import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: any;
  /** The day, does not include a time. */
  Date: any;
  /**
   * A JSON Web Token defined by [RFC 7519](https://tools.ietf.org/html/rfc7519)
   * which securely represents claims between two parties.
   */
  JwtToken: any;
};

/** All input for the `acceptInvitationToGroup` mutation. */
export type AcceptInvitationToGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invitationId: Scalars['UUID'];
};

/** The output of our `acceptInvitationToGroup` mutation. */
export type AcceptInvitationToGroupPayload = {
  __typename?: 'AcceptInvitationToGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type AccountData = {
  __typename?: 'AccountData';
  id: Scalars['String'];
  name: Scalars['String'];
};

/** All input for the `activateEncryptionForSenior` mutation. */
export type ActivateEncryptionForSeniorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  seniorId: Scalars['UUID'];
  mode: EncryptionMode;
};

/** The output of our `activateEncryptionForSenior` mutation. */
export type ActivateEncryptionForSeniorPayload = {
  __typename?: 'ActivateEncryptionForSeniorPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `activateLicense` mutation. */
export type ActivateLicenseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  licenseId: Scalars['String'];
  deviceId: Scalars['String'];
};

/** The output of our `activateLicense` mutation. */
export type ActivateLicensePayload = {
  __typename?: 'ActivateLicensePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  licenseResponse?: Maybe<LicenseResponse>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `addUserDevice` mutation. */
export type AddUserDeviceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
  platform?: Maybe<DevicePlatform>;
  deviceId?: Maybe<Scalars['UUID']>;
  osVersion?: Maybe<Scalars['String']>;
  appVersion?: Maybe<Scalars['String']>;
  appFlavour?: Maybe<Scalars['String']>;
  videocallVersion?: Maybe<Scalars['Int']>;
  deviceModel?: Maybe<Scalars['String']>;
  deviceSerialNo?: Maybe<Scalars['String']>;
  deviceImei?: Maybe<Scalars['String']>;
  deviceIccid?: Maybe<Scalars['String']>;
  chromeVersion?: Maybe<Scalars['String']>;
  appPackageId?: Maybe<Scalars['String']>;
  appPackageName?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  contentVersion?: Maybe<Scalars['String']>;
  appSigning?: Maybe<Scalars['String']>;
  appSwMode?: Maybe<Scalars['String']>;
  supportsEncryption?: Maybe<Scalars['Boolean']>;
};

/** The output of our `addUserDevice` mutation. */
export type AddUserDevicePayload = {
  __typename?: 'AddUserDevicePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export enum AppFeature {
  AudioMessage = 'AUDIO_MESSAGE',
  VideoMessage = 'VIDEO_MESSAGE',
  GroupVideocall = 'GROUP_VIDEOCALL',
  MessageEncryption = 'MESSAGE_ENCRYPTION'
}

export type Attachment = Node & {
  __typename?: 'Attachment';
  createdAt: Scalars['Datetime'];
  duration?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['UUID'];
  kind: AttachmentKind;
  /** Reads a single `Message` that is related to this `Attachment`. */
  message?: Maybe<Message>;
  messageId: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  status: AttachmentStatus;
  url?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

/** The fields on `attachment` to look up the row to connect. */
export type AttachmentAttachmentPkeyConnect = {
  id: Scalars['UUID'];
};

/**
 * A condition to be used against `Attachment` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AttachmentCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `messageId` field. */
  messageId?: Maybe<Scalars['UUID']>;
};

/** An input for mutations affecting `Attachment` */
export type AttachmentInput = {
  id: Scalars['UUID'];
  createdAt?: Maybe<Scalars['Datetime']>;
  messageId?: Maybe<Scalars['UUID']>;
  kind: AttachmentKind;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  messageToMessageId?: Maybe<AttachmentMessageIdInput>;
};

export enum AttachmentKind {
  Jpg = 'JPG',
  Png = 'PNG',
  Gif = 'GIF',
  Aac = 'AAC',
  Mp4 = 'MP4',
  Mp3 = 'MP3'
}

/** The `attachment` to be created by this mutation. */
export type AttachmentMessageIdAttachmentCreateInput = {
  id: Scalars['UUID'];
  createdAt?: Maybe<Scalars['Datetime']>;
  kind: AttachmentKind;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  messageToMessageId?: Maybe<AttachmentMessageIdInput>;
};

/** Input for the nested mutation of `message` in the `AttachmentInput` mutation. */
export type AttachmentMessageIdInput = {
  /** The primary key(s) for `message` for the far side of the relationship. */
  connectById?: Maybe<MessageMessagePkeyConnect>;
  /** The primary key(s) for `message` for the far side of the relationship. */
  connectByNodeId?: Maybe<MessageNodeIdConnect>;
  /** The primary key(s) for `message` for the far side of the relationship. */
  deleteById?: Maybe<MessageMessagePkeyDelete>;
  /** The primary key(s) for `message` for the far side of the relationship. */
  deleteByNodeId?: Maybe<MessageNodeIdDelete>;
  /** The primary key(s) and patch data for `message` for the far side of the relationship. */
  updateById?: Maybe<MessageOnAttachmentForAttachmentMessageIdUsingMessagePkeyUpdate>;
  /** The primary key(s) and patch data for `message` for the far side of the relationship. */
  updateByNodeId?: Maybe<AttachmentOnAttachmentForAttachmentMessageIdNodeIdUpdate>;
  /** A `MessageInput` object that will be created and connected to this object. */
  create?: Maybe<AttachmentMessageIdMessageCreateInput>;
};

/** Input for the nested mutation of `attachment` in the `MessageInput` mutation. */
export type AttachmentMessageIdInverseInput = {
  /** The primary key(s) for `attachment` for the far side of the relationship. */
  connectById?: Maybe<Array<AttachmentAttachmentPkeyConnect>>;
  /** The primary key(s) for `attachment` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<AttachmentNodeIdConnect>>;
  /** The primary key(s) and patch data for `attachment` for the far side of the relationship. */
  updateById?: Maybe<Array<AttachmentOnAttachmentForAttachmentMessageIdUsingAttachmentPkeyUpdate>>;
  /** The primary key(s) and patch data for `attachment` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<MessageOnAttachmentForAttachmentMessageIdNodeIdUpdate>>;
  /** A `AttachmentInput` object that will be created and connected to this object. */
  create?: Maybe<Array<AttachmentMessageIdAttachmentCreateInput>>;
};

/** The `message` to be created by this mutation. */
export type AttachmentMessageIdMessageCreateInput = {
  authorId?: Maybe<Scalars['UUID']>;
  chatId?: Maybe<Scalars['UUID']>;
  content?: Maybe<Scalars['String']>;
  senderKeyId?: Maybe<Scalars['UUID']>;
  userToAuthorId?: Maybe<MessageAuthorIdInput>;
  groupToChatId?: Maybe<MessageChatIdInput>;
  attachmentsUsingId?: Maybe<AttachmentMessageIdInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AttachmentNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `attachment` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AttachmentOnAttachmentForAttachmentMessageIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `message` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `message` being updated. */
  patch: MessagePatch;
};

/** The fields on `attachment` to look up the row to update. */
export type AttachmentOnAttachmentForAttachmentMessageIdUsingAttachmentPkeyUpdate = {
  /** An object where the defined keys will be set on the `attachment` being updated. */
  patch: UpdateAttachmentOnAttachmentForAttachmentMessageIdPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `Attachment`. Fields that are set will be updated. */
export type AttachmentPatch = {
  id?: Maybe<Scalars['UUID']>;
  messageId?: Maybe<Scalars['UUID']>;
  kind?: Maybe<AttachmentKind>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  messageToMessageId?: Maybe<AttachmentMessageIdInput>;
};

/** A connection to a list of `Attachment` values. */
export type AttachmentsConnection = {
  __typename?: 'AttachmentsConnection';
  /** A list of `Attachment` objects. */
  nodes: Array<Maybe<Attachment>>;
  /** A list of edges which contains the `Attachment` and cursor to aid in pagination. */
  edges: Array<AttachmentsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Attachment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Attachment` edge in the connection. */
export type AttachmentsEdge = {
  __typename?: 'AttachmentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Attachment` at the end of the edge. */
  node?: Maybe<Attachment>;
};

/** Methods to use when ordering `Attachment`. */
export enum AttachmentsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  MessageIdAsc = 'MESSAGE_ID_ASC',
  MessageIdDesc = 'MESSAGE_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export enum AttachmentStatus {
  Pending = 'PENDING',
  Sending = 'SENDING',
  Uploaded = 'UPLOADED',
  Failed = 'FAILED'
}


export type CatapushPassword = {
  __typename?: 'CatapushPassword';
  password: Scalars['String'];
};

export enum ContractStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Cancelled = 'Cancelled'
}

/** All input for the create `Attachment` mutation. */
export type CreateAttachmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Attachment` to be created by this mutation. */
  attachment: AttachmentInput;
};

/** The output of our create `Attachment` mutation. */
export type CreateAttachmentPayload = {
  __typename?: 'CreateAttachmentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Attachment` that was created by this mutation. */
  attachment?: Maybe<Attachment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Message` that is related to this `Attachment`. */
  message?: Maybe<Message>;
  /** An edge for our `Attachment`. May be used by Relay 1. */
  attachmentEdge?: Maybe<AttachmentsEdge>;
};


/** The output of our create `Attachment` mutation. */
export type CreateAttachmentPayloadAttachmentEdgeArgs = {
  orderBy?: Maybe<Array<AttachmentsOrderBy>>;
};

/** All input for the create `Feedback` mutation. */
export type CreateFeedbackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Feedback` to be created by this mutation. */
  feedback: FeedbackInput;
};

/** The output of our create `Feedback` mutation. */
export type CreateFeedbackPayload = {
  __typename?: 'CreateFeedbackPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Feedback` that was created by this mutation. */
  feedback?: Maybe<Feedback>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Feedback`. */
  user?: Maybe<User>;
  /** An edge for our `Feedback`. May be used by Relay 1. */
  feedbackEdge?: Maybe<FeedbacksEdge>;
};


/** The output of our create `Feedback` mutation. */
export type CreateFeedbackPayloadFeedbackEdgeArgs = {
  orderBy?: Maybe<Array<FeedbacksOrderBy>>;
};

/** All input for the create `Group` mutation. */
export type CreateGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Group` to be created by this mutation. */
  group: GroupInput;
};

/** The output of our create `Group` mutation. */
export type CreateGroupPayload = {
  __typename?: 'CreateGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Group` that was created by this mutation. */
  group?: Maybe<Group>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge?: Maybe<GroupsEdge>;
};


/** The output of our create `Group` mutation. */
export type CreateGroupPayloadGroupEdgeArgs = {
  orderBy?: Maybe<Array<GroupsOrderBy>>;
};

/** All input for the create `Membership` mutation. */
export type CreateMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Membership` to be created by this mutation. */
  membership: MembershipInput;
};

/** The output of our create `Membership` mutation. */
export type CreateMembershipPayload = {
  __typename?: 'CreateMembershipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Membership` that was created by this mutation. */
  membership?: Maybe<Membership>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `Membership`. */
  group?: Maybe<Group>;
  /** Reads a single `User` that is related to this `Membership`. */
  member?: Maybe<User>;
  /** An edge for our `Membership`. May be used by Relay 1. */
  membershipEdge?: Maybe<MembershipsEdge>;
};


/** The output of our create `Membership` mutation. */
export type CreateMembershipPayloadMembershipEdgeArgs = {
  orderBy?: Maybe<Array<MembershipsOrderBy>>;
};

/** All input for the create `Message` mutation. */
export type CreateMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Message` to be created by this mutation. */
  message: MessageInput;
};

/** The output of our create `Message` mutation. */
export type CreateMessagePayload = {
  __typename?: 'CreateMessagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Message` that was created by this mutation. */
  message?: Maybe<Message>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Message`. */
  author?: Maybe<User>;
  /** Reads a single `Group` that is related to this `Message`. */
  chat?: Maybe<Group>;
  /** An edge for our `Message`. May be used by Relay 1. */
  messageEdge?: Maybe<MessagesEdge>;
};


/** The output of our create `Message` mutation. */
export type CreateMessagePayloadMessageEdgeArgs = {
  orderBy?: Maybe<Array<MessagesOrderBy>>;
};

/** All input for the `createOrRetrievePrivateChat` mutation. */
export type CreateOrRetrievePrivateChatInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  memberId1?: Maybe<Scalars['UUID']>;
  memberId2?: Maybe<Scalars['UUID']>;
};

/** The output of our `createOrRetrievePrivateChat` mutation. */
export type CreateOrRetrievePrivateChatPayload = {
  __typename?: 'CreateOrRetrievePrivateChatPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  group?: Maybe<Group>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge?: Maybe<GroupsEdge>;
};


/** The output of our `createOrRetrievePrivateChat` mutation. */
export type CreateOrRetrievePrivateChatPayloadGroupEdgeArgs = {
  orderBy?: Maybe<Array<GroupsOrderBy>>;
};

/** All input for the create `SenderKey` mutation. */
export type CreateSenderKeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SenderKey` to be created by this mutation. */
  senderKey: SenderKeyInput;
};

/** The output of our create `SenderKey` mutation. */
export type CreateSenderKeyPayload = {
  __typename?: 'CreateSenderKeyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `SenderKey` that was created by this mutation. */
  senderKey?: Maybe<SenderKey>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `SenderKey`. */
  group?: Maybe<Group>;
  /** An edge for our `SenderKey`. May be used by Relay 1. */
  senderKeyEdge?: Maybe<SenderKeysEdge>;
};


/** The output of our create `SenderKey` mutation. */
export type CreateSenderKeyPayloadSenderKeyEdgeArgs = {
  orderBy?: Maybe<Array<SenderKeysOrderBy>>;
};

/** All input for the `createSenior` mutation. */
export type CreateSeniorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  flavour?: Maybe<Scalars['String']>;
};

/** The output of our `createSenior` mutation. */
export type CreateSeniorPayload = {
  __typename?: 'CreateSeniorPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  jwtToken?: Maybe<Scalars['JwtToken']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createSystemMessage` mutation. */
export type CreateSystemMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  receiverId?: Maybe<Scalars['UUID']>;
  kind?: Maybe<SystemMessageType>;
  payload?: Maybe<Scalars['JSON']>;
};

/** The output of our `createSystemMessage` mutation. */
export type CreateSystemMessagePayload = {
  __typename?: 'CreateSystemMessagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `User` mutation. */
export type CreateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `User` to be created by this mutation. */
  user: UserInput;
};

/** The output of our create `User` mutation. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `User` that was created by this mutation. */
  user?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our create `User` mutation. */
export type CreateUserPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

/** All input for the create `UserSetting` mutation. */
export type CreateUserSettingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserSetting` to be created by this mutation. */
  userSetting: UserSettingInput;
};

/** The output of our create `UserSetting` mutation. */
export type CreateUserSettingPayload = {
  __typename?: 'CreateUserSettingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserSetting` that was created by this mutation. */
  userSetting?: Maybe<UserSetting>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserSetting`. */
  user?: Maybe<User>;
  /** An edge for our `UserSetting`. May be used by Relay 1. */
  userSettingEdge?: Maybe<UserSettingsEdge>;
};


/** The output of our create `UserSetting` mutation. */
export type CreateUserSettingPayloadUserSettingEdgeArgs = {
  orderBy?: Maybe<Array<UserSettingsOrderBy>>;
};

/** All input for the create `Website` mutation. */
export type CreateWebsiteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Website` to be created by this mutation. */
  website: WebsiteInput;
};

/** The output of our create `Website` mutation. */
export type CreateWebsitePayload = {
  __typename?: 'CreateWebsitePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Website` that was created by this mutation. */
  website?: Maybe<Website>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Website`. */
  user?: Maybe<User>;
  /** An edge for our `Website`. May be used by Relay 1. */
  websiteEdge?: Maybe<WebsitesEdge>;
};


/** The output of our create `Website` mutation. */
export type CreateWebsitePayloadWebsiteEdgeArgs = {
  orderBy?: Maybe<Array<WebsitesOrderBy>>;
};

export type Credential = {
  __typename?: 'Credential';
  userId: Scalars['UUID'];
  email?: Maybe<Scalars['String']>;
  passwordHash?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  lastUpdate: Scalars['Datetime'];
  licenceNr?: Maybe<Scalars['BigInt']>;
};


export type Customer = {
  __typename?: 'Customer';
  id: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
};



/** All input for the `deleteFeedbackByNodeId` mutation. */
export type DeleteFeedbackByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Feedback` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFeedback` mutation. */
export type DeleteFeedbackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Feedback` mutation. */
export type DeleteFeedbackPayload = {
  __typename?: 'DeleteFeedbackPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Feedback` that was deleted by this mutation. */
  feedback?: Maybe<Feedback>;
  deletedFeedbackNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Feedback`. */
  user?: Maybe<User>;
  /** An edge for our `Feedback`. May be used by Relay 1. */
  feedbackEdge?: Maybe<FeedbacksEdge>;
};


/** The output of our delete `Feedback` mutation. */
export type DeleteFeedbackPayloadFeedbackEdgeArgs = {
  orderBy?: Maybe<Array<FeedbacksOrderBy>>;
};

/** All input for the `deleteInvitationByNodeId` mutation. */
export type DeleteInvitationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Invitation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteInvitation` mutation. */
export type DeleteInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Invitation` mutation. */
export type DeleteInvitationPayload = {
  __typename?: 'DeleteInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Invitation` that was deleted by this mutation. */
  invitation?: Maybe<Invitation>;
  deletedInvitationNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Invitation`. */
  userByInvitedBy?: Maybe<User>;
  /** Reads a single `Group` that is related to this `Invitation`. */
  groupByInvitedTo?: Maybe<Group>;
};

/** All input for the `deleteMessageByNodeId` mutation. */
export type DeleteMessageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Message` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteMessage` mutation. */
export type DeleteMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Message` mutation. */
export type DeleteMessagePayload = {
  __typename?: 'DeleteMessagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Message` that was deleted by this mutation. */
  message?: Maybe<Message>;
  deletedMessageNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Message`. */
  author?: Maybe<User>;
  /** Reads a single `Group` that is related to this `Message`. */
  chat?: Maybe<Group>;
  /** An edge for our `Message`. May be used by Relay 1. */
  messageEdge?: Maybe<MessagesEdge>;
};


/** The output of our delete `Message` mutation. */
export type DeleteMessagePayloadMessageEdgeArgs = {
  orderBy?: Maybe<Array<MessagesOrderBy>>;
};

export type DeleteMessageSubscriptionPayload = {
  __typename?: 'DeleteMessageSubscriptionPayload';
  messageId: Scalars['String'];
  chatId: Scalars['String'];
};

/** All input for the `deleteUserByNodeId` mutation. */
export type DeleteUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUser` mutation. */
export type DeleteUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `User` mutation. */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `User` that was deleted by this mutation. */
  user?: Maybe<User>;
  deletedUserNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our delete `User` mutation. */
export type DeleteUserPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

/** All input for the `deleteUserSettingByNodeId` mutation. */
export type DeleteUserSettingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserSetting` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUserSetting` mutation. */
export type DeleteUserSettingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  userId: Scalars['UUID'];
};

/** The output of our delete `UserSetting` mutation. */
export type DeleteUserSettingPayload = {
  __typename?: 'DeleteUserSettingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserSetting` that was deleted by this mutation. */
  userSetting?: Maybe<UserSetting>;
  deletedUserSettingNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserSetting`. */
  user?: Maybe<User>;
  /** An edge for our `UserSetting`. May be used by Relay 1. */
  userSettingEdge?: Maybe<UserSettingsEdge>;
};


/** The output of our delete `UserSetting` mutation. */
export type DeleteUserSettingPayloadUserSettingEdgeArgs = {
  orderBy?: Maybe<Array<UserSettingsOrderBy>>;
};

/** All input for the `deleteWebsiteByNodeId` mutation. */
export type DeleteWebsiteByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Website` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteWebsite` mutation. */
export type DeleteWebsiteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our delete `Website` mutation. */
export type DeleteWebsitePayload = {
  __typename?: 'DeleteWebsitePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Website` that was deleted by this mutation. */
  website?: Maybe<Website>;
  deletedWebsiteNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Website`. */
  user?: Maybe<User>;
  /** An edge for our `Website`. May be used by Relay 1. */
  websiteEdge?: Maybe<WebsitesEdge>;
};


/** The output of our delete `Website` mutation. */
export type DeleteWebsitePayloadWebsiteEdgeArgs = {
  orderBy?: Maybe<Array<WebsitesOrderBy>>;
};

/** All input for the `deregisterPushNotificationDevice` mutation. */
export type DeregisterPushNotificationDeviceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['UUID']>;
};

/** The output of our `deregisterPushNotificationDevice` mutation. */
export type DeregisterPushNotificationDevicePayload = {
  __typename?: 'DeregisterPushNotificationDevicePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export enum DevicePlatform {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB'
}

export enum EmailStatus {
  Sending = 'SENDING',
  Sent = 'SENT',
  Failed = 'FAILED',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED'
}

export enum EncryptionMode {
  None = 'NONE',
  Message = 'MESSAGE',
  All = 'ALL'
}

export type Feedback = Node & {
  __typename?: 'Feedback';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  userId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  target?: Maybe<Scalars['String']>;
  params?: Maybe<Scalars['JSON']>;
  rating?: Maybe<Scalars['Int']>;
  targetType?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Feedback`. */
  user?: Maybe<User>;
};

/**
 * A condition to be used against `Feedback` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type FeedbackCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `targetType` field. */
  targetType?: Maybe<Scalars['String']>;
};

/** The fields on `feedback` to look up the row to connect. */
export type FeedbackFeedbackPkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `feedback` to look up the row to delete. */
export type FeedbackFeedbackPkeyDelete = {
  id: Scalars['Int'];
};

/** An input for mutations affecting `Feedback` */
export type FeedbackInput = {
  userId?: Maybe<Scalars['UUID']>;
  deviceId: Scalars['UUID'];
  target?: Maybe<Scalars['String']>;
  params?: Maybe<Scalars['JSON']>;
  rating?: Maybe<Scalars['Int']>;
  targetType?: Maybe<Scalars['String']>;
  userToUserId?: Maybe<FeedbackUserIdInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type FeedbackNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `feedback` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type FeedbackNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `feedback` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FeedbackOnFeedbackForFeedbackUserIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `feedback` to look up the row to update. */
export type FeedbackOnFeedbackForFeedbackUserIdUsingFeedbackPkeyUpdate = {
  /** An object where the defined keys will be set on the `feedback` being updated. */
  patch: UpdateFeedbackOnFeedbackForFeedbackUserIdPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `Feedback`. Fields that are set will be updated. */
export type FeedbackPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  userId?: Maybe<Scalars['UUID']>;
  deviceId?: Maybe<Scalars['UUID']>;
  target?: Maybe<Scalars['String']>;
  params?: Maybe<Scalars['JSON']>;
  rating?: Maybe<Scalars['Int']>;
  targetType?: Maybe<Scalars['String']>;
  userToUserId?: Maybe<FeedbackUserIdInput>;
};

/** A connection to a list of `Feedback` values. */
export type FeedbacksConnection = {
  __typename?: 'FeedbacksConnection';
  /** A list of `Feedback` objects. */
  nodes: Array<Maybe<Feedback>>;
  /** A list of edges which contains the `Feedback` and cursor to aid in pagination. */
  edges: Array<FeedbacksEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Feedback` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Feedback` edge in the connection. */
export type FeedbacksEdge = {
  __typename?: 'FeedbacksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Feedback` at the end of the edge. */
  node?: Maybe<Feedback>;
};

/** Methods to use when ordering `Feedback`. */
export enum FeedbacksOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  TargetTypeAsc = 'TARGET_TYPE_ASC',
  TargetTypeDesc = 'TARGET_TYPE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The `feedback` to be created by this mutation. */
export type FeedbackUserIdFeedbackCreateInput = {
  deviceId: Scalars['UUID'];
  target?: Maybe<Scalars['String']>;
  params?: Maybe<Scalars['JSON']>;
  rating?: Maybe<Scalars['Int']>;
  targetType?: Maybe<Scalars['String']>;
  userToUserId?: Maybe<FeedbackUserIdInput>;
};

/** Input for the nested mutation of `user` in the `FeedbackInput` mutation. */
export type FeedbackUserIdInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: Maybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: Maybe<UserNodeIdConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteById?: Maybe<UserUserPkeyDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByNodeId?: Maybe<UserNodeIdDelete>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: Maybe<UserOnFeedbackForFeedbackUserIdUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: Maybe<FeedbackOnFeedbackForFeedbackUserIdNodeIdUpdate>;
  /** A `UserInput` object that will be created and connected to this object. */
  create?: Maybe<FeedbackUserIdUserCreateInput>;
};

/** Input for the nested mutation of `feedback` in the `UserInput` mutation. */
export type FeedbackUserIdInverseInput = {
  /** Flag indicating whether all other `feedback` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `feedback` for the far side of the relationship. */
  connectById?: Maybe<Array<FeedbackFeedbackPkeyConnect>>;
  /** The primary key(s) for `feedback` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<FeedbackNodeIdConnect>>;
  /** The primary key(s) for `feedback` for the far side of the relationship. */
  deleteById?: Maybe<Array<FeedbackFeedbackPkeyDelete>>;
  /** The primary key(s) for `feedback` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<FeedbackNodeIdDelete>>;
  /** The primary key(s) and patch data for `feedback` for the far side of the relationship. */
  updateById?: Maybe<Array<FeedbackOnFeedbackForFeedbackUserIdUsingFeedbackPkeyUpdate>>;
  /** The primary key(s) and patch data for `feedback` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<UserOnFeedbackForFeedbackUserIdNodeIdUpdate>>;
  /** A `FeedbackInput` object that will be created and connected to this object. */
  create?: Maybe<Array<FeedbackUserIdFeedbackCreateInput>>;
};

/** The `user` to be created by this mutation. */
export type FeedbackUserIdUserCreateInput = {
  name: Scalars['String'];
  kind: UserType;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipMemberIdInverseInput>;
  messagesAuthored?: Maybe<MessageAuthorIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedByFkeyInverseInput>;
  feedbacksUsingId?: Maybe<FeedbackUserIdInverseInput>;
  websitesUsingId?: Maybe<OwnerUserIdInverseInput>;
  userSettingUsingId?: Maybe<UserIdInverseInput>;
};

export enum Flavour {
  B2c = 'b2c',
  B2bBasic = 'b2bBasic',
  B2b = 'b2b',
  Caregiver = 'caregiver'
}

/** All input for the `forgotPassword` mutation. */
export type ForgotPasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

/** The output of our `forgotPassword` mutation. */
export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type GenerateToyDatasetFromFamilyReturn = {
  __typename?: 'GenerateToyDatasetFromFamilyReturn';
  recoveryKey: Scalars['String'];
  relativeEmails: Array<Maybe<Scalars['String']>>;
  invitedRelativeEmails: Array<Maybe<Scalars['String']>>;
};

export type Group = Node & {
  __typename?: 'Group';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  kind: GroupType;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Membership`. */
  memberships: MembershipsConnection;
  /** Reads and enables pagination through a set of `Membership`. */
  membershipsList: Array<Membership>;
  /** Reads and enables pagination through a set of `Message`. */
  messages: MessagesConnection;
  /** Reads and enables pagination through a set of `Message`. */
  messagesList: Array<Message>;
  /** Reads and enables pagination through a set of `SenderKey`. */
  senderKeys: SenderKeysConnection;
  /** Reads and enables pagination through a set of `SenderKey`. */
  senderKeysList: Array<SenderKey>;
  /** Returns the current user's last message for the given group */
  lastMessageForCurrentUser?: Maybe<Message>;
  /** Returns the minimum last_read timestamp of all members of the given group */
  lastReadByAll?: Maybe<Scalars['Datetime']>;
  /** Returns the current user's last read timestamp for the given group */
  lastReadTimestampForCurrentUser?: Maybe<Scalars['Datetime']>;
  /** Returns the minimum last_received timestamp of all members of the given group */
  lastReceivedByAll?: Maybe<Scalars['Datetime']>;
  /** Returns the current user's unread messages count for the given group */
  unreadCountForCurrentUser?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `User`. */
  members: GroupMembersManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  membersList: Array<User>;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvitationInvitedToAndInvitedBy: GroupUsersByInvitationInvitedToAndInvitedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvitationInvitedToAndInvitedByList: Array<User>;
  avatarUrl?: Maybe<Scalars['String']>;
  supportedFeatures?: Maybe<Array<Maybe<AppFeature>>>;
  memberDevices?: Maybe<Array<Maybe<MemberDevice>>>;
  relatedSeniorEncryptionMode?: Maybe<EncryptionMode>;
};


export type GroupMembershipsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MembershipsOrderBy>>;
  condition?: Maybe<MembershipCondition>;
};


export type GroupMembershipsListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MembershipsOrderBy>>;
  condition?: Maybe<MembershipCondition>;
};


export type GroupMessagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MessagesOrderBy>>;
  condition?: Maybe<MessageCondition>;
};


export type GroupMessagesListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MessagesOrderBy>>;
  condition?: Maybe<MessageCondition>;
};


export type GroupSenderKeysArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SenderKeysOrderBy>>;
  condition?: Maybe<SenderKeyCondition>;
};


export type GroupSenderKeysListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SenderKeysOrderBy>>;
  condition?: Maybe<SenderKeyCondition>;
};


export type GroupMembersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
};


export type GroupMembersListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
};


export type GroupUsersByInvitationInvitedToAndInvitedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
};


export type GroupUsersByInvitationInvitedToAndInvitedByListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
};

/** A condition to be used against `Group` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type GroupCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** The fields on `group` to look up the row to connect. */
export type GroupGroupPkeyConnect = {
  id: Scalars['UUID'];
};

/** An input for mutations affecting `Group` */
export type GroupInput = {
  kind: GroupType;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipGroupIdInverseInput>;
  messages?: Maybe<MessageChatIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedToFkeyInverseInput>;
  senderKeysUsingId?: Maybe<SenderKeyGroupIdInverseInput>;
};

/** A connection to a list of `User` values, with data from `Membership`. */
export type GroupMembersManyToManyConnection = {
  __typename?: 'GroupMembersManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** A list of edges which contains the `User`, info from the `Membership`, and the cursor to aid in pagination. */
  edges: Array<GroupMembersManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Membership`. */
export type GroupMembersManyToManyEdge = {
  __typename?: 'GroupMembersManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
  createdAt: Scalars['Datetime'];
  lastRead: Scalars['Datetime'];
  lastReceived?: Maybe<Scalars['Datetime']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type GroupNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnInvitationForInvitationInvitedToFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `invitation` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `invitation` being updated. */
  patch: InvitationPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnInvitationForInvitationInvitedToFkeyUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnInvitationForInvitationInvitedToFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnMembershipForMembershipGroupIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `membership` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `membership` being updated. */
  patch: MembershipPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnMembershipForMembershipGroupIdUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnMembershipForMembershipGroupIdPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnMessageForMessageChatIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `message` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `message` being updated. */
  patch: MessagePatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnMessageForMessageChatIdUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnMessageForMessageChatIdPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GroupOnSenderKeyForSenderKeyGroupIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `senderKey` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `senderKey` being updated. */
  patch: SenderKeyPatch;
};

/** The fields on `group` to look up the row to update. */
export type GroupOnSenderKeyForSenderKeyGroupIdUsingGroupPkeyUpdate = {
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: UpdateGroupOnSenderKeyForSenderKeyGroupIdPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `Group`. Fields that are set will be updated. */
export type GroupPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  kind?: Maybe<GroupType>;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipGroupIdInverseInput>;
  messages?: Maybe<MessageChatIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedToFkeyInverseInput>;
  senderKeysUsingId?: Maybe<SenderKeyGroupIdInverseInput>;
};

/** A connection to a list of `Group` values. */
export type GroupsConnection = {
  __typename?: 'GroupsConnection';
  /** A list of `Group` objects. */
  nodes: Array<Maybe<Group>>;
  /** A list of edges which contains the `Group` and cursor to aid in pagination. */
  edges: Array<GroupsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection. */
export type GroupsEdge = {
  __typename?: 'GroupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node?: Maybe<Group>;
};

/** Methods to use when ordering `Group`. */
export enum GroupsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type GroupSubscriptionPayload = {
  __typename?: 'GroupSubscriptionPayload';
  group: Group;
};

export enum GroupType {
  FamilyChat = 'FAMILY_CHAT',
  GroupChat = 'GROUP_CHAT',
  PrivateChat = 'PRIVATE_CHAT',
  ReduriskChat = 'REDURISK_CHAT'
}

/** A connection to a list of `User` values, with data from `Invitation`. */
export type GroupUsersByInvitationInvitedToAndInvitedByManyToManyConnection = {
  __typename?: 'GroupUsersByInvitationInvitedToAndInvitedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** A list of edges which contains the `User`, info from the `Invitation`, and the cursor to aid in pagination. */
  edges: Array<GroupUsersByInvitationInvitedToAndInvitedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Invitation`. */
export type GroupUsersByInvitationInvitedToAndInvitedByManyToManyEdge = {
  __typename?: 'GroupUsersByInvitationInvitedToAndInvitedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
  /** Reads and enables pagination through a set of `Invitation`. */
  invitationsByInvitedBy: InvitationsConnection;
  /** Reads and enables pagination through a set of `Invitation`. */
  invitationsByInvitedByList: Array<Invitation>;
};


/** A `User` edge in the connection, with data from `Invitation`. */
export type GroupUsersByInvitationInvitedToAndInvitedByManyToManyEdgeInvitationsByInvitedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvitationsOrderBy>>;
  condition?: Maybe<InvitationCondition>;
};


/** A `User` edge in the connection, with data from `Invitation`. */
export type GroupUsersByInvitationInvitedToAndInvitedByManyToManyEdgeInvitationsByInvitedByListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<InvitationsOrderBy>>;
  condition?: Maybe<InvitationCondition>;
};

export type Invitation = Node & {
  __typename?: 'Invitation';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  email: Scalars['String'];
  invitedBy?: Maybe<Scalars['UUID']>;
  invitedTo?: Maybe<Scalars['UUID']>;
  status: EmailStatus;
  errorMsg?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Invitation`. */
  userByInvitedBy?: Maybe<User>;
  /** Reads a single `Group` that is related to this `Invitation`. */
  groupByInvitedTo?: Maybe<Group>;
};

/**
 * A condition to be used against `Invitation` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type InvitationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `invitedBy` field. */
  invitedBy?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `invitedTo` field. */
  invitedTo?: Maybe<Scalars['UUID']>;
};

/** The fields on `invitation` to look up the row to connect. */
export type InvitationInvitationPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `invitation` to look up the row to delete. */
export type InvitationInvitationPkeyDelete = {
  id: Scalars['UUID'];
};

/** Input for the nested mutation of `user` in the `InvitationInput` mutation. */
export type InvitationInvitedByFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: Maybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: Maybe<UserNodeIdConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteById?: Maybe<UserUserPkeyDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByNodeId?: Maybe<UserNodeIdDelete>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: Maybe<UserOnInvitationForInvitationInvitedByFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: Maybe<InvitationOnInvitationForInvitationInvitedByFkeyNodeIdUpdate>;
  /** A `UserInput` object that will be created and connected to this object. */
  create?: Maybe<InvitationInvitedByFkeyUserCreateInput>;
};

/** Input for the nested mutation of `invitation` in the `UserInput` mutation. */
export type InvitationInvitedByFkeyInverseInput = {
  /** Flag indicating whether all other `invitation` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `invitation` for the far side of the relationship. */
  connectById?: Maybe<Array<InvitationInvitationPkeyConnect>>;
  /** The primary key(s) for `invitation` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<InvitationNodeIdConnect>>;
  /** The primary key(s) for `invitation` for the far side of the relationship. */
  deleteById?: Maybe<Array<InvitationInvitationPkeyDelete>>;
  /** The primary key(s) for `invitation` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<InvitationNodeIdDelete>>;
  /** The primary key(s) and patch data for `invitation` for the far side of the relationship. */
  updateById?: Maybe<Array<InvitationOnInvitationForInvitationInvitedByFkeyUsingInvitationPkeyUpdate>>;
  /** The primary key(s) and patch data for `invitation` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<UserOnInvitationForInvitationInvitedByFkeyNodeIdUpdate>>;
};

/** The `user` to be created by this mutation. */
export type InvitationInvitedByFkeyUserCreateInput = {
  name: Scalars['String'];
  kind: UserType;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipMemberIdInverseInput>;
  messagesAuthored?: Maybe<MessageAuthorIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedByFkeyInverseInput>;
  feedbacksUsingId?: Maybe<FeedbackUserIdInverseInput>;
  websitesUsingId?: Maybe<OwnerUserIdInverseInput>;
  userSettingUsingId?: Maybe<UserIdInverseInput>;
};

/** The `group` to be created by this mutation. */
export type InvitationInvitedToFkeyGroupCreateInput = {
  kind: GroupType;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipGroupIdInverseInput>;
  messages?: Maybe<MessageChatIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedToFkeyInverseInput>;
  senderKeysUsingId?: Maybe<SenderKeyGroupIdInverseInput>;
};

/** Input for the nested mutation of `group` in the `InvitationInput` mutation. */
export type InvitationInvitedToFkeyInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnInvitationForInvitationInvitedToFkeyUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<InvitationOnInvitationForInvitationInvitedToFkeyNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<InvitationInvitedToFkeyGroupCreateInput>;
};

/** Input for the nested mutation of `invitation` in the `GroupInput` mutation. */
export type InvitationInvitedToFkeyInverseInput = {
  /** Flag indicating whether all other `invitation` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `invitation` for the far side of the relationship. */
  connectById?: Maybe<Array<InvitationInvitationPkeyConnect>>;
  /** The primary key(s) for `invitation` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<InvitationNodeIdConnect>>;
  /** The primary key(s) for `invitation` for the far side of the relationship. */
  deleteById?: Maybe<Array<InvitationInvitationPkeyDelete>>;
  /** The primary key(s) for `invitation` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<InvitationNodeIdDelete>>;
  /** The primary key(s) and patch data for `invitation` for the far side of the relationship. */
  updateById?: Maybe<Array<InvitationOnInvitationForInvitationInvitedToFkeyUsingInvitationPkeyUpdate>>;
  /** The primary key(s) and patch data for `invitation` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnInvitationForInvitationInvitedToFkeyNodeIdUpdate>>;
};

/** The globally unique `ID` look up for the row to connect. */
export type InvitationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `invitation` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type InvitationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `invitation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type InvitationOnInvitationForInvitationInvitedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `invitation` to look up the row to update. */
export type InvitationOnInvitationForInvitationInvitedByFkeyUsingInvitationPkeyUpdate = {
  /** An object where the defined keys will be set on the `invitation` being updated. */
  patch: UpdateInvitationOnInvitationForInvitationInvitedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type InvitationOnInvitationForInvitationInvitedToFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `invitation` to look up the row to update. */
export type InvitationOnInvitationForInvitationInvitedToFkeyUsingInvitationPkeyUpdate = {
  /** An object where the defined keys will be set on the `invitation` being updated. */
  patch: UpdateInvitationOnInvitationForInvitationInvitedToFkeyPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `Invitation`. Fields that are set will be updated. */
export type InvitationPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  email?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<Scalars['UUID']>;
  invitedTo?: Maybe<Scalars['UUID']>;
  status?: Maybe<EmailStatus>;
  errorMsg?: Maybe<Scalars['String']>;
  userToInvitedBy?: Maybe<InvitationInvitedByFkeyInput>;
  groupToInvitedTo?: Maybe<InvitationInvitedToFkeyInput>;
};

/** A connection to a list of `Invitation` values. */
export type InvitationsConnection = {
  __typename?: 'InvitationsConnection';
  /** A list of `Invitation` objects. */
  nodes: Array<Maybe<Invitation>>;
  /** A list of edges which contains the `Invitation` and cursor to aid in pagination. */
  edges: Array<InvitationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invitation` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invitation` edge in the connection. */
export type InvitationsEdge = {
  __typename?: 'InvitationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invitation` at the end of the edge. */
  node?: Maybe<Invitation>;
};

/** Methods to use when ordering `Invitation`. */
export enum InvitationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InvitedByAsc = 'INVITED_BY_ASC',
  InvitedByDesc = 'INVITED_BY_DESC',
  InvitedToAsc = 'INVITED_TO_ASC',
  InvitedToDesc = 'INVITED_TO_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type InvitationSubscriptionPayload = {
  __typename?: 'InvitationSubscriptionPayload';
  invitation: Invitation;
};

/** All input for the `invite` mutation. */
export type InviteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['UUID']>;
};

/** The output of our `invite` mutation. */
export type InvitePayload = {
  __typename?: 'InvitePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `isAndroidDeviceSupported` mutation. */
export type IsAndroidDeviceSupportedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  hwModel?: Maybe<Scalars['String']>;
  androidVersion?: Maybe<Scalars['String']>;
  utilizationMode?: Maybe<Scalars['String']>;
  chromeVersion?: Maybe<Scalars['String']>;
};

/** The output of our `isAndroidDeviceSupported` mutation. */
export type IsAndroidDeviceSupportedPayload = {
  __typename?: 'IsAndroidDeviceSupportedPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  boolean?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `isTabletUpdatable` mutation. */
export type IsTabletUpdatableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  hwModel?: Maybe<Scalars['String']>;
  androidVersion?: Maybe<Scalars['String']>;
  utilizationMode?: Maybe<Scalars['String']>;
  chromeVersion?: Maybe<Scalars['String']>;
};

/** The output of our `isTabletUpdatable` mutation. */
export type IsTabletUpdatablePayload = {
  __typename?: 'IsTabletUpdatablePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  boolean?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};



export type License = {
  __typename?: 'License';
  id: Scalars['UUID'];
  name: Scalars['String'];
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  plan?: Maybe<Scalars['String']>;
  flavour?: Maybe<Flavour>;
  tabletName?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  activatedAt?: Maybe<Scalars['Date']>;
  appUrl?: Maybe<Scalars['String']>;
};

export type LicenseData = {
  __typename?: 'LicenseData';
  id: Scalars['UUID'];
  name: Scalars['String'];
  startDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  plan?: Maybe<Scalars['String']>;
  flavour: Flavour;
  tabletName?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  activatedAt?: Maybe<Scalars['Date']>;
  appUrl?: Maybe<Scalars['String']>;
  qrCodeUrl: Scalars['String'];
};

export type LicenseResponse = {
  __typename?: 'LicenseResponse';
  jwtToken?: Maybe<Scalars['JwtToken']>;
  flavour?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  contentGroup?: Maybe<Scalars['String']>;
};

/** All input for the `login` mutation. */
export type LoginInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

/** All input for the `loginOrCreateSenior` mutation. */
export type LoginOrCreateSeniorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  serialNumber: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

/** The output of our `loginOrCreateSenior` mutation. */
export type LoginOrCreateSeniorPayload = {
  __typename?: 'LoginOrCreateSeniorPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  jwtToken?: Maybe<Scalars['JwtToken']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our `login` mutation. */
export type LoginPayload = {
  __typename?: 'LoginPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  jwtToken?: Maybe<Scalars['JwtToken']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `loginSenior` mutation. */
export type LoginSeniorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  licence: Scalars['String'];
};

/** The output of our `loginSenior` mutation. */
export type LoginSeniorPayload = {
  __typename?: 'LoginSeniorPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  jwtToken?: Maybe<Scalars['JwtToken']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `markAsRead` mutation. */
export type MarkAsReadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
  messageId?: Maybe<Scalars['UUID']>;
};

/** The output of our `markAsRead` mutation. */
export type MarkAsReadPayload = {
  __typename?: 'MarkAsReadPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['Datetime']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `markAsReceived` mutation. */
export type MarkAsReceivedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
  messageId?: Maybe<Scalars['UUID']>;
};

/** The output of our `markAsReceived` mutation. */
export type MarkAsReceivedPayload = {
  __typename?: 'MarkAsReceivedPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['Datetime']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type MemberDevice = {
  __typename?: 'MemberDevice';
  memberId: Scalars['String'];
  deviceId: Scalars['String'];
  publicKey?: Maybe<Scalars['String']>;
};

export type Membership = Node & {
  __typename?: 'Membership';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  groupId: Scalars['UUID'];
  memberId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  lastRead: Scalars['Datetime'];
  lastReceived?: Maybe<Scalars['Datetime']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Group` that is related to this `Membership`. */
  group?: Maybe<Group>;
  /** Reads a single `User` that is related to this `Membership`. */
  member?: Maybe<User>;
};

/**
 * A condition to be used against `Membership` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MembershipCondition = {
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `memberId` field. */
  memberId?: Maybe<Scalars['UUID']>;
};

/** The `group` to be created by this mutation. */
export type MembershipGroupIdGroupCreateInput = {
  kind: GroupType;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipGroupIdInverseInput>;
  messages?: Maybe<MessageChatIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedToFkeyInverseInput>;
  senderKeysUsingId?: Maybe<SenderKeyGroupIdInverseInput>;
};

/** Input for the nested mutation of `group` in the `MembershipInput` mutation. */
export type MembershipGroupIdInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnMembershipForMembershipGroupIdUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<MembershipOnMembershipForMembershipGroupIdNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<MembershipGroupIdGroupCreateInput>;
};

/** Input for the nested mutation of `membership` in the `GroupInput` mutation. */
export type MembershipGroupIdInverseInput = {
  /** The primary key(s) for `membership` for the far side of the relationship. */
  connectByGroupIdAndMemberId?: Maybe<Array<MembershipMembershipPkeyConnect>>;
  /** The primary key(s) for `membership` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<MembershipNodeIdConnect>>;
  /** The primary key(s) and patch data for `membership` for the far side of the relationship. */
  updateByGroupIdAndMemberId?: Maybe<Array<MembershipOnMembershipForMembershipGroupIdUsingMembershipPkeyUpdate>>;
  /** The primary key(s) and patch data for `membership` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnMembershipForMembershipGroupIdNodeIdUpdate>>;
  /** A `MembershipInput` object that will be created and connected to this object. */
  create?: Maybe<Array<MembershipGroupIdMembershipCreateInput>>;
};

/** The `membership` to be created by this mutation. */
export type MembershipGroupIdMembershipCreateInput = {
  memberId?: Maybe<Scalars['UUID']>;
  lastReceived?: Maybe<Scalars['Datetime']>;
  groupToGroupId?: Maybe<MembershipGroupIdInput>;
  userToMemberId?: Maybe<MembershipMemberIdInput>;
};

/** An input for mutations affecting `Membership` */
export type MembershipInput = {
  groupId?: Maybe<Scalars['UUID']>;
  memberId?: Maybe<Scalars['UUID']>;
  lastReceived?: Maybe<Scalars['Datetime']>;
  groupToGroupId?: Maybe<MembershipGroupIdInput>;
  userToMemberId?: Maybe<MembershipMemberIdInput>;
};

/** Input for the nested mutation of `user` in the `MembershipInput` mutation. */
export type MembershipMemberIdInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: Maybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: Maybe<UserNodeIdConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteById?: Maybe<UserUserPkeyDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByNodeId?: Maybe<UserNodeIdDelete>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: Maybe<UserOnMembershipForMembershipMemberIdUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: Maybe<MembershipOnMembershipForMembershipMemberIdNodeIdUpdate>;
  /** A `UserInput` object that will be created and connected to this object. */
  create?: Maybe<MembershipMemberIdUserCreateInput>;
};

/** Input for the nested mutation of `membership` in the `UserInput` mutation. */
export type MembershipMemberIdInverseInput = {
  /** The primary key(s) for `membership` for the far side of the relationship. */
  connectByGroupIdAndMemberId?: Maybe<Array<MembershipMembershipPkeyConnect>>;
  /** The primary key(s) for `membership` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<MembershipNodeIdConnect>>;
  /** The primary key(s) and patch data for `membership` for the far side of the relationship. */
  updateByGroupIdAndMemberId?: Maybe<Array<MembershipOnMembershipForMembershipMemberIdUsingMembershipPkeyUpdate>>;
  /** The primary key(s) and patch data for `membership` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<UserOnMembershipForMembershipMemberIdNodeIdUpdate>>;
  /** A `MembershipInput` object that will be created and connected to this object. */
  create?: Maybe<Array<MembershipMemberIdMembershipCreateInput>>;
};

/** The `membership` to be created by this mutation. */
export type MembershipMemberIdMembershipCreateInput = {
  groupId?: Maybe<Scalars['UUID']>;
  lastReceived?: Maybe<Scalars['Datetime']>;
  groupToGroupId?: Maybe<MembershipGroupIdInput>;
  userToMemberId?: Maybe<MembershipMemberIdInput>;
};

/** The `user` to be created by this mutation. */
export type MembershipMemberIdUserCreateInput = {
  name: Scalars['String'];
  kind: UserType;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipMemberIdInverseInput>;
  messagesAuthored?: Maybe<MessageAuthorIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedByFkeyInverseInput>;
  feedbacksUsingId?: Maybe<FeedbackUserIdInverseInput>;
  websitesUsingId?: Maybe<OwnerUserIdInverseInput>;
  userSettingUsingId?: Maybe<UserIdInverseInput>;
};

/** The fields on `membership` to look up the row to connect. */
export type MembershipMembershipPkeyConnect = {
  groupId: Scalars['UUID'];
  memberId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to connect. */
export type MembershipNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `membership` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type MembershipOnMembershipForMembershipGroupIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `membership` to look up the row to update. */
export type MembershipOnMembershipForMembershipGroupIdUsingMembershipPkeyUpdate = {
  /** An object where the defined keys will be set on the `membership` being updated. */
  patch: UpdateMembershipOnMembershipForMembershipGroupIdPatch;
  groupId: Scalars['UUID'];
  memberId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type MembershipOnMembershipForMembershipMemberIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `membership` to look up the row to update. */
export type MembershipOnMembershipForMembershipMemberIdUsingMembershipPkeyUpdate = {
  /** An object where the defined keys will be set on the `membership` being updated. */
  patch: UpdateMembershipOnMembershipForMembershipMemberIdPatch;
  groupId: Scalars['UUID'];
  memberId: Scalars['UUID'];
};

/** Represents an update to a `Membership`. Fields that are set will be updated. */
export type MembershipPatch = {
  groupId?: Maybe<Scalars['UUID']>;
  memberId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  lastRead?: Maybe<Scalars['Datetime']>;
  lastReceived?: Maybe<Scalars['Datetime']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  groupToGroupId?: Maybe<MembershipGroupIdInput>;
  userToMemberId?: Maybe<MembershipMemberIdInput>;
};

/** A connection to a list of `Membership` values. */
export type MembershipsConnection = {
  __typename?: 'MembershipsConnection';
  /** A list of `Membership` objects. */
  nodes: Array<Maybe<Membership>>;
  /** A list of edges which contains the `Membership` and cursor to aid in pagination. */
  edges: Array<MembershipsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Membership` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Membership` edge in the connection. */
export type MembershipsEdge = {
  __typename?: 'MembershipsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Membership` at the end of the edge. */
  node?: Maybe<Membership>;
};

/** Methods to use when ordering `Membership`. */
export enum MembershipsOrderBy {
  Natural = 'NATURAL',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  MemberIdAsc = 'MEMBER_ID_ASC',
  MemberIdDesc = 'MEMBER_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Message = Node & {
  __typename?: 'Message';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  authorId: Scalars['UUID'];
  chatId: Scalars['UUID'];
  content?: Maybe<Scalars['String']>;
  senderKeyId?: Maybe<Scalars['UUID']>;
  /** Reads a single `User` that is related to this `Message`. */
  author?: Maybe<User>;
  /** Reads a single `Group` that is related to this `Message`. */
  chat?: Maybe<Group>;
  /** Reads and enables pagination through a set of `Attachment`. */
  attachments: AttachmentsConnection;
  /** Reads and enables pagination through a set of `Attachment`. */
  attachmentsList: Array<Attachment>;
};


export type MessageAttachmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AttachmentsOrderBy>>;
  condition?: Maybe<AttachmentCondition>;
};


export type MessageAttachmentsListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AttachmentsOrderBy>>;
  condition?: Maybe<AttachmentCondition>;
};

/** Input for the nested mutation of `user` in the `MessageInput` mutation. */
export type MessageAuthorIdInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: Maybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: Maybe<UserNodeIdConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteById?: Maybe<UserUserPkeyDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByNodeId?: Maybe<UserNodeIdDelete>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: Maybe<UserOnMessageForMessageAuthorIdUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: Maybe<MessageOnMessageForMessageAuthorIdNodeIdUpdate>;
  /** A `UserInput` object that will be created and connected to this object. */
  create?: Maybe<MessageAuthorIdUserCreateInput>;
};

/** Input for the nested mutation of `message` in the `UserInput` mutation. */
export type MessageAuthorIdInverseInput = {
  /** Flag indicating whether all other `message` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `message` for the far side of the relationship. */
  connectById?: Maybe<Array<MessageMessagePkeyConnect>>;
  /** The primary key(s) for `message` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<MessageNodeIdConnect>>;
  /** The primary key(s) for `message` for the far side of the relationship. */
  deleteById?: Maybe<Array<MessageMessagePkeyDelete>>;
  /** The primary key(s) for `message` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<MessageNodeIdDelete>>;
  /** The primary key(s) and patch data for `message` for the far side of the relationship. */
  updateById?: Maybe<Array<MessageOnMessageForMessageAuthorIdUsingMessagePkeyUpdate>>;
  /** The primary key(s) and patch data for `message` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<UserOnMessageForMessageAuthorIdNodeIdUpdate>>;
  /** A `MessageInput` object that will be created and connected to this object. */
  create?: Maybe<Array<MessageAuthorIdMessageCreateInput>>;
};

/** The `message` to be created by this mutation. */
export type MessageAuthorIdMessageCreateInput = {
  chatId?: Maybe<Scalars['UUID']>;
  content?: Maybe<Scalars['String']>;
  senderKeyId?: Maybe<Scalars['UUID']>;
  userToAuthorId?: Maybe<MessageAuthorIdInput>;
  groupToChatId?: Maybe<MessageChatIdInput>;
  attachmentsUsingId?: Maybe<AttachmentMessageIdInverseInput>;
};

/** The `user` to be created by this mutation. */
export type MessageAuthorIdUserCreateInput = {
  name: Scalars['String'];
  kind: UserType;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipMemberIdInverseInput>;
  messagesAuthored?: Maybe<MessageAuthorIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedByFkeyInverseInput>;
  feedbacksUsingId?: Maybe<FeedbackUserIdInverseInput>;
  websitesUsingId?: Maybe<OwnerUserIdInverseInput>;
  userSettingUsingId?: Maybe<UserIdInverseInput>;
};

/** The `group` to be created by this mutation. */
export type MessageChatIdGroupCreateInput = {
  kind: GroupType;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipGroupIdInverseInput>;
  messages?: Maybe<MessageChatIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedToFkeyInverseInput>;
  senderKeysUsingId?: Maybe<SenderKeyGroupIdInverseInput>;
};

/** Input for the nested mutation of `group` in the `MessageInput` mutation. */
export type MessageChatIdInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnMessageForMessageChatIdUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<MessageOnMessageForMessageChatIdNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<MessageChatIdGroupCreateInput>;
};

/** Input for the nested mutation of `message` in the `GroupInput` mutation. */
export type MessageChatIdInverseInput = {
  /** Flag indicating whether all other `message` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `message` for the far side of the relationship. */
  connectById?: Maybe<Array<MessageMessagePkeyConnect>>;
  /** The primary key(s) for `message` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<MessageNodeIdConnect>>;
  /** The primary key(s) for `message` for the far side of the relationship. */
  deleteById?: Maybe<Array<MessageMessagePkeyDelete>>;
  /** The primary key(s) for `message` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<MessageNodeIdDelete>>;
  /** The primary key(s) and patch data for `message` for the far side of the relationship. */
  updateById?: Maybe<Array<MessageOnMessageForMessageChatIdUsingMessagePkeyUpdate>>;
  /** The primary key(s) and patch data for `message` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnMessageForMessageChatIdNodeIdUpdate>>;
  /** A `MessageInput` object that will be created and connected to this object. */
  create?: Maybe<Array<MessageChatIdMessageCreateInput>>;
};

/** The `message` to be created by this mutation. */
export type MessageChatIdMessageCreateInput = {
  authorId?: Maybe<Scalars['UUID']>;
  content?: Maybe<Scalars['String']>;
  senderKeyId?: Maybe<Scalars['UUID']>;
  userToAuthorId?: Maybe<MessageAuthorIdInput>;
  groupToChatId?: Maybe<MessageChatIdInput>;
  attachmentsUsingId?: Maybe<AttachmentMessageIdInverseInput>;
};

/** A condition to be used against `Message` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type MessageCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `chatId` field. */
  chatId?: Maybe<Scalars['UUID']>;
};

/** An input for mutations affecting `Message` */
export type MessageInput = {
  authorId?: Maybe<Scalars['UUID']>;
  chatId?: Maybe<Scalars['UUID']>;
  content?: Maybe<Scalars['String']>;
  senderKeyId?: Maybe<Scalars['UUID']>;
  userToAuthorId?: Maybe<MessageAuthorIdInput>;
  groupToChatId?: Maybe<MessageChatIdInput>;
  attachmentsUsingId?: Maybe<AttachmentMessageIdInverseInput>;
};

/** The fields on `message` to look up the row to connect. */
export type MessageMessagePkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `message` to look up the row to delete. */
export type MessageMessagePkeyDelete = {
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to connect. */
export type MessageNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `message` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type MessageNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `message` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type MessageOnAttachmentForAttachmentMessageIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `attachment` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `attachment` being updated. */
  patch: AttachmentPatch;
};

/** The fields on `message` to look up the row to update. */
export type MessageOnAttachmentForAttachmentMessageIdUsingMessagePkeyUpdate = {
  /** An object where the defined keys will be set on the `message` being updated. */
  patch: UpdateMessageOnAttachmentForAttachmentMessageIdPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type MessageOnMessageForMessageAuthorIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `message` to look up the row to update. */
export type MessageOnMessageForMessageAuthorIdUsingMessagePkeyUpdate = {
  /** An object where the defined keys will be set on the `message` being updated. */
  patch: UpdateMessageOnMessageForMessageAuthorIdPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type MessageOnMessageForMessageChatIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `message` to look up the row to update. */
export type MessageOnMessageForMessageChatIdUsingMessagePkeyUpdate = {
  /** An object where the defined keys will be set on the `message` being updated. */
  patch: UpdateMessageOnMessageForMessageChatIdPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `Message`. Fields that are set will be updated. */
export type MessagePatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  authorId?: Maybe<Scalars['UUID']>;
  chatId?: Maybe<Scalars['UUID']>;
  content?: Maybe<Scalars['String']>;
  senderKeyId?: Maybe<Scalars['UUID']>;
  userToAuthorId?: Maybe<MessageAuthorIdInput>;
  groupToChatId?: Maybe<MessageChatIdInput>;
  attachmentsUsingId?: Maybe<AttachmentMessageIdInverseInput>;
};

/** A connection to a list of `Message` values. */
export type MessagesConnection = {
  __typename?: 'MessagesConnection';
  /** A list of `Message` objects. */
  nodes: Array<Maybe<Message>>;
  /** A list of edges which contains the `Message` and cursor to aid in pagination. */
  edges: Array<MessagesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Message` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Message` edge in the connection. */
export type MessagesEdge = {
  __typename?: 'MessagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Message` at the end of the edge. */
  node?: Maybe<Message>;
};

/** Methods to use when ordering `Message`. */
export enum MessagesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  AuthorIdAsc = 'AUTHOR_ID_ASC',
  AuthorIdDesc = 'AUTHOR_ID_DESC',
  ChatIdAsc = 'CHAT_ID_ASC',
  ChatIdDesc = 'CHAT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type MessageSubscriptionPayload = {
  __typename?: 'MessageSubscriptionPayload';
  message: Message;
};

export type Metadata = {
  __typename?: 'Metadata';
  valid: Scalars['Boolean'];
  url: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a single `Attachment`. */
  createAttachment?: Maybe<CreateAttachmentPayload>;
  /** Creates a single `Feedback`. */
  createFeedback?: Maybe<CreateFeedbackPayload>;
  /** Creates a single `Group`. */
  createGroup?: Maybe<CreateGroupPayload>;
  /** Creates a single `Membership`. */
  createMembership?: Maybe<CreateMembershipPayload>;
  /** Creates a single `Message`. */
  createMessage?: Maybe<CreateMessagePayload>;
  /** Creates a single `SenderKey`. */
  createSenderKey?: Maybe<CreateSenderKeyPayload>;
  /** Creates a single `User`. */
  createUser?: Maybe<CreateUserPayload>;
  /** Creates a single `UserSetting`. */
  createUserSetting?: Maybe<CreateUserSettingPayload>;
  /** Creates a single `Website`. */
  createWebsite?: Maybe<CreateWebsitePayload>;
  /** Updates a single `Attachment` using its globally unique id and a patch. */
  updateAttachmentByNodeId?: Maybe<UpdateAttachmentPayload>;
  /** Updates a single `Attachment` using a unique key and a patch. */
  updateAttachment?: Maybe<UpdateAttachmentPayload>;
  /** Updates a single `Group` using its globally unique id and a patch. */
  updateGroupByNodeId?: Maybe<UpdateGroupPayload>;
  /** Updates a single `Group` using a unique key and a patch. */
  updateGroup?: Maybe<UpdateGroupPayload>;
  /** Updates a single `Membership` using its globally unique id and a patch. */
  updateMembershipByNodeId?: Maybe<UpdateMembershipPayload>;
  /** Updates a single `Membership` using a unique key and a patch. */
  updateMembership?: Maybe<UpdateMembershipPayload>;
  /** Updates a single `Message` using its globally unique id and a patch. */
  updateMessageByNodeId?: Maybe<UpdateMessagePayload>;
  /** Updates a single `Message` using a unique key and a patch. */
  updateMessage?: Maybe<UpdateMessagePayload>;
  /** Updates a single `User` using its globally unique id and a patch. */
  updateUserByNodeId?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Updates a single `UserSetting` using its globally unique id and a patch. */
  updateUserSettingByNodeId?: Maybe<UpdateUserSettingPayload>;
  /** Updates a single `UserSetting` using a unique key and a patch. */
  updateUserSetting?: Maybe<UpdateUserSettingPayload>;
  /** Updates a single `Website` using its globally unique id and a patch. */
  updateWebsiteByNodeId?: Maybe<UpdateWebsitePayload>;
  /** Updates a single `Website` using a unique key and a patch. */
  updateWebsite?: Maybe<UpdateWebsitePayload>;
  /** Deletes a single `Feedback` using its globally unique id. */
  deleteFeedbackByNodeId?: Maybe<DeleteFeedbackPayload>;
  /** Deletes a single `Feedback` using a unique key. */
  deleteFeedback?: Maybe<DeleteFeedbackPayload>;
  /** Deletes a single `Invitation` using its globally unique id. */
  deleteInvitationByNodeId?: Maybe<DeleteInvitationPayload>;
  /** Deletes a single `Invitation` using a unique key. */
  deleteInvitation?: Maybe<DeleteInvitationPayload>;
  /** Deletes a single `Message` using its globally unique id. */
  deleteMessageByNodeId?: Maybe<DeleteMessagePayload>;
  /** Deletes a single `Message` using a unique key. */
  deleteMessage?: Maybe<DeleteMessagePayload>;
  /** Deletes a single `User` using its globally unique id. */
  deleteUserByNodeId?: Maybe<DeleteUserPayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUser?: Maybe<DeleteUserPayload>;
  /** Deletes a single `UserSetting` using its globally unique id. */
  deleteUserSettingByNodeId?: Maybe<DeleteUserSettingPayload>;
  /** Deletes a single `UserSetting` using a unique key. */
  deleteUserSetting?: Maybe<DeleteUserSettingPayload>;
  /** Deletes a single `Website` using its globally unique id. */
  deleteWebsiteByNodeId?: Maybe<DeleteWebsitePayload>;
  /** Deletes a single `Website` using a unique key. */
  deleteWebsite?: Maybe<DeleteWebsitePayload>;
  /** Accepts the given invitation to a group if the invited user is logged in */
  acceptInvitationToGroup?: Maybe<AcceptInvitationToGroupPayload>;
  activateEncryptionForSenior?: Maybe<ActivateEncryptionForSeniorPayload>;
  activateLicense?: Maybe<ActivateLicensePayload>;
  /** Add or update a user device */
  addUserDevice?: Maybe<AddUserDevicePayload>;
  /** Creates or returns the private chat between the two given users */
  createOrRetrievePrivateChat?: Maybe<CreateOrRetrievePrivateChatPayload>;
  /** Creates a new account for a senior */
  createSenior?: Maybe<CreateSeniorPayload>;
  /** Send system message to a user */
  createSystemMessage?: Maybe<CreateSystemMessagePayload>;
  /** Deregisters a device from receiving push notifications */
  deregisterPushNotificationDevice?: Maybe<DeregisterPushNotificationDevicePayload>;
  /** Sends a password reset email if the given email address is known */
  forgotPassword?: Maybe<ForgotPasswordPayload>;
  /** Sends an invitation email */
  invite?: Maybe<InvitePayload>;
  /** Checks if the android device is supported to run versions greater than 3.x */
  isAndroidDeviceSupported?: Maybe<IsAndroidDeviceSupportedPayload>;
  /** Checks eligibility of a tablet for update to 3.x */
  isTabletUpdatable?: Maybe<IsTabletUpdatablePayload>;
  /** Authenticates the user */
  login?: Maybe<LoginPayload>;
  /** Logs in or creates a senior, given a device serial number and a name */
  loginOrCreateSenior?: Maybe<LoginOrCreateSeniorPayload>;
  /** Authenticates a senior user given a licence */
  loginSenior?: Maybe<LoginSeniorPayload>;
  /** Sets the `last_read` timestamp of a user in chat as defined by the given `message_id`. */
  markAsRead?: Maybe<MarkAsReadPayload>;
  /** Sets the `last_received` timestamp of a user in chat as defined by the given `message_id`. */
  markAsReceived?: Maybe<MarkAsReceivedPayload>;
  registerPushNotificationDevice?: Maybe<RegisterPushNotificationDevicePayload>;
  /** Creates a new user, given that the current jwt sub corresponds to a valid invitation */
  registerUser?: Maybe<RegisterUserPayload>;
  /** Resend invitation email for a given id */
  resendInvitation?: Maybe<ResendInvitationPayload>;
  /** Resets a password, given a valid password request */
  resetPassword?: Maybe<ResetPasswordPayload>;
  /** Add or update mdapp backup */
  saveMdappBackup?: Maybe<SaveMdappBackupPayload>;
  sendLicensingPortalInvitation?: Maybe<SendLicensingPortalInvitationPayload>;
  sendQrCodeSalesforce?: Maybe<SendQrCodeSalesforcePayload>;
  sendQrcode?: Maybe<SendQrcodePayload>;
  /** Sends a video call event to a group. */
  sendVideocallEvent?: Maybe<SendVideocallEventPayload>;
  /** (Testing Environment) Sets the `last_read` timestamp of a user in chat. */
  testEnvUpdateLastRead?: Maybe<TestEnvUpdateLastReadPayload>;
  /** (Testing Environment) Sets the `created_at` timestamp of message. */
  testEnvUpdateMessageCreatedAt?: Maybe<TestEnvUpdateMessageCreatedAtPayload>;
  /** Changes user credentials. */
  updateUserCredentials?: Maybe<UpdateUserCredentialsPayload>;
  /** Changes user password. */
  updateUserPassword?: Maybe<UpdateUserPasswordPayload>;
  refreshAccessToken?: Maybe<Scalars['String']>;
  registerCatapushDevice: CatapushPassword;
  deRegisterCatapushDevice?: Maybe<Scalars['Boolean']>;
  generateToyDataset: Scalars['Boolean'];
  generateToyDatasetFromFamily: GenerateToyDatasetFromFamilyReturn;
  createSeniorProfile: NewProfile;
  createTestCustomer: Scalars['Boolean'];
  syncContractItemSalesforce: Scalars['String'];
  deactivateLicense: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAttachmentArgs = {
  input: CreateAttachmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMembershipArgs = {
  input: CreateMembershipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMessageArgs = {
  input: CreateMessageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSenderKeyArgs = {
  input: CreateSenderKeyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserSettingArgs = {
  input: CreateUserSettingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWebsiteArgs = {
  input: CreateWebsiteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAttachmentByNodeIdArgs = {
  input: UpdateAttachmentByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAttachmentArgs = {
  input: UpdateAttachmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupByNodeIdArgs = {
  input: UpdateGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMembershipByNodeIdArgs = {
  input: UpdateMembershipByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMembershipArgs = {
  input: UpdateMembershipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMessageByNodeIdArgs = {
  input: UpdateMessageByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMessageArgs = {
  input: UpdateMessageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByNodeIdArgs = {
  input: UpdateUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserSettingByNodeIdArgs = {
  input: UpdateUserSettingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserSettingArgs = {
  input: UpdateUserSettingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWebsiteByNodeIdArgs = {
  input: UpdateWebsiteByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWebsiteArgs = {
  input: UpdateWebsiteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedbackByNodeIdArgs = {
  input: DeleteFeedbackByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedbackArgs = {
  input: DeleteFeedbackInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInvitationByNodeIdArgs = {
  input: DeleteInvitationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInvitationArgs = {
  input: DeleteInvitationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMessageByNodeIdArgs = {
  input: DeleteMessageByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMessageArgs = {
  input: DeleteMessageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserByNodeIdArgs = {
  input: DeleteUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserSettingByNodeIdArgs = {
  input: DeleteUserSettingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserSettingArgs = {
  input: DeleteUserSettingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWebsiteByNodeIdArgs = {
  input: DeleteWebsiteByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWebsiteArgs = {
  input: DeleteWebsiteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAcceptInvitationToGroupArgs = {
  input: AcceptInvitationToGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationActivateEncryptionForSeniorArgs = {
  input: ActivateEncryptionForSeniorInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationActivateLicenseArgs = {
  input: ActivateLicenseInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddUserDeviceArgs = {
  input: AddUserDeviceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrRetrievePrivateChatArgs = {
  input: CreateOrRetrievePrivateChatInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSeniorArgs = {
  input: CreateSeniorInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSystemMessageArgs = {
  input: CreateSystemMessageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeregisterPushNotificationDeviceArgs = {
  input: DeregisterPushNotificationDeviceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationInviteArgs = {
  input: InviteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationIsAndroidDeviceSupportedArgs = {
  input: IsAndroidDeviceSupportedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationIsTabletUpdatableArgs = {
  input: IsTabletUpdatableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLoginArgs = {
  input: LoginInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLoginOrCreateSeniorArgs = {
  input: LoginOrCreateSeniorInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLoginSeniorArgs = {
  input: LoginSeniorInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMarkAsReadArgs = {
  input: MarkAsReadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMarkAsReceivedArgs = {
  input: MarkAsReceivedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterPushNotificationDeviceArgs = {
  input: RegisterPushNotificationDeviceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResendInvitationArgs = {
  input: ResendInvitationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSaveMdappBackupArgs = {
  input: SaveMdappBackupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSendLicensingPortalInvitationArgs = {
  input: SendLicensingPortalInvitationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSendQrCodeSalesforceArgs = {
  input: SendQrCodeSalesforceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSendQrcodeArgs = {
  input: SendQrcodeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSendVideocallEventArgs = {
  input: SendVideocallEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTestEnvUpdateLastReadArgs = {
  input: TestEnvUpdateLastReadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationTestEnvUpdateMessageCreatedAtArgs = {
  input: TestEnvUpdateMessageCreatedAtInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserCredentialsArgs = {
  input: UpdateUserCredentialsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRegisterCatapushDeviceArgs = {
  deviceId: Scalars['String'];
  deviceModel: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeRegisterCatapushDeviceArgs = {
  deviceId: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateToyDatasetArgs = {
  email: Scalars['String'];
  numberOfMessages?: Maybe<Scalars['Int']>;
  numberOfImages?: Maybe<Scalars['Int']>;
  numberOfUnread?: Maybe<Scalars['Int']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateToyDatasetFromFamilyArgs = {
  email: Scalars['String'];
  numberOfRelatives?: Maybe<Scalars['Int']>;
  numberOfMessages?: Maybe<Scalars['Int']>;
  numberOfImages?: Maybe<Scalars['Int']>;
  numberOfUnread?: Maybe<Scalars['Int']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSeniorProfileArgs = {
  name: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTestCustomerArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
  flavour: Flavour;
  contractStatus: ContractStatus;
  swMode?: Maybe<SwMode>;
  contentGroup?: Maybe<Scalars['String']>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSyncContractItemSalesforceArgs = {
  accountId: Scalars['String'];
  accountName: Scalars['String'];
  contactName: Scalars['String'];
  contactEmail: Scalars['String'];
  contractId: Scalars['String'];
  contractName: Scalars['String'];
  contractStartDate: Scalars['String'];
  contractEndDate: Scalars['String'];
  contractStatus: Scalars['String'];
  contractProductFamily: Scalars['String'];
  contractProductName: Scalars['String'];
  contractSponsoringKrankenkasse: Scalars['String'];
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeactivateLicenseArgs = {
  id: Scalars['String'];
};

export type NewProfile = {
  __typename?: 'NewProfile';
  id: Scalars['UUID'];
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** Input for the nested mutation of `user` in the `WebsiteInput` mutation. */
export type OwnerUserIdInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: Maybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: Maybe<UserNodeIdConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteById?: Maybe<UserUserPkeyDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByNodeId?: Maybe<UserNodeIdDelete>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: Maybe<UserOnWebsiteForOwnerUserIdUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: Maybe<WebsiteOnWebsiteForOwnerUserIdNodeIdUpdate>;
  /** A `UserInput` object that will be created and connected to this object. */
  create?: Maybe<OwnerUserIdUserCreateInput>;
};

/** Input for the nested mutation of `website` in the `UserInput` mutation. */
export type OwnerUserIdInverseInput = {
  /** Flag indicating whether all other `website` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `website` for the far side of the relationship. */
  connectById?: Maybe<Array<WebsiteWebsitePkeyConnect>>;
  /** The primary key(s) for `website` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<WebsiteNodeIdConnect>>;
  /** The primary key(s) for `website` for the far side of the relationship. */
  deleteById?: Maybe<Array<WebsiteWebsitePkeyDelete>>;
  /** The primary key(s) for `website` for the far side of the relationship. */
  deleteByNodeId?: Maybe<Array<WebsiteNodeIdDelete>>;
  /** The primary key(s) and patch data for `website` for the far side of the relationship. */
  updateById?: Maybe<Array<WebsiteOnWebsiteForOwnerUserIdUsingWebsitePkeyUpdate>>;
  /** The primary key(s) and patch data for `website` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<UserOnWebsiteForOwnerUserIdNodeIdUpdate>>;
  /** A `WebsiteInput` object that will be created and connected to this object. */
  create?: Maybe<Array<OwnerUserIdWebsiteCreateInput>>;
};

/** The `user` to be created by this mutation. */
export type OwnerUserIdUserCreateInput = {
  name: Scalars['String'];
  kind: UserType;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipMemberIdInverseInput>;
  messagesAuthored?: Maybe<MessageAuthorIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedByFkeyInverseInput>;
  feedbacksUsingId?: Maybe<FeedbackUserIdInverseInput>;
  websitesUsingId?: Maybe<OwnerUserIdInverseInput>;
  userSettingUsingId?: Maybe<UserIdInverseInput>;
};

/** The `website` to be created by this mutation. */
export type OwnerUserIdWebsiteCreateInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  url: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  userToUserId?: Maybe<OwnerUserIdInput>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** Reads and enables pagination through a set of `Attachment`. */
  attachments?: Maybe<AttachmentsConnection>;
  /** Reads a set of `Attachment`. */
  attachmentsList?: Maybe<Array<Attachment>>;
  /** Reads and enables pagination through a set of `Feedback`. */
  feedbacks?: Maybe<FeedbacksConnection>;
  /** Reads a set of `Feedback`. */
  feedbacksList?: Maybe<Array<Feedback>>;
  /** Reads and enables pagination through a set of `Group`. */
  groups?: Maybe<GroupsConnection>;
  /** Reads a set of `Group`. */
  groupsList?: Maybe<Array<Group>>;
  /** Reads and enables pagination through a set of `Membership`. */
  memberships?: Maybe<MembershipsConnection>;
  /** Reads a set of `Membership`. */
  membershipsList?: Maybe<Array<Membership>>;
  /** Reads and enables pagination through a set of `Message`. */
  messages?: Maybe<MessagesConnection>;
  /** Reads a set of `Message`. */
  messagesList?: Maybe<Array<Message>>;
  /** Reads and enables pagination through a set of `SenderKey`. */
  senderKeys?: Maybe<SenderKeysConnection>;
  /** Reads a set of `SenderKey`. */
  senderKeysList?: Maybe<Array<SenderKey>>;
  /** Reads and enables pagination through a set of `User`. */
  users?: Maybe<UsersConnection>;
  /** Reads a set of `User`. */
  usersList?: Maybe<Array<User>>;
  /** Reads and enables pagination through a set of `UserRelation`. */
  userRelations?: Maybe<UserRelationsConnection>;
  /** Reads a set of `UserRelation`. */
  userRelationsList?: Maybe<Array<UserRelation>>;
  /** Reads and enables pagination through a set of `UserSetting`. */
  userSettings?: Maybe<UserSettingsConnection>;
  /** Reads a set of `UserSetting`. */
  userSettingsList?: Maybe<Array<UserSetting>>;
  /** Reads and enables pagination through a set of `Website`. */
  websites?: Maybe<WebsitesConnection>;
  /** Reads a set of `Website`. */
  websitesList?: Maybe<Array<Website>>;
  attachment?: Maybe<Attachment>;
  feedback?: Maybe<Feedback>;
  group?: Maybe<Group>;
  invitation?: Maybe<Invitation>;
  membership?: Maybe<Membership>;
  message?: Maybe<Message>;
  senderKey?: Maybe<SenderKey>;
  senderKeyByKeyHash?: Maybe<SenderKey>;
  user?: Maybe<User>;
  userSetting?: Maybe<UserSetting>;
  website?: Maybe<Website>;
  chromeverToInt?: Maybe<Scalars['BigInt']>;
  /** Returns the members of every family chat the current user is member of */
  contacts: UsersConnection;
  /** Returns the members of every family chat the current user is member of */
  contactsList?: Maybe<Array<Maybe<User>>>;
  currentUserId?: Maybe<Scalars['UUID']>;
  /** Reads and enables pagination through a set of `Attachment`. */
  getAttachments: AttachmentsConnection;
  /** Reads and enables pagination through a set of `Attachment`. */
  getAttachmentsList?: Maybe<Array<Maybe<Attachment>>>;
  /** Reads and enables pagination through a set of `Attachment`. */
  getSeniorProfileAttachments: AttachmentsConnection;
  /** Reads and enables pagination through a set of `Attachment`. */
  getSeniorProfileAttachmentsList?: Maybe<Array<Maybe<Attachment>>>;
  /** Reads and enables pagination through a set of `SystemMessage`. */
  getSystemMessages: SystemMessagesConnection;
  /** Reads and enables pagination through a set of `SystemMessage`. */
  getSystemMessagesList?: Maybe<Array<Maybe<SystemMessage>>>;
  /** Returns the list of pending invitations for the current user */
  groupInvitations: InvitationsConnection;
  /** Returns the list of pending invitations for the current user */
  groupInvitationsList?: Maybe<Array<Maybe<Invitation>>>;
  /** Returns the list of pending invitations for the current user */
  invitations: InvitationsConnection;
  /** Returns the list of pending invitations for the current user */
  invitationsList?: Maybe<Array<Maybe<Invitation>>>;
  /** Returns unread messages for an user. */
  me?: Maybe<User>;
  /** Returns the most recent video call event sent by a user to a group. */
  mostRecentVideocallEvent?: Maybe<VideocallEventType>;
  semverToInt?: Maybe<Scalars['Int']>;
  seniorRecoveryKeys: SeniorRecoveryKeysConnection;
  seniorRecoveryKeysList?: Maybe<Array<Maybe<SeniorRecoveryKeysRecord>>>;
  supportedVideocallVersion?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `Message`. */
  unreadMessages: MessagesConnection;
  /** Reads and enables pagination through a set of `Message`. */
  unreadMessagesList?: Maybe<Array<Maybe<Message>>>;
  /** Reads a single `Attachment` using its globally unique `ID`. */
  attachmentByNodeId?: Maybe<Attachment>;
  /** Reads a single `Feedback` using its globally unique `ID`. */
  feedbackByNodeId?: Maybe<Feedback>;
  /** Reads a single `Group` using its globally unique `ID`. */
  groupByNodeId?: Maybe<Group>;
  /** Reads a single `Invitation` using its globally unique `ID`. */
  invitationByNodeId?: Maybe<Invitation>;
  /** Reads a single `Membership` using its globally unique `ID`. */
  membershipByNodeId?: Maybe<Membership>;
  /** Reads a single `Message` using its globally unique `ID`. */
  messageByNodeId?: Maybe<Message>;
  /** Reads a single `SenderKey` using its globally unique `ID`. */
  senderKeyByNodeId?: Maybe<SenderKey>;
  /** Reads a single `User` using its globally unique `ID`. */
  userByNodeId?: Maybe<User>;
  /** Reads a single `UserSetting` using its globally unique `ID`. */
  userSettingByNodeId?: Maybe<UserSetting>;
  /** Reads a single `Website` using its globally unique `ID`. */
  websiteByNodeId?: Maybe<Website>;
  getUploadLinks?: Maybe<Array<UploadLink>>;
  getUploadLinksWithKeys?: Maybe<Array<UploadLink>>;
  getDownloadLink: UploadLink;
  verifyToken?: Maybe<Response>;
  getWebSiteMetadata?: Maybe<Metadata>;
  updateLink?: Maybe<Scalars['String']>;
  getSwOnlyAppDownloadLink: Scalars['String'];
  videocallToken: Scalars['String'];
  videocallInvite: Scalars['Boolean'];
  videocallStart: Scalars['String'];
  videocallJoin: Scalars['String'];
  videocallHangup?: Maybe<Scalars['Boolean']>;
  checkHealth?: Maybe<Scalars['Boolean']>;
  licenses?: Maybe<Array<License>>;
  getAccounts?: Maybe<Array<AccountData>>;
  getCustomers?: Maybe<Array<Customer>>;
  getLicense?: Maybe<LicenseData>;
  getUserFlavour?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAttachmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AttachmentsOrderBy>>;
  condition?: Maybe<AttachmentCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAttachmentsListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<AttachmentsOrderBy>>;
  condition?: Maybe<AttachmentCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbacksArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FeedbacksOrderBy>>;
  condition?: Maybe<FeedbackCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbacksListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<FeedbacksOrderBy>>;
  condition?: Maybe<FeedbackCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupsListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MembershipsOrderBy>>;
  condition?: Maybe<MembershipCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipsListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MembershipsOrderBy>>;
  condition?: Maybe<MembershipCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMessagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MessagesOrderBy>>;
  condition?: Maybe<MessageCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMessagesListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MessagesOrderBy>>;
  condition?: Maybe<MessageCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySenderKeysArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SenderKeysOrderBy>>;
  condition?: Maybe<SenderKeyCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySenderKeysListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<SenderKeysOrderBy>>;
  condition?: Maybe<SenderKeyCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserRelationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserRelationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserRelationsListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<UserRelationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserSettingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserSettingsOrderBy>>;
  condition?: Maybe<UserSettingCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserSettingsListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<UserSettingsOrderBy>>;
  condition?: Maybe<UserSettingCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWebsitesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WebsitesOrderBy>>;
  condition?: Maybe<WebsiteCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWebsitesListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<WebsitesOrderBy>>;
  condition?: Maybe<WebsiteCondition>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAttachmentArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvitationArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipArgs = {
  groupId: Scalars['UUID'];
  memberId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMessageArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySenderKeyArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySenderKeyByKeyHashArgs = {
  keyHash: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserSettingArgs = {
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWebsiteArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryChromeverToIntArgs = {
  version?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryContactsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryContactsListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAttachmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAttachmentsListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetSeniorProfileAttachmentsArgs = {
  seniorProfileId?: Maybe<Scalars['UUID']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetSeniorProfileAttachmentsListArgs = {
  seniorProfileId?: Maybe<Scalars['UUID']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetSystemMessagesArgs = {
  upperBound?: Maybe<Scalars['Datetime']>;
  lowerBound?: Maybe<Scalars['Datetime']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetSystemMessagesListArgs = {
  upperBound?: Maybe<Scalars['Datetime']>;
  lowerBound?: Maybe<Scalars['Datetime']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupInvitationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupInvitationsListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryInvitationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryInvitationsListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMostRecentVideocallEventArgs = {
  senderId?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySemverToIntArgs = {
  version?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySeniorRecoveryKeysArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySeniorRecoveryKeysListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySupportedVideocallVersionArgs = {
  groupId?: Maybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUnreadMessagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUnreadMessagesListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAttachmentByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvitationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMessageByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySenderKeyByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserSettingByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWebsiteByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetUploadLinksArgs = {
  n: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetUploadLinksWithKeysArgs = {
  keys: Array<Maybe<Scalars['String']>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetDownloadLinkArgs = {
  key: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVerifyTokenArgs = {
  token: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetWebSiteMetadataArgs = {
  url: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUpdateLinkArgs = {
  version: Scalars['String'];
  flavour?: Maybe<Scalars['String']>;
  swMode?: Maybe<SwMode>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetSwOnlyAppDownloadLinkArgs = {
  shortId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVideocallTokenArgs = {
  chatId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVideocallInviteArgs = {
  chatId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVideocallStartArgs = {
  chatId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVideocallJoinArgs = {
  chatId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVideocallHangupArgs = {
  chatId: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLicensesArgs = {
  customerId?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCustomersArgs = {
  accountId?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLicenseArgs = {
  licenseId?: Maybe<Scalars['String']>;
};

/** All input for the `registerPushNotificationDevice` mutation. */
export type RegisterPushNotificationDeviceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['UUID']>;
  token?: Maybe<Scalars['String']>;
  deviceModel?: Maybe<Scalars['String']>;
};

/** The output of our `registerPushNotificationDevice` mutation. */
export type RegisterPushNotificationDevicePayload = {
  __typename?: 'RegisterPushNotificationDevicePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `registerUser` mutation. */
export type RegisterUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  password: Scalars['String'];
};

/** The output of our `registerUser` mutation. */
export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  jwtToken?: Maybe<Scalars['JwtToken']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `resendInvitation` mutation. */
export type ResendInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['UUID']>;
};

/** The output of our `resendInvitation` mutation. */
export type ResendInvitationPayload = {
  __typename?: 'ResendInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `resetPassword` mutation. */
export type ResetPasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  newPassword: Scalars['String'];
};

/** The output of our `resetPassword` mutation. */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  jwtToken?: Maybe<Scalars['JwtToken']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type Response = {
  __typename?: 'Response';
  valid: Scalars['Boolean'];
  used: Scalars['Boolean'];
  expired: Scalars['Boolean'];
  role: Scalars['String'];
  kind?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  sub?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
};

/** All input for the `saveMdappBackup` mutation. */
export type SaveMdappBackupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['UUID']>;
  utilizationMode?: Maybe<Scalars['String']>;
  deviceData?: Maybe<Scalars['JSON']>;
};

/** The output of our `saveMdappBackup` mutation. */
export type SaveMdappBackupPayload = {
  __typename?: 'SaveMdappBackupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type SenderKey = Node & {
  __typename?: 'SenderKey';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  userId: Scalars['UUID'];
  groupId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  keyHash: Scalars['String'];
  /** Reads a single `Group` that is related to this `SenderKey`. */
  group?: Maybe<Group>;
};

/**
 * A condition to be used against `SenderKey` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SenderKeyCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `keyHash` field. */
  keyHash?: Maybe<Scalars['String']>;
};

/** The `group` to be created by this mutation. */
export type SenderKeyGroupIdGroupCreateInput = {
  kind: GroupType;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipGroupIdInverseInput>;
  messages?: Maybe<MessageChatIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedToFkeyInverseInput>;
  senderKeysUsingId?: Maybe<SenderKeyGroupIdInverseInput>;
};

/** Input for the nested mutation of `group` in the `SenderKeyInput` mutation. */
export type SenderKeyGroupIdInput = {
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectById?: Maybe<GroupGroupPkeyConnect>;
  /** The primary key(s) for `group` for the far side of the relationship. */
  connectByNodeId?: Maybe<GroupNodeIdConnect>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateById?: Maybe<GroupOnSenderKeyForSenderKeyGroupIdUsingGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `group` for the far side of the relationship. */
  updateByNodeId?: Maybe<SenderKeyOnSenderKeyForSenderKeyGroupIdNodeIdUpdate>;
  /** A `GroupInput` object that will be created and connected to this object. */
  create?: Maybe<SenderKeyGroupIdGroupCreateInput>;
};

/** Input for the nested mutation of `senderKey` in the `GroupInput` mutation. */
export type SenderKeyGroupIdInverseInput = {
  /** The primary key(s) for `senderKey` for the far side of the relationship. */
  connectById?: Maybe<Array<SenderKeySenderKeyPkeyConnect>>;
  /** The primary key(s) for `senderKey` for the far side of the relationship. */
  connectByKeyHash?: Maybe<Array<SenderKeySenderKeyKeyHashKeyConnect>>;
  /** The primary key(s) for `senderKey` for the far side of the relationship. */
  connectByNodeId?: Maybe<Array<SenderKeyNodeIdConnect>>;
  /** The primary key(s) and patch data for `senderKey` for the far side of the relationship. */
  updateById?: Maybe<Array<SenderKeyOnSenderKeyForSenderKeyGroupIdUsingSenderKeyPkeyUpdate>>;
  /** The primary key(s) and patch data for `senderKey` for the far side of the relationship. */
  updateByKeyHash?: Maybe<Array<SenderKeyOnSenderKeyForSenderKeyGroupIdUsingSenderKeyKeyHashKeyUpdate>>;
  /** The primary key(s) and patch data for `senderKey` for the far side of the relationship. */
  updateByNodeId?: Maybe<Array<GroupOnSenderKeyForSenderKeyGroupIdNodeIdUpdate>>;
  /** A `SenderKeyInput` object that will be created and connected to this object. */
  create?: Maybe<Array<SenderKeyGroupIdSenderKeyCreateInput>>;
};

/** The `senderKey` to be created by this mutation. */
export type SenderKeyGroupIdSenderKeyCreateInput = {
  userId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  keyHash: Scalars['String'];
  groupToGroupId?: Maybe<SenderKeyGroupIdInput>;
};

/** An input for mutations affecting `SenderKey` */
export type SenderKeyInput = {
  userId: Scalars['UUID'];
  groupId?: Maybe<Scalars['UUID']>;
  deviceId: Scalars['UUID'];
  keyHash: Scalars['String'];
  groupToGroupId?: Maybe<SenderKeyGroupIdInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type SenderKeyNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `senderKey` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SenderKeyOnSenderKeyForSenderKeyGroupIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `group` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `group` being updated. */
  patch: GroupPatch;
};

/** The fields on `senderKey` to look up the row to update. */
export type SenderKeyOnSenderKeyForSenderKeyGroupIdUsingSenderKeyKeyHashKeyUpdate = {
  /** An object where the defined keys will be set on the `senderKey` being updated. */
  patch: UpdateSenderKeyOnSenderKeyForSenderKeyGroupIdPatch;
  keyHash: Scalars['String'];
};

/** The fields on `senderKey` to look up the row to update. */
export type SenderKeyOnSenderKeyForSenderKeyGroupIdUsingSenderKeyPkeyUpdate = {
  /** An object where the defined keys will be set on the `senderKey` being updated. */
  patch: UpdateSenderKeyOnSenderKeyForSenderKeyGroupIdPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `SenderKey`. Fields that are set will be updated. */
export type SenderKeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  userId?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  deviceId?: Maybe<Scalars['UUID']>;
  keyHash?: Maybe<Scalars['String']>;
  groupToGroupId?: Maybe<SenderKeyGroupIdInput>;
};

/** A connection to a list of `SenderKey` values. */
export type SenderKeysConnection = {
  __typename?: 'SenderKeysConnection';
  /** A list of `SenderKey` objects. */
  nodes: Array<Maybe<SenderKey>>;
  /** A list of edges which contains the `SenderKey` and cursor to aid in pagination. */
  edges: Array<SenderKeysEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SenderKey` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SenderKey` edge in the connection. */
export type SenderKeysEdge = {
  __typename?: 'SenderKeysEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SenderKey` at the end of the edge. */
  node?: Maybe<SenderKey>;
};

/** The fields on `senderKey` to look up the row to connect. */
export type SenderKeySenderKeyKeyHashKeyConnect = {
  keyHash: Scalars['String'];
};

/** The fields on `senderKey` to look up the row to connect. */
export type SenderKeySenderKeyPkeyConnect = {
  id: Scalars['UUID'];
};

/** Methods to use when ordering `SenderKey`. */
export enum SenderKeysOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  KeyHashAsc = 'KEY_HASH_ASC',
  KeyHashDesc = 'KEY_HASH_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `sendLicensingPortalInvitation` mutation. */
export type SendLicensingPortalInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  customerEmail: Scalars['String'];
};

/** The output of our `sendLicensingPortalInvitation` mutation. */
export type SendLicensingPortalInvitationPayload = {
  __typename?: 'SendLicensingPortalInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `sendQrcode` mutation. */
export type SendQrcodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  licenseId: Scalars['String'];
  email: Scalars['String'];
};

/** The output of our `sendQrcode` mutation. */
export type SendQrcodePayload = {
  __typename?: 'SendQrcodePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `sendQrCodeSalesforce` mutation. */
export type SendQrCodeSalesforceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  licenseId: Scalars['String'];
  email: Scalars['String'];
  swOnly: Scalars['Boolean'];
};

/** The output of our `sendQrCodeSalesforce` mutation. */
export type SendQrCodeSalesforcePayload = {
  __typename?: 'SendQrCodeSalesforcePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `sendVideocallEvent` mutation. */
export type SendVideocallEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  senderId?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  eventType?: Maybe<VideocallEventType>;
};

/** The output of our `sendVideocallEvent` mutation. */
export type SendVideocallEventPayload = {
  __typename?: 'SendVideocallEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A `SeniorRecoveryKeysRecord` edge in the connection. */
export type SeniorRecoveryKeyEdge = {
  __typename?: 'SeniorRecoveryKeyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SeniorRecoveryKeysRecord` at the end of the edge. */
  node?: Maybe<SeniorRecoveryKeysRecord>;
};

/** A connection to a list of `SeniorRecoveryKeysRecord` values. */
export type SeniorRecoveryKeysConnection = {
  __typename?: 'SeniorRecoveryKeysConnection';
  /** A list of `SeniorRecoveryKeysRecord` objects. */
  nodes: Array<Maybe<SeniorRecoveryKeysRecord>>;
  /** A list of edges which contains the `SeniorRecoveryKeysRecord` and cursor to aid in pagination. */
  edges: Array<SeniorRecoveryKeyEdge>;
  /** The count of *all* `SeniorRecoveryKeysRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** The return type of our `seniorRecoveryKeys` query. */
export type SeniorRecoveryKeysRecord = {
  __typename?: 'SeniorRecoveryKeysRecord';
  seniorName?: Maybe<Scalars['String']>;
  seniorId?: Maybe<Scalars['UUID']>;
  familyId?: Maybe<Scalars['UUID']>;
  licence?: Maybe<Scalars['String']>;
  adminName?: Maybe<Scalars['String']>;
  adminId?: Maybe<Scalars['UUID']>;
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type Subscription = {
  __typename?: 'Subscription';
  messageAdded?: Maybe<MessageSubscriptionPayload>;
  messageDeleted?: Maybe<DeleteMessageSubscriptionPayload>;
  groupJoined?: Maybe<GroupSubscriptionPayload>;
  groupLastReadByAllUpdated?: Maybe<UpdateLastRemoteReadPayload>;
  invitationAddedOrUpdated?: Maybe<InvitationSubscriptionPayload>;
  newVideocallEvent?: Maybe<VideocallEventSubscriptionPayload>;
  systemMessageAdded?: Maybe<SystemMessageAddedPayload>;
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionMessageAddedArgs = {
  userId?: Maybe<Scalars['UUID']>;
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionMessageDeletedArgs = {
  userId?: Maybe<Scalars['UUID']>;
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionGroupJoinedArgs = {
  userId: Scalars['UUID'];
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionGroupLastReadByAllUpdatedArgs = {
  userId: Scalars['UUID'];
};

export enum SwMode {
  SwOnly = 'swOnly',
  SwAndHw = 'swAndHw'
}

export type SystemMessage = {
  __typename?: 'SystemMessage';
  createdAt: Scalars['Datetime'];
  hidden: Scalars['Boolean'];
  id: Scalars['UUID'];
  kind: SystemMessageType;
  payload: Scalars['JSON'];
  receiverId?: Maybe<Scalars['UUID']>;
  senderId?: Maybe<Scalars['UUID']>;
  status: SystemMessageStatus;
};

export type SystemMessageAddedPayload = {
  __typename?: 'systemMessageAddedPayload';
  systemMessage: SystemMessage;
};

/** A connection to a list of `SystemMessage` values. */
export type SystemMessagesConnection = {
  __typename?: 'SystemMessagesConnection';
  /** A list of `SystemMessage` objects. */
  nodes: Array<Maybe<SystemMessage>>;
  /** A list of edges which contains the `SystemMessage` and cursor to aid in pagination. */
  edges: Array<SystemMessagesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SystemMessage` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SystemMessage` edge in the connection. */
export type SystemMessagesEdge = {
  __typename?: 'SystemMessagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `SystemMessage` at the end of the edge. */
  node?: Maybe<SystemMessage>;
};

export enum SystemMessageStatus {
  Seen = 'SEEN',
  Unseen = 'UNSEEN'
}

export enum SystemMessageType {
  BroadcastSenderKeys = 'BROADCAST_SENDER_KEYS',
  SenderKeys = 'SENDER_KEYS',
  MissedCall = 'MISSED_CALL'
}

/** All input for the `testEnvUpdateLastRead` mutation. */
export type TestEnvUpdateLastReadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
  groupId?: Maybe<Scalars['UUID']>;
  lastRead?: Maybe<Scalars['Datetime']>;
};

/** The output of our `testEnvUpdateLastRead` mutation. */
export type TestEnvUpdateLastReadPayload = {
  __typename?: 'TestEnvUpdateLastReadPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['Datetime']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `testEnvUpdateMessageCreatedAt` mutation. */
export type TestEnvUpdateMessageCreatedAtInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
};

/** The output of our `testEnvUpdateMessageCreatedAt` mutation. */
export type TestEnvUpdateMessageCreatedAtPayload = {
  __typename?: 'TestEnvUpdateMessageCreatedAtPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  datetime?: Maybe<Scalars['Datetime']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateAttachmentByNodeId` mutation. */
export type UpdateAttachmentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Attachment` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Attachment` being updated. */
  patch: AttachmentPatch;
};

/** All input for the `updateAttachment` mutation. */
export type UpdateAttachmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Attachment` being updated. */
  patch: AttachmentPatch;
  id: Scalars['UUID'];
};

/** An object where the defined keys will be set on the `attachment` being updated. */
export type UpdateAttachmentOnAttachmentForAttachmentMessageIdPatch = {
  id?: Maybe<Scalars['UUID']>;
  kind?: Maybe<AttachmentKind>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  messageToMessageId?: Maybe<AttachmentMessageIdInput>;
};

/** The output of our update `Attachment` mutation. */
export type UpdateAttachmentPayload = {
  __typename?: 'UpdateAttachmentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Attachment` that was updated by this mutation. */
  attachment?: Maybe<Attachment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Message` that is related to this `Attachment`. */
  message?: Maybe<Message>;
  /** An edge for our `Attachment`. May be used by Relay 1. */
  attachmentEdge?: Maybe<AttachmentsEdge>;
};


/** The output of our update `Attachment` mutation. */
export type UpdateAttachmentPayloadAttachmentEdgeArgs = {
  orderBy?: Maybe<Array<AttachmentsOrderBy>>;
};

/** An object where the defined keys will be set on the `feedback` being updated. */
export type UpdateFeedbackOnFeedbackForFeedbackUserIdPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  deviceId?: Maybe<Scalars['UUID']>;
  target?: Maybe<Scalars['String']>;
  params?: Maybe<Scalars['JSON']>;
  rating?: Maybe<Scalars['Int']>;
  targetType?: Maybe<Scalars['String']>;
  userToUserId?: Maybe<FeedbackUserIdInput>;
};

/** All input for the `updateGroupByNodeId` mutation. */
export type UpdateGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Group` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Group` being updated. */
  patch: GroupPatch;
};

/** All input for the `updateGroup` mutation. */
export type UpdateGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Group` being updated. */
  patch: GroupPatch;
  id: Scalars['UUID'];
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnInvitationForInvitationInvitedToFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  kind?: Maybe<GroupType>;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipGroupIdInverseInput>;
  messages?: Maybe<MessageChatIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedToFkeyInverseInput>;
  senderKeysUsingId?: Maybe<SenderKeyGroupIdInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnMembershipForMembershipGroupIdPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  kind?: Maybe<GroupType>;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipGroupIdInverseInput>;
  messages?: Maybe<MessageChatIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedToFkeyInverseInput>;
  senderKeysUsingId?: Maybe<SenderKeyGroupIdInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnMessageForMessageChatIdPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  kind?: Maybe<GroupType>;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipGroupIdInverseInput>;
  messages?: Maybe<MessageChatIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedToFkeyInverseInput>;
  senderKeysUsingId?: Maybe<SenderKeyGroupIdInverseInput>;
};

/** An object where the defined keys will be set on the `group` being updated. */
export type UpdateGroupOnSenderKeyForSenderKeyGroupIdPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  kind?: Maybe<GroupType>;
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipGroupIdInverseInput>;
  messages?: Maybe<MessageChatIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedToFkeyInverseInput>;
  senderKeysUsingId?: Maybe<SenderKeyGroupIdInverseInput>;
};

/** The output of our update `Group` mutation. */
export type UpdateGroupPayload = {
  __typename?: 'UpdateGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Group` that was updated by this mutation. */
  group?: Maybe<Group>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge?: Maybe<GroupsEdge>;
};


/** The output of our update `Group` mutation. */
export type UpdateGroupPayloadGroupEdgeArgs = {
  orderBy?: Maybe<Array<GroupsOrderBy>>;
};

/** An object where the defined keys will be set on the `invitation` being updated. */
export type UpdateInvitationOnInvitationForInvitationInvitedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  email?: Maybe<Scalars['String']>;
  invitedTo?: Maybe<Scalars['UUID']>;
  status?: Maybe<EmailStatus>;
  errorMsg?: Maybe<Scalars['String']>;
  userToInvitedBy?: Maybe<InvitationInvitedByFkeyInput>;
  groupToInvitedTo?: Maybe<InvitationInvitedToFkeyInput>;
};

/** An object where the defined keys will be set on the `invitation` being updated. */
export type UpdateInvitationOnInvitationForInvitationInvitedToFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  email?: Maybe<Scalars['String']>;
  invitedBy?: Maybe<Scalars['UUID']>;
  status?: Maybe<EmailStatus>;
  errorMsg?: Maybe<Scalars['String']>;
  userToInvitedBy?: Maybe<InvitationInvitedByFkeyInput>;
  groupToInvitedTo?: Maybe<InvitationInvitedToFkeyInput>;
};

export type UpdateLastRemoteReadPayload = {
  __typename?: 'UpdateLastRemoteReadPayload';
  lastReadByAll: Scalars['Datetime'];
  lastReceivedByAll: Scalars['Datetime'];
  chatId: Scalars['UUID'];
};

/** All input for the `updateMembershipByNodeId` mutation. */
export type UpdateMembershipByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Membership` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Membership` being updated. */
  patch: MembershipPatch;
};

/** All input for the `updateMembership` mutation. */
export type UpdateMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Membership` being updated. */
  patch: MembershipPatch;
  groupId: Scalars['UUID'];
  memberId: Scalars['UUID'];
};

/** An object where the defined keys will be set on the `membership` being updated. */
export type UpdateMembershipOnMembershipForMembershipGroupIdPatch = {
  memberId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  lastRead?: Maybe<Scalars['Datetime']>;
  lastReceived?: Maybe<Scalars['Datetime']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  groupToGroupId?: Maybe<MembershipGroupIdInput>;
  userToMemberId?: Maybe<MembershipMemberIdInput>;
};

/** An object where the defined keys will be set on the `membership` being updated. */
export type UpdateMembershipOnMembershipForMembershipMemberIdPatch = {
  groupId?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  lastRead?: Maybe<Scalars['Datetime']>;
  lastReceived?: Maybe<Scalars['Datetime']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  groupToGroupId?: Maybe<MembershipGroupIdInput>;
  userToMemberId?: Maybe<MembershipMemberIdInput>;
};

/** The output of our update `Membership` mutation. */
export type UpdateMembershipPayload = {
  __typename?: 'UpdateMembershipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Membership` that was updated by this mutation. */
  membership?: Maybe<Membership>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `Membership`. */
  group?: Maybe<Group>;
  /** Reads a single `User` that is related to this `Membership`. */
  member?: Maybe<User>;
  /** An edge for our `Membership`. May be used by Relay 1. */
  membershipEdge?: Maybe<MembershipsEdge>;
};


/** The output of our update `Membership` mutation. */
export type UpdateMembershipPayloadMembershipEdgeArgs = {
  orderBy?: Maybe<Array<MembershipsOrderBy>>;
};

/** All input for the `updateMessageByNodeId` mutation. */
export type UpdateMessageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Message` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Message` being updated. */
  patch: MessagePatch;
};

/** All input for the `updateMessage` mutation. */
export type UpdateMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Message` being updated. */
  patch: MessagePatch;
  id: Scalars['UUID'];
};

/** An object where the defined keys will be set on the `message` being updated. */
export type UpdateMessageOnAttachmentForAttachmentMessageIdPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  authorId?: Maybe<Scalars['UUID']>;
  chatId?: Maybe<Scalars['UUID']>;
  content?: Maybe<Scalars['String']>;
  senderKeyId?: Maybe<Scalars['UUID']>;
  userToAuthorId?: Maybe<MessageAuthorIdInput>;
  groupToChatId?: Maybe<MessageChatIdInput>;
  attachmentsUsingId?: Maybe<AttachmentMessageIdInverseInput>;
};

/** An object where the defined keys will be set on the `message` being updated. */
export type UpdateMessageOnMessageForMessageAuthorIdPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  chatId?: Maybe<Scalars['UUID']>;
  content?: Maybe<Scalars['String']>;
  senderKeyId?: Maybe<Scalars['UUID']>;
  userToAuthorId?: Maybe<MessageAuthorIdInput>;
  groupToChatId?: Maybe<MessageChatIdInput>;
  attachmentsUsingId?: Maybe<AttachmentMessageIdInverseInput>;
};

/** An object where the defined keys will be set on the `message` being updated. */
export type UpdateMessageOnMessageForMessageChatIdPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  authorId?: Maybe<Scalars['UUID']>;
  content?: Maybe<Scalars['String']>;
  senderKeyId?: Maybe<Scalars['UUID']>;
  userToAuthorId?: Maybe<MessageAuthorIdInput>;
  groupToChatId?: Maybe<MessageChatIdInput>;
  attachmentsUsingId?: Maybe<AttachmentMessageIdInverseInput>;
};

/** The output of our update `Message` mutation. */
export type UpdateMessagePayload = {
  __typename?: 'UpdateMessagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Message` that was updated by this mutation. */
  message?: Maybe<Message>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Message`. */
  author?: Maybe<User>;
  /** Reads a single `Group` that is related to this `Message`. */
  chat?: Maybe<Group>;
  /** An edge for our `Message`. May be used by Relay 1. */
  messageEdge?: Maybe<MessagesEdge>;
};


/** The output of our update `Message` mutation. */
export type UpdateMessagePayloadMessageEdgeArgs = {
  orderBy?: Maybe<Array<MessagesOrderBy>>;
};

/** An object where the defined keys will be set on the `senderKey` being updated. */
export type UpdateSenderKeyOnSenderKeyForSenderKeyGroupIdPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  userId?: Maybe<Scalars['UUID']>;
  deviceId?: Maybe<Scalars['UUID']>;
  keyHash?: Maybe<Scalars['String']>;
  groupToGroupId?: Maybe<SenderKeyGroupIdInput>;
};

/** All input for the `updateUserByNodeId` mutation. */
export type UpdateUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUserCredentials` mutation. */
export type UpdateUserCredentialsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

/** The output of our `updateUserCredentials` mutation. */
export type UpdateUserCredentialsPayload = {
  __typename?: 'UpdateUserCredentialsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  credential?: Maybe<Credential>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
  id: Scalars['UUID'];
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnFeedbackForFeedbackUserIdPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<UserType>;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipMemberIdInverseInput>;
  messagesAuthored?: Maybe<MessageAuthorIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedByFkeyInverseInput>;
  feedbacksUsingId?: Maybe<FeedbackUserIdInverseInput>;
  websitesUsingId?: Maybe<OwnerUserIdInverseInput>;
  userSettingUsingId?: Maybe<UserIdInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnInvitationForInvitationInvitedByFkeyPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<UserType>;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipMemberIdInverseInput>;
  messagesAuthored?: Maybe<MessageAuthorIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedByFkeyInverseInput>;
  feedbacksUsingId?: Maybe<FeedbackUserIdInverseInput>;
  websitesUsingId?: Maybe<OwnerUserIdInverseInput>;
  userSettingUsingId?: Maybe<UserIdInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnMembershipForMembershipMemberIdPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<UserType>;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipMemberIdInverseInput>;
  messagesAuthored?: Maybe<MessageAuthorIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedByFkeyInverseInput>;
  feedbacksUsingId?: Maybe<FeedbackUserIdInverseInput>;
  websitesUsingId?: Maybe<OwnerUserIdInverseInput>;
  userSettingUsingId?: Maybe<UserIdInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnMessageForMessageAuthorIdPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<UserType>;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipMemberIdInverseInput>;
  messagesAuthored?: Maybe<MessageAuthorIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedByFkeyInverseInput>;
  feedbacksUsingId?: Maybe<FeedbackUserIdInverseInput>;
  websitesUsingId?: Maybe<OwnerUserIdInverseInput>;
  userSettingUsingId?: Maybe<UserIdInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnUserSettingForUserIdPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<UserType>;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipMemberIdInverseInput>;
  messagesAuthored?: Maybe<MessageAuthorIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedByFkeyInverseInput>;
  feedbacksUsingId?: Maybe<FeedbackUserIdInverseInput>;
  websitesUsingId?: Maybe<OwnerUserIdInverseInput>;
  userSettingUsingId?: Maybe<UserIdInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnWebsiteForOwnerUserIdPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<UserType>;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipMemberIdInverseInput>;
  messagesAuthored?: Maybe<MessageAuthorIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedByFkeyInverseInput>;
  feedbacksUsingId?: Maybe<FeedbackUserIdInverseInput>;
  websitesUsingId?: Maybe<OwnerUserIdInverseInput>;
  userSettingUsingId?: Maybe<UserIdInverseInput>;
};

/** All input for the `updateUserPassword` mutation. */
export type UpdateUserPasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
  password?: Maybe<Scalars['String']>;
};

/** The output of our `updateUserPassword` mutation. */
export type UpdateUserPasswordPayload = {
  __typename?: 'UpdateUserPasswordPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  credential?: Maybe<Credential>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `User` mutation. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `User` that was updated by this mutation. */
  user?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our update `User` mutation. */
export type UpdateUserPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

/** All input for the `updateUserSettingByNodeId` mutation. */
export type UpdateUserSettingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserSetting` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UserSetting` being updated. */
  patch: UserSettingPatch;
};

/** All input for the `updateUserSetting` mutation. */
export type UpdateUserSettingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `UserSetting` being updated. */
  patch: UserSettingPatch;
  userId: Scalars['UUID'];
};

/** An object where the defined keys will be set on the `userSetting` being updated. */
export type UpdateUserSettingOnUserSettingForUserIdPatch = {
  encryptionMode?: Maybe<EncryptionMode>;
  userToUserId?: Maybe<UserIdInput>;
};

/** The output of our update `UserSetting` mutation. */
export type UpdateUserSettingPayload = {
  __typename?: 'UpdateUserSettingPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserSetting` that was updated by this mutation. */
  userSetting?: Maybe<UserSetting>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserSetting`. */
  user?: Maybe<User>;
  /** An edge for our `UserSetting`. May be used by Relay 1. */
  userSettingEdge?: Maybe<UserSettingsEdge>;
};


/** The output of our update `UserSetting` mutation. */
export type UpdateUserSettingPayloadUserSettingEdgeArgs = {
  orderBy?: Maybe<Array<UserSettingsOrderBy>>;
};

/** All input for the `updateWebsiteByNodeId` mutation. */
export type UpdateWebsiteByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Website` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Website` being updated. */
  patch: WebsitePatch;
};

/** All input for the `updateWebsite` mutation. */
export type UpdateWebsiteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Website` being updated. */
  patch: WebsitePatch;
  id: Scalars['Int'];
};

/** An object where the defined keys will be set on the `website` being updated. */
export type UpdateWebsiteOnWebsiteForOwnerUserIdPatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  userToUserId?: Maybe<OwnerUserIdInput>;
};

/** The output of our update `Website` mutation. */
export type UpdateWebsitePayload = {
  __typename?: 'UpdateWebsitePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Website` that was updated by this mutation. */
  website?: Maybe<Website>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Website`. */
  user?: Maybe<User>;
  /** An edge for our `Website`. May be used by Relay 1. */
  websiteEdge?: Maybe<WebsitesEdge>;
};


/** The output of our update `Website` mutation. */
export type UpdateWebsitePayloadWebsiteEdgeArgs = {
  orderBy?: Maybe<Array<WebsitesOrderBy>>;
};

export type UploadLink = {
  __typename?: 'UploadLink';
  key: Scalars['String'];
  url: Scalars['String'];
};

export type User = Node & {
  __typename?: 'User';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  name: Scalars['String'];
  kind: UserType;
  avatar?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Membership`. */
  memberships: MembershipsConnection;
  /** Reads and enables pagination through a set of `Membership`. */
  membershipsList: Array<Membership>;
  /** Reads and enables pagination through a set of `Message`. */
  messagesAuthored: MessagesConnection;
  /** Reads and enables pagination through a set of `Message`. */
  messagesAuthoredList: Array<Message>;
  /** Reads and enables pagination through a set of `Feedback`. */
  feedbacks: FeedbacksConnection;
  /** Reads and enables pagination through a set of `Feedback`. */
  feedbacksList: Array<Feedback>;
  /** Reads and enables pagination through a set of `Website`. */
  websites: WebsitesConnection;
  /** Reads and enables pagination through a set of `Website`. */
  websitesList: Array<Website>;
  /** Reads a single `UserSetting` that is related to this `User`. */
  userSettingByUserId?: Maybe<UserSetting>;
  /**
   * Reads and enables pagination through a set of `UserSetting`.
   * @deprecated Please use userSettingByUserId instead
   */
  userSettings: UserSettingsConnection;
  /** Returns the current user's licence number */
  licence?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Group`. */
  groups: UserGroupsManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsList: Array<Group>;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByInvitationInvitedByAndInvitedTo: UserGroupsByInvitationInvitedByAndInvitedToManyToManyConnection;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByInvitationInvitedByAndInvitedToList: Array<Group>;
  avatarUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};


export type UserMembershipsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MembershipsOrderBy>>;
  condition?: Maybe<MembershipCondition>;
};


export type UserMembershipsListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MembershipsOrderBy>>;
  condition?: Maybe<MembershipCondition>;
};


export type UserMessagesAuthoredArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MessagesOrderBy>>;
  condition?: Maybe<MessageCondition>;
};


export type UserMessagesAuthoredListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<MessagesOrderBy>>;
  condition?: Maybe<MessageCondition>;
};


export type UserFeedbacksArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<FeedbacksOrderBy>>;
  condition?: Maybe<FeedbackCondition>;
};


export type UserFeedbacksListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<FeedbacksOrderBy>>;
  condition?: Maybe<FeedbackCondition>;
};


export type UserWebsitesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<WebsitesOrderBy>>;
  condition?: Maybe<WebsiteCondition>;
};


export type UserWebsitesListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<WebsitesOrderBy>>;
  condition?: Maybe<WebsiteCondition>;
};


export type UserUserSettingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserSettingsOrderBy>>;
  condition?: Maybe<UserSettingCondition>;
};


export type UserGroupsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
};


export type UserGroupsListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
};


export type UserGroupsByInvitationInvitedByAndInvitedToArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
};


export type UserGroupsByInvitationInvitedByAndInvitedToListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<GroupsOrderBy>>;
  condition?: Maybe<GroupCondition>;
};

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `Group` values, with data from `Invitation`. */
export type UserGroupsByInvitationInvitedByAndInvitedToManyToManyConnection = {
  __typename?: 'UserGroupsByInvitationInvitedByAndInvitedToManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Maybe<Group>>;
  /** A list of edges which contains the `Group`, info from the `Invitation`, and the cursor to aid in pagination. */
  edges: Array<UserGroupsByInvitationInvitedByAndInvitedToManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `Invitation`. */
export type UserGroupsByInvitationInvitedByAndInvitedToManyToManyEdge = {
  __typename?: 'UserGroupsByInvitationInvitedByAndInvitedToManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node?: Maybe<Group>;
  /** Reads and enables pagination through a set of `Invitation`. */
  invitationsByInvitedTo: InvitationsConnection;
  /** Reads and enables pagination through a set of `Invitation`. */
  invitationsByInvitedToList: Array<Invitation>;
};


/** A `Group` edge in the connection, with data from `Invitation`. */
export type UserGroupsByInvitationInvitedByAndInvitedToManyToManyEdgeInvitationsByInvitedToArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvitationsOrderBy>>;
  condition?: Maybe<InvitationCondition>;
};


/** A `Group` edge in the connection, with data from `Invitation`. */
export type UserGroupsByInvitationInvitedByAndInvitedToManyToManyEdgeInvitationsByInvitedToListArgs = {
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Array<InvitationsOrderBy>>;
  condition?: Maybe<InvitationCondition>;
};

/** A connection to a list of `Group` values, with data from `Membership`. */
export type UserGroupsManyToManyConnection = {
  __typename?: 'UserGroupsManyToManyConnection';
  /** A list of `Group` objects. */
  nodes: Array<Maybe<Group>>;
  /** A list of edges which contains the `Group`, info from the `Membership`, and the cursor to aid in pagination. */
  edges: Array<UserGroupsManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Group` edge in the connection, with data from `Membership`. */
export type UserGroupsManyToManyEdge = {
  __typename?: 'UserGroupsManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node?: Maybe<Group>;
  createdAt: Scalars['Datetime'];
  lastRead: Scalars['Datetime'];
  lastReceived?: Maybe<Scalars['Datetime']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
};

/** Input for the nested mutation of `user` in the `UserSettingInput` mutation. */
export type UserIdInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: Maybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: Maybe<UserNodeIdConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteById?: Maybe<UserUserPkeyDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByNodeId?: Maybe<UserNodeIdDelete>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: Maybe<UserOnUserSettingForUserIdUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: Maybe<UserSettingOnUserSettingForUserIdNodeIdUpdate>;
  /** A `UserInput` object that will be created and connected to this object. */
  create?: Maybe<UserIdUserCreateInput>;
};

/** Input for the nested mutation of `userSetting` in the `UserInput` mutation. */
export type UserIdInverseInput = {
  /** Flag indicating whether all other `userSetting` records that match this relationship should be removed. */
  deleteOthers?: Maybe<Scalars['Boolean']>;
  /** The primary key(s) for `userSetting` for the far side of the relationship. */
  connectByUserId?: Maybe<UserSettingUserSettingsPkeyConnect>;
  /** The primary key(s) for `userSetting` for the far side of the relationship. */
  connectByNodeId?: Maybe<UserSettingNodeIdConnect>;
  /** The primary key(s) for `userSetting` for the far side of the relationship. */
  deleteByUserId?: Maybe<UserSettingUserSettingsPkeyDelete>;
  /** The primary key(s) for `userSetting` for the far side of the relationship. */
  deleteByNodeId?: Maybe<UserSettingNodeIdDelete>;
  /** The primary key(s) and patch data for `userSetting` for the far side of the relationship. */
  updateByUserId?: Maybe<UserSettingOnUserSettingForUserIdUsingUserSettingsPkeyUpdate>;
  /** The primary key(s) and patch data for `userSetting` for the far side of the relationship. */
  updateByNodeId?: Maybe<UserOnUserSettingForUserIdNodeIdUpdate>;
  /** A `UserSettingInput` object that will be created and connected to this object. */
  create?: Maybe<Array<UserIdUserSettingsCreateInput>>;
};

/** The `user` to be created by this mutation. */
export type UserIdUserCreateInput = {
  name: Scalars['String'];
  kind: UserType;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipMemberIdInverseInput>;
  messagesAuthored?: Maybe<MessageAuthorIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedByFkeyInverseInput>;
  feedbacksUsingId?: Maybe<FeedbackUserIdInverseInput>;
  websitesUsingId?: Maybe<OwnerUserIdInverseInput>;
  userSettingUsingId?: Maybe<UserIdInverseInput>;
};

/** The `userSetting` to be created by this mutation. */
export type UserIdUserSettingsCreateInput = {
  encryptionMode?: Maybe<EncryptionMode>;
  userToUserId?: Maybe<UserIdInput>;
};

/** An input for mutations affecting `User` */
export type UserInput = {
  name: Scalars['String'];
  kind: UserType;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipMemberIdInverseInput>;
  messagesAuthored?: Maybe<MessageAuthorIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedByFkeyInverseInput>;
  feedbacksUsingId?: Maybe<FeedbackUserIdInverseInput>;
  websitesUsingId?: Maybe<OwnerUserIdInverseInput>;
  userSettingUsingId?: Maybe<UserIdInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type UserNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type UserNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `user` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnFeedbackForFeedbackUserIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `feedback` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `feedback` being updated. */
  patch: FeedbackPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnFeedbackForFeedbackUserIdUsingUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnFeedbackForFeedbackUserIdPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnInvitationForInvitationInvitedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `invitation` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `invitation` being updated. */
  patch: InvitationPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnInvitationForInvitationInvitedByFkeyUsingUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnInvitationForInvitationInvitedByFkeyPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnMembershipForMembershipMemberIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `membership` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `membership` being updated. */
  patch: MembershipPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnMembershipForMembershipMemberIdUsingUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnMembershipForMembershipMemberIdPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnMessageForMessageAuthorIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `message` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `message` being updated. */
  patch: MessagePatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnMessageForMessageAuthorIdUsingUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnMessageForMessageAuthorIdPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnUserSettingForUserIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `userSetting` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `userSetting` being updated. */
  patch: UserSettingPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnUserSettingForUserIdUsingUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnUserSettingForUserIdPatch;
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnWebsiteForOwnerUserIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `website` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `website` being updated. */
  patch: WebsitePatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnWebsiteForOwnerUserIdUsingUserPkeyUpdate = {
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnWebsiteForOwnerUserIdPatch;
  id: Scalars['UUID'];
};

/** Represents an update to a `User`. Fields that are set will be updated. */
export type UserPatch = {
  id?: Maybe<Scalars['UUID']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<UserType>;
  avatar?: Maybe<Scalars['String']>;
  memberships?: Maybe<MembershipMemberIdInverseInput>;
  messagesAuthored?: Maybe<MessageAuthorIdInverseInput>;
  invitationsUsingId?: Maybe<InvitationInvitedByFkeyInverseInput>;
  feedbacksUsingId?: Maybe<FeedbackUserIdInverseInput>;
  websitesUsingId?: Maybe<OwnerUserIdInverseInput>;
  userSettingUsingId?: Maybe<UserIdInverseInput>;
};

export type UserRelation = {
  __typename?: 'UserRelation';
  contact1Id?: Maybe<Scalars['UUID']>;
  contact1Kind?: Maybe<UserType>;
  contact2Id?: Maybe<Scalars['UUID']>;
  contact2Kind?: Maybe<UserType>;
  relatedSeniorId?: Maybe<Scalars['UUID']>;
  privateChatId?: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `UserRelation` values. */
export type UserRelationsConnection = {
  __typename?: 'UserRelationsConnection';
  /** A list of `UserRelation` objects. */
  nodes: Array<Maybe<UserRelation>>;
  /** A list of edges which contains the `UserRelation` and cursor to aid in pagination. */
  edges: Array<UserRelationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserRelation` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserRelation` edge in the connection. */
export type UserRelationsEdge = {
  __typename?: 'UserRelationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserRelation` at the end of the edge. */
  node?: Maybe<UserRelation>;
};

/** Methods to use when ordering `UserRelation`. */
export enum UserRelationsOrderBy {
  Natural = 'NATURAL'
}

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

export type UserSetting = Node & {
  __typename?: 'UserSetting';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  userId: Scalars['UUID'];
  encryptionMode?: Maybe<EncryptionMode>;
  /** Reads a single `User` that is related to this `UserSetting`. */
  user?: Maybe<User>;
};

/**
 * A condition to be used against `UserSetting` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UserSettingCondition = {
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
};

/** An input for mutations affecting `UserSetting` */
export type UserSettingInput = {
  userId?: Maybe<Scalars['UUID']>;
  encryptionMode?: Maybe<EncryptionMode>;
  userToUserId?: Maybe<UserIdInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type UserSettingNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `userSetting` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type UserSettingNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `userSetting` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserSettingOnUserSettingForUserIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `userSetting` to look up the row to update. */
export type UserSettingOnUserSettingForUserIdUsingUserSettingsPkeyUpdate = {
  /** An object where the defined keys will be set on the `userSetting` being updated. */
  patch: UpdateUserSettingOnUserSettingForUserIdPatch;
  userId: Scalars['UUID'];
};

/** Represents an update to a `UserSetting`. Fields that are set will be updated. */
export type UserSettingPatch = {
  userId?: Maybe<Scalars['UUID']>;
  encryptionMode?: Maybe<EncryptionMode>;
  userToUserId?: Maybe<UserIdInput>;
};

/** A connection to a list of `UserSetting` values. */
export type UserSettingsConnection = {
  __typename?: 'UserSettingsConnection';
  /** A list of `UserSetting` objects. */
  nodes: Array<Maybe<UserSetting>>;
  /** A list of edges which contains the `UserSetting` and cursor to aid in pagination. */
  edges: Array<UserSettingsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserSetting` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserSetting` edge in the connection. */
export type UserSettingsEdge = {
  __typename?: 'UserSettingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserSetting` at the end of the edge. */
  node?: Maybe<UserSetting>;
};

/** Methods to use when ordering `UserSetting`. */
export enum UserSettingsOrderBy {
  Natural = 'NATURAL',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The fields on `userSetting` to look up the row to connect. */
export type UserSettingUserSettingsPkeyConnect = {
  userId: Scalars['UUID'];
};

/** The fields on `userSetting` to look up the row to delete. */
export type UserSettingUserSettingsPkeyDelete = {
  userId: Scalars['UUID'];
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export enum UserType {
  Senior = 'SENIOR',
  Relative = 'RELATIVE',
  Manager = 'MANAGER',
  Redurisk = 'REDURISK',
  Caregiver = 'CAREGIVER',
  Customer = 'CUSTOMER'
}

/** The fields on `user` to look up the row to connect. */
export type UserUserPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `user` to look up the row to delete. */
export type UserUserPkeyDelete = {
  id: Scalars['UUID'];
};


export type VideocallEvent = {
  __typename?: 'VideocallEvent';
  senderId: Scalars['UUID'];
  groupId: Scalars['UUID'];
  event: VideocallEventType;
};

export type VideocallEventSubscriptionPayload = {
  __typename?: 'VideocallEventSubscriptionPayload';
  videocallEvent: VideocallEvent;
};

export enum VideocallEventType {
  Call = 'CALL',
  Ringing = 'RINGING',
  Accept = 'ACCEPT',
  Reject = 'REJECT',
  Busy = 'BUSY'
}

export type Website = Node & {
  __typename?: 'Website';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  userId: Scalars['UUID'];
  url: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Website`. */
  user?: Maybe<User>;
};

/** A condition to be used against `Website` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type WebsiteCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['UUID']>;
};

/** An input for mutations affecting `Website` */
export type WebsiteInput = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  userId?: Maybe<Scalars['UUID']>;
  url: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  userToUserId?: Maybe<OwnerUserIdInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type WebsiteNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `website` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type WebsiteNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `website` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type WebsiteOnWebsiteForOwnerUserIdNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `website` to look up the row to update. */
export type WebsiteOnWebsiteForOwnerUserIdUsingWebsitePkeyUpdate = {
  /** An object where the defined keys will be set on the `website` being updated. */
  patch: UpdateWebsiteOnWebsiteForOwnerUserIdPatch;
  id: Scalars['Int'];
};

/** Represents an update to a `Website`. Fields that are set will be updated. */
export type WebsitePatch = {
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  userId?: Maybe<Scalars['UUID']>;
  url?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
  userToUserId?: Maybe<OwnerUserIdInput>;
};

/** A connection to a list of `Website` values. */
export type WebsitesConnection = {
  __typename?: 'WebsitesConnection';
  /** A list of `Website` objects. */
  nodes: Array<Maybe<Website>>;
  /** A list of edges which contains the `Website` and cursor to aid in pagination. */
  edges: Array<WebsitesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Website` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Website` edge in the connection. */
export type WebsitesEdge = {
  __typename?: 'WebsitesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Website` at the end of the edge. */
  node?: Maybe<Website>;
};

/** Methods to use when ordering `Website`. */
export enum WebsitesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The fields on `website` to look up the row to connect. */
export type WebsiteWebsitePkeyConnect = {
  id: Scalars['Int'];
};

/** The fields on `website` to look up the row to delete. */
export type WebsiteWebsitePkeyDelete = {
  id: Scalars['Int'];
};

export type ContactFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'avatar' | 'avatarUrl' | 'kind'>
);

export type FullMessageFragment = (
  { __typename?: 'Message' }
  & Pick<Message, 'id' | 'content' | 'senderKeyId' | 'chatId' | 'createdAt'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'kind'>
    & ContactFragment
  )>, attachmentsList: Array<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'id' | 'status' | 'nodeId' | 'messageId' | 'createdAt' | 'kind' | 'url' | 'width' | 'height' | 'duration'>
  )> }
);

export type MessageListFragment = (
  { __typename?: 'Group' }
  & Pick<Group, 'id'>
  & { messagesList: Array<(
    { __typename?: 'Message' }
    & FullMessageFragment
  )> }
);

export type SenderKeyItemFragment = (
  { __typename?: 'SenderKey' }
  & Pick<SenderKey, 'id' | 'groupId' | 'deviceId' | 'keyHash' | 'userId' | 'createdAt'>
);

export type UserGroupFragment = (
  { __typename?: 'Group' }
  & Pick<Group, 'id' | 'name' | 'avatar' | 'avatarUrl' | 'kind' | 'lastReadByAll' | 'lastReceivedByAll' | 'relatedSeniorEncryptionMode' | 'supportedFeatures'>
  & { membersList: Array<(
    { __typename?: 'User' }
    & ContactFragment
  )>, membershipsList: Array<(
    { __typename?: 'Membership' }
    & Pick<Membership, 'isAdmin'>
    & { member?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )>, memberDevices?: Maybe<Array<Maybe<(
    { __typename?: 'MemberDevice' }
    & Pick<MemberDevice, 'memberId' | 'deviceId' | 'publicKey'>
  )>>>, senderKeysList: Array<(
    { __typename?: 'SenderKey' }
    & SenderKeyItemFragment
  )> }
);

export type ChatFragment = (
  { __typename?: 'Group' }
  & Pick<Group, 'lastReadTimestampForCurrentUser'>
  & UserGroupFragment
);

export type ChatListItemFragment = (
  { __typename?: 'Group' }
  & Pick<Group, 'unreadCountForCurrentUser' | 'lastReceivedByAll'>
  & { lastMessageForCurrentUser?: Maybe<(
    { __typename?: 'Message' }
    & FullMessageFragment
  )> }
  & UserGroupFragment
);

export type InvitationInfoFragment = (
  { __typename?: 'Invitation' }
  & Pick<Invitation, 'id' | 'email' | 'status' | 'updatedAt' | 'createdAt' | 'invitedTo'>
  & { userByInvitedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )> }
);

export type FeedbackItemFragment = (
  { __typename?: 'Feedback' }
  & Pick<Feedback, 'id' | 'target' | 'targetType'>
);

export type SystemMessageItemFragment = (
  { __typename?: 'SystemMessage' }
  & Pick<SystemMessage, 'createdAt' | 'hidden' | 'id' | 'kind' | 'payload' | 'receiverId' | 'senderId' | 'status'>
);

export type LicenseItemFragment = (
  { __typename?: 'License' }
  & Pick<License, 'id' | 'name' | 'active' | 'startDate' | 'endDate' | 'plan' | 'appUrl' | 'tabletName' | 'activatedAt'>
);

export type CreateOrRetrievePrivateChatMutationVariables = Exact<{
  memberId1: Scalars['UUID'];
  memberId2: Scalars['UUID'];
}>;


export type CreateOrRetrievePrivateChatMutation = (
  { __typename?: 'Mutation' }
  & { createOrRetrievePrivateChat?: Maybe<(
    { __typename?: 'CreateOrRetrievePrivateChatPayload' }
    & { group?: Maybe<(
      { __typename?: 'Group' }
      & ChatListItemFragment
    )> }
  )> }
);

export type CreateMessageMutationVariables = Exact<{
  input: CreateMessageInput;
}>;


export type CreateMessageMutation = (
  { __typename?: 'Mutation' }
  & { createMessage?: Maybe<(
    { __typename?: 'CreateMessagePayload' }
    & { message?: Maybe<(
      { __typename?: 'Message' }
      & FullMessageFragment
    )> }
  )> }
);

export type DeleteMessageMutationVariables = Exact<{
  input: DeleteMessageInput;
}>;


export type DeleteMessageMutation = (
  { __typename?: 'Mutation' }
  & { deleteMessage?: Maybe<(
    { __typename?: 'DeleteMessagePayload' }
    & { message?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'id'>
    )> }
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'DeleteUserPayload' }
    & Pick<DeleteUserPayload, 'clientMutationId'>
  )> }
);

export type MarkAsReadMutationVariables = Exact<{
  userId: Scalars['UUID'];
  messageId: Scalars['UUID'];
}>;


export type MarkAsReadMutation = (
  { __typename?: 'Mutation' }
  & { markAsRead?: Maybe<(
    { __typename?: 'MarkAsReadPayload' }
    & Pick<MarkAsReadPayload, 'datetime'>
  )> }
);

export type MarkAsReceivedMutationVariables = Exact<{
  userId: Scalars['UUID'];
  messageId: Scalars['UUID'];
}>;


export type MarkAsReceivedMutation = (
  { __typename?: 'Mutation' }
  & { markAsReceived?: Maybe<(
    { __typename?: 'MarkAsReceivedPayload' }
    & Pick<MarkAsReceivedPayload, 'datetime'>
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'LoginPayload' }
    & Pick<LoginPayload, 'jwtToken'>
  )> }
);

export type InviteMutationVariables = Exact<{
  input: InviteInput;
}>;


export type InviteMutation = (
  { __typename?: 'Mutation' }
  & { invite?: Maybe<(
    { __typename?: 'InvitePayload' }
    & Pick<InvitePayload, 'clientMutationId'>
  )> }
);

export type RegisterUserMutationVariables = Exact<{
  input: RegisterUserInput;
}>;


export type RegisterUserMutation = (
  { __typename?: 'Mutation' }
  & { registerUser?: Maybe<(
    { __typename?: 'RegisterUserPayload' }
    & Pick<RegisterUserPayload, 'jwtToken'>
  )> }
);

export type AcceptInvitationToGroupMutationVariables = Exact<{
  input: AcceptInvitationToGroupInput;
}>;


export type AcceptInvitationToGroupMutation = (
  { __typename?: 'Mutation' }
  & { acceptInvitationToGroup?: Maybe<(
    { __typename?: 'AcceptInvitationToGroupPayload' }
    & Pick<AcceptInvitationToGroupPayload, 'clientMutationId'>
  )> }
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & { forgotPassword?: Maybe<(
    { __typename?: 'ForgotPasswordPayload' }
    & Pick<ForgotPasswordPayload, 'clientMutationId'>
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename?: 'ResetPasswordPayload' }
    & Pick<ResetPasswordPayload, 'jwtToken'>
  )> }
);

export type CreateSeniorMutationVariables = Exact<{
  name: Scalars['String'];
  flavour: Scalars['String'];
}>;


export type CreateSeniorMutation = (
  { __typename?: 'Mutation' }
  & { createSenior?: Maybe<(
    { __typename?: 'CreateSeniorPayload' }
    & Pick<CreateSeniorPayload, 'jwtToken'>
  )> }
);

export type CreateSeniorProfileMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateSeniorProfileMutation = (
  { __typename?: 'Mutation' }
  & { createSeniorProfile: (
    { __typename?: 'NewProfile' }
    & Pick<NewProfile, 'id'>
  ) }
);

export type RefreshAccessTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshAccessTokenMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'refreshAccessToken'>
);

export type RegisterPushNotificationDeviceMutationVariables = Exact<{
  deviceId: Scalars['UUID'];
  token: Scalars['String'];
  deviceModel: Scalars['String'];
}>;


export type RegisterPushNotificationDeviceMutation = (
  { __typename?: 'Mutation' }
  & { registerPushNotificationDevice?: Maybe<(
    { __typename?: 'RegisterPushNotificationDevicePayload' }
    & Pick<RegisterPushNotificationDevicePayload, 'clientMutationId'>
  )> }
);

export type DeregisterPushNotificationDeviceMutationVariables = Exact<{
  deviceId: Scalars['UUID'];
}>;


export type DeregisterPushNotificationDeviceMutation = (
  { __typename?: 'Mutation' }
  & { deregisterPushNotificationDevice?: Maybe<(
    { __typename?: 'DeregisterPushNotificationDevicePayload' }
    & Pick<DeregisterPushNotificationDevicePayload, 'clientMutationId'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'avatar' | 'avatarUrl'>
    )> }
  )> }
);

export type UpgradeUserMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
  kind?: Maybe<UserType>;
}>;


export type UpgradeUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'kind' | 'avatar' | 'avatarUrl'>
    )> }
  )> }
);

export type UpdateGroupMutationVariables = Exact<{
  id: Scalars['UUID'];
  name: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
}>;


export type UpdateGroupMutation = (
  { __typename?: 'Mutation' }
  & { updateGroup?: Maybe<(
    { __typename?: 'UpdateGroupPayload' }
    & { group?: Maybe<(
      { __typename?: 'Group' }
      & Pick<Group, 'id' | 'name' | 'avatar' | 'avatarUrl'>
    )> }
  )> }
);

export type AddUserDeviceMutationVariables = Exact<{
  userId: Scalars['UUID'];
  platform: DevicePlatform;
  deviceId: Scalars['UUID'];
  osVersion: Scalars['String'];
  appVersion: Scalars['String'];
  appFlavour: Scalars['String'];
  videocallVersion: Scalars['Int'];
  deviceModel: Scalars['String'];
  deviceSerialNo?: Maybe<Scalars['String']>;
  deviceImei?: Maybe<Scalars['String']>;
  deviceIccid?: Maybe<Scalars['String']>;
  chromeVersion?: Maybe<Scalars['String']>;
  appPackageId?: Maybe<Scalars['String']>;
  appPackageName?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  contentVersion?: Maybe<Scalars['String']>;
  appSigning?: Maybe<Scalars['String']>;
  appSwMode?: Maybe<Scalars['String']>;
  supportsEncryption?: Maybe<Scalars['Boolean']>;
}>;


export type AddUserDeviceMutation = (
  { __typename?: 'Mutation' }
  & { addUserDevice?: Maybe<(
    { __typename?: 'AddUserDevicePayload' }
    & Pick<AddUserDevicePayload, 'clientMutationId'>
  )> }
);

export type RegisterCatapushDeviceMutationVariables = Exact<{
  deviceId: Scalars['String'];
  deviceModel: Scalars['String'];
}>;


export type RegisterCatapushDeviceMutation = (
  { __typename?: 'Mutation' }
  & { registerCatapushDevice: (
    { __typename?: 'CatapushPassword' }
    & Pick<CatapushPassword, 'password'>
  ) }
);

export type DeRegisterCatapushDeviceMutationVariables = Exact<{
  deviceId: Scalars['String'];
}>;


export type DeRegisterCatapushDeviceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deRegisterCatapushDevice'>
);

export type SendVideoCallEventMutationVariables = Exact<{
  groupId: Scalars['UUID'];
  senderId: Scalars['UUID'];
  event: VideocallEventType;
}>;


export type SendVideoCallEventMutation = (
  { __typename?: 'Mutation' }
  & { sendVideocallEvent?: Maybe<(
    { __typename?: 'SendVideocallEventPayload' }
    & Pick<SendVideocallEventPayload, 'clientMutationId'>
  )> }
);

export type DeleteInvitationMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteInvitationMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvitation?: Maybe<(
    { __typename?: 'DeleteInvitationPayload' }
    & Pick<DeleteInvitationPayload, 'clientMutationId'>
  )> }
);

export type ResendInvitationMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ResendInvitationMutation = (
  { __typename?: 'Mutation' }
  & { resendInvitation?: Maybe<(
    { __typename?: 'ResendInvitationPayload' }
    & Pick<ResendInvitationPayload, 'clientMutationId'>
  )> }
);

export type LoginSeniorMutationVariables = Exact<{
  licence: Scalars['String'];
}>;


export type LoginSeniorMutation = (
  { __typename?: 'Mutation' }
  & { loginSenior?: Maybe<(
    { __typename?: 'LoginSeniorPayload' }
    & Pick<LoginSeniorPayload, 'jwtToken'>
  )> }
);

export type CreateFeedbackMutationVariables = Exact<{
  userId: Scalars['UUID'];
  deviceId: Scalars['UUID'];
  target: Scalars['String'];
  targetType: Scalars['String'];
  rating?: Maybe<Scalars['Int']>;
  params: Scalars['JSON'];
}>;


export type CreateFeedbackMutation = (
  { __typename: 'Mutation' }
  & { createFeedback?: Maybe<(
    { __typename?: 'CreateFeedbackPayload' }
    & { feedback?: Maybe<(
      { __typename?: 'Feedback' }
      & FeedbackItemFragment
    )> }
  )> }
);

export type CreateWebsiteMutationVariables = Exact<{
  userId: Scalars['UUID'];
  url: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
}>;


export type CreateWebsiteMutation = (
  { __typename: 'Mutation' }
  & { createWebsite?: Maybe<(
    { __typename?: 'CreateWebsitePayload' }
    & { website?: Maybe<(
      { __typename?: 'Website' }
      & Pick<Website, 'id' | 'url' | 'title' | 'thumbnail'>
    )> }
  )> }
);

export type UpdateWebsiteMutationVariables = Exact<{
  id: Scalars['Int'];
  userId: Scalars['UUID'];
  url: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
}>;


export type UpdateWebsiteMutation = (
  { __typename: 'Mutation' }
  & { updateWebsite?: Maybe<(
    { __typename?: 'UpdateWebsitePayload' }
    & Pick<UpdateWebsitePayload, 'clientMutationId'>
  )> }
);

export type IsAndroidDeviceSupportedMutationVariables = Exact<{
  androidVersion: Scalars['String'];
  chromeVersion: Scalars['String'];
  hwModel: Scalars['String'];
  utilizationMode: Scalars['String'];
}>;


export type IsAndroidDeviceSupportedMutation = (
  { __typename: 'Mutation' }
  & { isAndroidDeviceSupported?: Maybe<(
    { __typename?: 'IsAndroidDeviceSupportedPayload' }
    & Pick<IsAndroidDeviceSupportedPayload, 'boolean'>
  )> }
);

export type GenerateToyDatasetMutationVariables = Exact<{
  email: Scalars['String'];
  numberOfMessages?: Maybe<Scalars['Int']>;
  numberOfImages?: Maybe<Scalars['Int']>;
  numberOfUnread?: Maybe<Scalars['Int']>;
}>;


export type GenerateToyDatasetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'generateToyDataset'>
);

export type DeleteWebsiteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteWebsiteMutation = (
  { __typename: 'Mutation' }
  & { deleteWebsite?: Maybe<(
    { __typename?: 'DeleteWebsitePayload' }
    & Pick<DeleteWebsitePayload, 'clientMutationId'>
  )> }
);

export type CreateSystemMessageMutationVariables = Exact<{
  receiverId: Scalars['UUID'];
  payload: Scalars['JSON'];
  kind: SystemMessageType;
}>;


export type CreateSystemMessageMutation = (
  { __typename?: 'Mutation' }
  & { createSystemMessage?: Maybe<(
    { __typename?: 'CreateSystemMessagePayload' }
    & Pick<CreateSystemMessagePayload, 'clientMutationId'>
  )> }
);

export type CreateSenderKeyMutationVariables = Exact<{
  input: CreateSenderKeyInput;
}>;


export type CreateSenderKeyMutation = (
  { __typename?: 'Mutation' }
  & { createSenderKey?: Maybe<(
    { __typename?: 'CreateSenderKeyPayload' }
    & { senderKey?: Maybe<(
      { __typename?: 'SenderKey' }
      & SenderKeyItemFragment
    )> }
  )> }
);

export type ActivateLicenseMutationVariables = Exact<{
  input: ActivateLicenseInput;
}>;


export type ActivateLicenseMutation = (
  { __typename?: 'Mutation' }
  & { activateLicense?: Maybe<(
    { __typename?: 'ActivateLicensePayload' }
    & { licenseResponse?: Maybe<(
      { __typename?: 'LicenseResponse' }
      & Pick<LicenseResponse, 'flavour' | 'jwtToken' | 'contentGroup'>
    )> }
  )> }
);

export type CreateTestCustomerMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  flavour: Flavour;
  contractStatus: ContractStatus;
  contentGroup?: Maybe<Scalars['String']>;
  swMode?: Maybe<SwMode>;
}>;


export type CreateTestCustomerMutation = (
  { __typename: 'Mutation' }
  & Pick<Mutation, 'createTestCustomer'>
);

export type SendQrcodeMutationVariables = Exact<{
  input: SendQrcodeInput;
}>;


export type SendQrcodeMutation = (
  { __typename?: 'Mutation' }
  & { sendQrcode?: Maybe<(
    { __typename?: 'SendQrcodePayload' }
    & Pick<SendQrcodePayload, 'clientMutationId'>
  )> }
);

export type ChatListQueryVariables = Exact<{ [key: string]: never; }>;


export type ChatListQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
    & { groupsList: Array<(
      { __typename?: 'Group' }
      & ChatListItemFragment
    )> }
  )> }
);

export type GetChatQueryVariables = Exact<{
  chatId: Scalars['UUID'];
  first: Scalars['Int'];
  cursor?: Maybe<Scalars['Cursor']>;
}>;


export type GetChatQuery = (
  { __typename?: 'Query' }
  & { group?: Maybe<(
    { __typename?: 'Group' }
    & Pick<Group, 'id'>
    & { messages: (
      { __typename?: 'MessagesConnection' }
      & { nodes: Array<Maybe<(
        { __typename?: 'Message' }
        & FullMessageFragment
      )>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
      ) }
    ) }
    & ChatFragment
  )> }
);

export type GetAttachmentsListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAttachmentsListQuery = (
  { __typename?: 'Query' }
  & { getAttachmentsList?: Maybe<Array<Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'url' | 'height' | 'id' | 'createdAt' | 'width' | 'kind'>
    & { message?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'content' | 'senderKeyId'>
      & { author?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    )> }
  )>>> }
);

export type GetSeniorProfilesAttachmentsListQueryVariables = Exact<{
  seniorProfileId: Scalars['UUID'];
}>;


export type GetSeniorProfilesAttachmentsListQuery = (
  { __typename?: 'Query' }
  & { getSeniorProfileAttachmentsList?: Maybe<Array<Maybe<(
    { __typename?: 'Attachment' }
    & Pick<Attachment, 'url' | 'height' | 'id' | 'createdAt' | 'width' | 'kind'>
    & { message?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'content' | 'senderKeyId'>
      & { author?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    )> }
  )>>> }
);

export type GetUnreadMessagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUnreadMessagesQuery = (
  { __typename?: 'Query' }
  & { unreadMessagesList?: Maybe<Array<Maybe<(
    { __typename?: 'Message' }
    & FullMessageFragment
  )>>> }
);

export type GetUploadLinksQueryVariables = Exact<{
  keys: Array<Maybe<Scalars['String']>>;
}>;


export type GetUploadLinksQuery = (
  { __typename?: 'Query' }
  & { getUploadLinksWithKeys?: Maybe<Array<(
    { __typename?: 'UploadLink' }
    & Pick<UploadLink, 'key' | 'url'>
  )>> }
);

export type VerifyTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyTokenQuery = (
  { __typename?: 'Query' }
  & { verifyToken?: Maybe<(
    { __typename?: 'Response' }
    & Pick<Response, 'sub' | 'valid' | 'used' | 'expired' | 'email' | 'userId'>
  )> }
);

export type GetUpdateLinkQueryVariables = Exact<{
  version: Scalars['String'];
  flavour: Scalars['String'];
  swMode?: Maybe<SwMode>;
}>;


export type GetUpdateLinkQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'updateLink'>
);

export type GetDownloadLinkQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type GetDownloadLinkQuery = (
  { __typename?: 'Query' }
  & { getDownloadLink: (
    { __typename?: 'UploadLink' }
    & Pick<UploadLink, 'key' | 'url'>
  ) }
);

export type VideocallTokenQueryVariables = Exact<{
  chatId: Scalars['String'];
}>;


export type VideocallTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'videocallToken'>
);

export type VideocallInviteQueryVariables = Exact<{
  chatId: Scalars['String'];
}>;


export type VideocallInviteQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'videocallInvite'>
);

export type VideocallHangupQueryVariables = Exact<{
  chatId: Scalars['String'];
}>;


export type VideocallHangupQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'videocallHangup'>
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'licence'>
    & ContactFragment
  )> }
);

export type CheckHealthQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckHealthQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkHealth'>
);

export type GetMostRecentVideocallEventQueryVariables = Exact<{
  groupId: Scalars['UUID'];
  senderId: Scalars['UUID'];
}>;


export type GetMostRecentVideocallEventQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'mostRecentVideocallEvent'>
);

export type GetSupportedVideocallVersionQueryVariables = Exact<{
  groupId: Scalars['UUID'];
}>;


export type GetSupportedVideocallVersionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supportedVideocallVersion'>
);

export type GetWebsitesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWebsitesQuery = (
  { __typename?: 'Query' }
  & { websitesList?: Maybe<Array<(
    { __typename?: 'Website' }
    & Pick<Website, 'id' | 'url' | 'title' | 'thumbnail'>
  )>> }
);

export type GetWebSiteMetadataQueryVariables = Exact<{
  url: Scalars['String'];
}>;


export type GetWebSiteMetadataQuery = (
  { __typename?: 'Query' }
  & { getWebSiteMetadata?: Maybe<(
    { __typename?: 'Metadata' }
    & Pick<Metadata, 'valid' | 'url' | 'title' | 'image'>
  )> }
);

export type GetFeedbackListQueryVariables = Exact<{
  userId: Scalars['UUID'];
  targetType: Scalars['String'];
}>;


export type GetFeedbackListQuery = (
  { __typename?: 'Query' }
  & { feedbacksList?: Maybe<Array<(
    { __typename?: 'Feedback' }
    & FeedbackItemFragment
  )>> }
);

export type SeniorRecoveryKeysListQueryVariables = Exact<{ [key: string]: never; }>;


export type SeniorRecoveryKeysListQuery = (
  { __typename?: 'Query' }
  & { seniorRecoveryKeysList?: Maybe<Array<Maybe<(
    { __typename?: 'SeniorRecoveryKeysRecord' }
    & Pick<SeniorRecoveryKeysRecord, 'seniorId' | 'licence' | 'seniorName' | 'adminName' | 'adminId' | 'familyId'>
  )>>> }
);

export type GetSystemMessagesQueryVariables = Exact<{
  lowerBound?: Maybe<Scalars['Datetime']>;
  upperBound?: Maybe<Scalars['Datetime']>;
}>;


export type GetSystemMessagesQuery = (
  { __typename?: 'Query' }
  & { getSystemMessagesList?: Maybe<Array<Maybe<(
    { __typename?: 'SystemMessage' }
    & SystemMessageItemFragment
  )>>> }
);

export type GroupInvitationsListQueryVariables = Exact<{ [key: string]: never; }>;


export type GroupInvitationsListQuery = (
  { __typename?: 'Query' }
  & { groupInvitationsList?: Maybe<Array<Maybe<(
    { __typename?: 'Invitation' }
    & InvitationInfoFragment
  )>>> }
);

export type GetLicensesQueryVariables = Exact<{
  customerId?: Maybe<Scalars['String']>;
}>;


export type GetLicensesQuery = (
  { __typename?: 'Query' }
  & { licenses?: Maybe<Array<(
    { __typename?: 'License' }
    & LicenseItemFragment
  )>> }
);

export type GetAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAccountsQuery = (
  { __typename?: 'Query' }
  & { getAccounts?: Maybe<Array<(
    { __typename?: 'AccountData' }
    & Pick<AccountData, 'id' | 'name'>
  )>> }
);

export type GetCustomersQueryVariables = Exact<{
  accountId?: Maybe<Scalars['String']>;
}>;


export type GetCustomersQuery = (
  { __typename?: 'Query' }
  & { getCustomers?: Maybe<Array<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'name' | 'email'>
  )>> }
);

export type GetLicenseQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLicenseQuery = (
  { __typename?: 'Query' }
  & { getLicense?: Maybe<(
    { __typename?: 'LicenseData' }
    & Pick<LicenseData, 'flavour'>
  )> }
);

export type GetUserFlavourQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserFlavourQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUserFlavour'>
);

export type GetSwOnlyAppDownloadLinkQueryVariables = Exact<{
  shortId: Scalars['String'];
}>;


export type GetSwOnlyAppDownloadLinkQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSwOnlyAppDownloadLink'>
);

export type OnGroupJoinedSubscriptionVariables = Exact<{
  userId: Scalars['UUID'];
}>;


export type OnGroupJoinedSubscription = (
  { __typename?: 'Subscription' }
  & { groupJoined?: Maybe<(
    { __typename?: 'GroupSubscriptionPayload' }
    & { group: (
      { __typename?: 'Group' }
      & ChatListItemFragment
    ) }
  )> }
);

export type OnGroupLastReadByAllUpdatedSubscriptionVariables = Exact<{
  userId: Scalars['UUID'];
}>;


export type OnGroupLastReadByAllUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { groupLastReadByAllUpdated?: Maybe<(
    { __typename?: 'UpdateLastRemoteReadPayload' }
    & Pick<UpdateLastRemoteReadPayload, 'chatId' | 'lastReadByAll' | 'lastReceivedByAll'>
  )> }
);

export type OnMessageAddedSubscriptionVariables = Exact<{
  userId: Scalars['UUID'];
}>;


export type OnMessageAddedSubscription = (
  { __typename?: 'Subscription' }
  & { messageAdded?: Maybe<(
    { __typename?: 'MessageSubscriptionPayload' }
    & { message: (
      { __typename?: 'Message' }
      & FullMessageFragment
    ) }
  )> }
);

export type OnMessageDeletedSubscriptionVariables = Exact<{
  userId: Scalars['UUID'];
}>;


export type OnMessageDeletedSubscription = (
  { __typename?: 'Subscription' }
  & { messageDeleted?: Maybe<(
    { __typename?: 'DeleteMessageSubscriptionPayload' }
    & Pick<DeleteMessageSubscriptionPayload, 'chatId' | 'messageId'>
  )> }
);

export type OnInvitationSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnInvitationSubscription = (
  { __typename?: 'Subscription' }
  & { invitationAddedOrUpdated?: Maybe<(
    { __typename?: 'InvitationSubscriptionPayload' }
    & { invitation: (
      { __typename?: 'Invitation' }
      & InvitationInfoFragment
    ) }
  )> }
);

export type OnVideoCallEventSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnVideoCallEventSubscription = (
  { __typename?: 'Subscription' }
  & { newVideocallEvent?: Maybe<(
    { __typename?: 'VideocallEventSubscriptionPayload' }
    & { videocallEvent: (
      { __typename?: 'VideocallEvent' }
      & Pick<VideocallEvent, 'event' | 'groupId' | 'senderId'>
    ) }
  )> }
);

export type OnSystemMessageSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnSystemMessageSubscription = (
  { __typename?: 'Subscription' }
  & { systemMessageAdded?: Maybe<(
    { __typename?: 'systemMessageAddedPayload' }
    & { systemMessage: (
      { __typename?: 'SystemMessage' }
      & Pick<SystemMessage, 'senderId' | 'receiverId' | 'payload' | 'kind' | 'id' | 'hidden' | 'createdAt'>
    ) }
  )> }
);

export const ContactFragmentDoc = gql`
    fragment Contact on User {
  id
  name
  avatar
  avatarUrl
  kind
}
    `;
export const FullMessageFragmentDoc = gql`
    fragment FullMessage on Message {
  id
  content
  senderKeyId
  chatId
  createdAt
  author {
    ...Contact
    kind
  }
  attachmentsList {
    id
    status @client
    nodeId
    messageId
    createdAt
    kind
    url
    width
    height
    duration
  }
}
    ${ContactFragmentDoc}`;
export const MessageListFragmentDoc = gql`
    fragment MessageList on Group {
  id
  messagesList(orderBy: CREATED_AT_ASC) {
    ...FullMessage
  }
}
    ${FullMessageFragmentDoc}`;
export const SenderKeyItemFragmentDoc = gql`
    fragment SenderKeyItem on SenderKey {
  id
  groupId
  deviceId
  keyHash
  userId
  createdAt
}
    `;
export const UserGroupFragmentDoc = gql`
    fragment UserGroup on Group {
  id
  name
  avatar
  avatarUrl
  kind
  lastReadByAll
  lastReceivedByAll
  relatedSeniorEncryptionMode
  membersList {
    ...Contact
  }
  membershipsList {
    isAdmin
    member {
      id
    }
  }
  memberDevices {
    memberId
    deviceId
    publicKey
  }
  supportedFeatures
  senderKeysList(orderBy: CREATED_AT_DESC) {
    ...SenderKeyItem
  }
}
    ${ContactFragmentDoc}
${SenderKeyItemFragmentDoc}`;
export const ChatFragmentDoc = gql`
    fragment Chat on Group {
  ...UserGroup
  lastReadTimestampForCurrentUser
}
    ${UserGroupFragmentDoc}`;
export const ChatListItemFragmentDoc = gql`
    fragment ChatListItem on Group {
  ...UserGroup
  lastMessageForCurrentUser {
    ...FullMessage
  }
  unreadCountForCurrentUser
  lastReceivedByAll
}
    ${UserGroupFragmentDoc}
${FullMessageFragmentDoc}`;
export const InvitationInfoFragmentDoc = gql`
    fragment InvitationInfo on Invitation {
  id
  email
  status
  updatedAt
  createdAt
  invitedTo
  userByInvitedBy {
    id
    name
  }
}
    `;
export const FeedbackItemFragmentDoc = gql`
    fragment FeedbackItem on Feedback {
  id
  target
  targetType
}
    `;
export const SystemMessageItemFragmentDoc = gql`
    fragment SystemMessageItem on SystemMessage {
  createdAt
  hidden
  id
  kind
  payload
  receiverId
  senderId
  status @client
}
    `;
export const LicenseItemFragmentDoc = gql`
    fragment LicenseItem on License {
  id
  name
  active
  startDate
  endDate
  plan
  appUrl
  tabletName
  activatedAt
}
    `;
export const CreateOrRetrievePrivateChatDocument = gql`
    mutation createOrRetrievePrivateChat($memberId1: UUID!, $memberId2: UUID!) {
  createOrRetrievePrivateChat(input: {memberId1: $memberId1, memberId2: $memberId2}) {
    group {
      ...ChatListItem
    }
  }
}
    ${ChatListItemFragmentDoc}`;
export type CreateOrRetrievePrivateChatMutationFn = Apollo.MutationFunction<CreateOrRetrievePrivateChatMutation, CreateOrRetrievePrivateChatMutationVariables>;

/**
 * __useCreateOrRetrievePrivateChatMutation__
 *
 * To run a mutation, you first call `useCreateOrRetrievePrivateChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrRetrievePrivateChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrRetrievePrivateChatMutation, { data, loading, error }] = useCreateOrRetrievePrivateChatMutation({
 *   variables: {
 *      memberId1: // value for 'memberId1'
 *      memberId2: // value for 'memberId2'
 *   },
 * });
 */
export function useCreateOrRetrievePrivateChatMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrRetrievePrivateChatMutation, CreateOrRetrievePrivateChatMutationVariables>) {
        return Apollo.useMutation<CreateOrRetrievePrivateChatMutation, CreateOrRetrievePrivateChatMutationVariables>(CreateOrRetrievePrivateChatDocument, baseOptions);
      }
export type CreateOrRetrievePrivateChatMutationHookResult = ReturnType<typeof useCreateOrRetrievePrivateChatMutation>;
export type CreateOrRetrievePrivateChatMutationResult = Apollo.MutationResult<CreateOrRetrievePrivateChatMutation>;
export type CreateOrRetrievePrivateChatMutationOptions = Apollo.BaseMutationOptions<CreateOrRetrievePrivateChatMutation, CreateOrRetrievePrivateChatMutationVariables>;
export const CreateMessageDocument = gql`
    mutation CreateMessage($input: CreateMessageInput!) {
  createMessage(input: $input) {
    message {
      ...FullMessage
    }
  }
}
    ${FullMessageFragmentDoc}`;
export type CreateMessageMutationFn = Apollo.MutationFunction<CreateMessageMutation, CreateMessageMutationVariables>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageMutation, CreateMessageMutationVariables>) {
        return Apollo.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(CreateMessageDocument, baseOptions);
      }
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = Apollo.MutationResult<CreateMessageMutation>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<CreateMessageMutation, CreateMessageMutationVariables>;
export const DeleteMessageDocument = gql`
    mutation DeleteMessage($input: DeleteMessageInput!) {
  deleteMessage(input: $input) {
    message {
      id
    }
  }
}
    `;
export type DeleteMessageMutationFn = Apollo.MutationFunction<DeleteMessageMutation, DeleteMessageMutationVariables>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMessageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMessageMutation, DeleteMessageMutationVariables>) {
        return Apollo.useMutation<DeleteMessageMutation, DeleteMessageMutationVariables>(DeleteMessageDocument, baseOptions);
      }
export type DeleteMessageMutationHookResult = ReturnType<typeof useDeleteMessageMutation>;
export type DeleteMessageMutationResult = Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<DeleteMessageMutation, DeleteMessageMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: UUID!) {
  deleteUser(input: {id: $id}) {
    clientMutationId
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const MarkAsReadDocument = gql`
    mutation markAsRead($userId: UUID!, $messageId: UUID!) {
  markAsRead(input: {userId: $userId, messageId: $messageId}) {
    datetime
  }
}
    `;
export type MarkAsReadMutationFn = Apollo.MutationFunction<MarkAsReadMutation, MarkAsReadMutationVariables>;

/**
 * __useMarkAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReadMutation, { data, loading, error }] = useMarkAsReadMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useMarkAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkAsReadMutation, MarkAsReadMutationVariables>) {
        return Apollo.useMutation<MarkAsReadMutation, MarkAsReadMutationVariables>(MarkAsReadDocument, baseOptions);
      }
export type MarkAsReadMutationHookResult = ReturnType<typeof useMarkAsReadMutation>;
export type MarkAsReadMutationResult = Apollo.MutationResult<MarkAsReadMutation>;
export type MarkAsReadMutationOptions = Apollo.BaseMutationOptions<MarkAsReadMutation, MarkAsReadMutationVariables>;
export const MarkAsReceivedDocument = gql`
    mutation markAsReceived($userId: UUID!, $messageId: UUID!) {
  markAsReceived(input: {userId: $userId, messageId: $messageId}) {
    datetime
  }
}
    `;
export type MarkAsReceivedMutationFn = Apollo.MutationFunction<MarkAsReceivedMutation, MarkAsReceivedMutationVariables>;

/**
 * __useMarkAsReceivedMutation__
 *
 * To run a mutation, you first call `useMarkAsReceivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReceivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReceivedMutation, { data, loading, error }] = useMarkAsReceivedMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useMarkAsReceivedMutation(baseOptions?: Apollo.MutationHookOptions<MarkAsReceivedMutation, MarkAsReceivedMutationVariables>) {
        return Apollo.useMutation<MarkAsReceivedMutation, MarkAsReceivedMutationVariables>(MarkAsReceivedDocument, baseOptions);
      }
export type MarkAsReceivedMutationHookResult = ReturnType<typeof useMarkAsReceivedMutation>;
export type MarkAsReceivedMutationResult = Apollo.MutationResult<MarkAsReceivedMutation>;
export type MarkAsReceivedMutationOptions = Apollo.BaseMutationOptions<MarkAsReceivedMutation, MarkAsReceivedMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(input: {email: $email, password: $password}) {
    jwtToken
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const InviteDocument = gql`
    mutation invite($input: InviteInput!) {
  invite(input: $input) {
    clientMutationId
  }
}
    `;
export type InviteMutationFn = Apollo.MutationFunction<InviteMutation, InviteMutationVariables>;

/**
 * __useInviteMutation__
 *
 * To run a mutation, you first call `useInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMutation, { data, loading, error }] = useInviteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteMutation(baseOptions?: Apollo.MutationHookOptions<InviteMutation, InviteMutationVariables>) {
        return Apollo.useMutation<InviteMutation, InviteMutationVariables>(InviteDocument, baseOptions);
      }
export type InviteMutationHookResult = ReturnType<typeof useInviteMutation>;
export type InviteMutationResult = Apollo.MutationResult<InviteMutation>;
export type InviteMutationOptions = Apollo.BaseMutationOptions<InviteMutation, InviteMutationVariables>;
export const RegisterUserDocument = gql`
    mutation registerUser($input: RegisterUserInput!) {
  registerUser(input: $input) {
    jwtToken
  }
}
    `;
export type RegisterUserMutationFn = Apollo.MutationFunction<RegisterUserMutation, RegisterUserMutationVariables>;

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterUserMutation, RegisterUserMutationVariables>) {
        return Apollo.useMutation<RegisterUserMutation, RegisterUserMutationVariables>(RegisterUserDocument, baseOptions);
      }
export type RegisterUserMutationHookResult = ReturnType<typeof useRegisterUserMutation>;
export type RegisterUserMutationResult = Apollo.MutationResult<RegisterUserMutation>;
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<RegisterUserMutation, RegisterUserMutationVariables>;
export const AcceptInvitationToGroupDocument = gql`
    mutation acceptInvitationToGroup($input: AcceptInvitationToGroupInput!) {
  acceptInvitationToGroup(input: $input) {
    clientMutationId
  }
}
    `;
export type AcceptInvitationToGroupMutationFn = Apollo.MutationFunction<AcceptInvitationToGroupMutation, AcceptInvitationToGroupMutationVariables>;

/**
 * __useAcceptInvitationToGroupMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationToGroupMutation, { data, loading, error }] = useAcceptInvitationToGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptInvitationToGroupMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvitationToGroupMutation, AcceptInvitationToGroupMutationVariables>) {
        return Apollo.useMutation<AcceptInvitationToGroupMutation, AcceptInvitationToGroupMutationVariables>(AcceptInvitationToGroupDocument, baseOptions);
      }
export type AcceptInvitationToGroupMutationHookResult = ReturnType<typeof useAcceptInvitationToGroupMutation>;
export type AcceptInvitationToGroupMutationResult = Apollo.MutationResult<AcceptInvitationToGroupMutation>;
export type AcceptInvitationToGroupMutationOptions = Apollo.BaseMutationOptions<AcceptInvitationToGroupMutation, AcceptInvitationToGroupMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(input: {email: $email}) {
    clientMutationId
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!) {
  resetPassword(input: {newPassword: $password}) {
    jwtToken
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const CreateSeniorDocument = gql`
    mutation createSenior($name: String!, $flavour: String!) {
  createSenior(input: {name: $name, flavour: $flavour}) {
    jwtToken
  }
}
    `;
export type CreateSeniorMutationFn = Apollo.MutationFunction<CreateSeniorMutation, CreateSeniorMutationVariables>;

/**
 * __useCreateSeniorMutation__
 *
 * To run a mutation, you first call `useCreateSeniorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeniorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeniorMutation, { data, loading, error }] = useCreateSeniorMutation({
 *   variables: {
 *      name: // value for 'name'
 *      flavour: // value for 'flavour'
 *   },
 * });
 */
export function useCreateSeniorMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeniorMutation, CreateSeniorMutationVariables>) {
        return Apollo.useMutation<CreateSeniorMutation, CreateSeniorMutationVariables>(CreateSeniorDocument, baseOptions);
      }
export type CreateSeniorMutationHookResult = ReturnType<typeof useCreateSeniorMutation>;
export type CreateSeniorMutationResult = Apollo.MutationResult<CreateSeniorMutation>;
export type CreateSeniorMutationOptions = Apollo.BaseMutationOptions<CreateSeniorMutation, CreateSeniorMutationVariables>;
export const CreateSeniorProfileDocument = gql`
    mutation createSeniorProfile($name: String!) {
  createSeniorProfile(name: $name) {
    id
  }
}
    `;
export type CreateSeniorProfileMutationFn = Apollo.MutationFunction<CreateSeniorProfileMutation, CreateSeniorProfileMutationVariables>;

/**
 * __useCreateSeniorProfileMutation__
 *
 * To run a mutation, you first call `useCreateSeniorProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSeniorProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSeniorProfileMutation, { data, loading, error }] = useCreateSeniorProfileMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateSeniorProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateSeniorProfileMutation, CreateSeniorProfileMutationVariables>) {
        return Apollo.useMutation<CreateSeniorProfileMutation, CreateSeniorProfileMutationVariables>(CreateSeniorProfileDocument, baseOptions);
      }
export type CreateSeniorProfileMutationHookResult = ReturnType<typeof useCreateSeniorProfileMutation>;
export type CreateSeniorProfileMutationResult = Apollo.MutationResult<CreateSeniorProfileMutation>;
export type CreateSeniorProfileMutationOptions = Apollo.BaseMutationOptions<CreateSeniorProfileMutation, CreateSeniorProfileMutationVariables>;
export const RefreshAccessTokenDocument = gql`
    mutation refreshAccessToken {
  refreshAccessToken
}
    `;
export type RefreshAccessTokenMutationFn = Apollo.MutationFunction<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>;

/**
 * __useRefreshAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAccessTokenMutation, { data, loading, error }] = useRefreshAccessTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>) {
        return Apollo.useMutation<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>(RefreshAccessTokenDocument, baseOptions);
      }
export type RefreshAccessTokenMutationHookResult = ReturnType<typeof useRefreshAccessTokenMutation>;
export type RefreshAccessTokenMutationResult = Apollo.MutationResult<RefreshAccessTokenMutation>;
export type RefreshAccessTokenMutationOptions = Apollo.BaseMutationOptions<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>;
export const RegisterPushNotificationDeviceDocument = gql`
    mutation registerPushNotificationDevice($deviceId: UUID!, $token: String!, $deviceModel: String!) {
  registerPushNotificationDevice(input: {deviceId: $deviceId, token: $token, deviceModel: $deviceModel}) {
    clientMutationId
  }
}
    `;
export type RegisterPushNotificationDeviceMutationFn = Apollo.MutationFunction<RegisterPushNotificationDeviceMutation, RegisterPushNotificationDeviceMutationVariables>;

/**
 * __useRegisterPushNotificationDeviceMutation__
 *
 * To run a mutation, you first call `useRegisterPushNotificationDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterPushNotificationDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerPushNotificationDeviceMutation, { data, loading, error }] = useRegisterPushNotificationDeviceMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      token: // value for 'token'
 *      deviceModel: // value for 'deviceModel'
 *   },
 * });
 */
export function useRegisterPushNotificationDeviceMutation(baseOptions?: Apollo.MutationHookOptions<RegisterPushNotificationDeviceMutation, RegisterPushNotificationDeviceMutationVariables>) {
        return Apollo.useMutation<RegisterPushNotificationDeviceMutation, RegisterPushNotificationDeviceMutationVariables>(RegisterPushNotificationDeviceDocument, baseOptions);
      }
export type RegisterPushNotificationDeviceMutationHookResult = ReturnType<typeof useRegisterPushNotificationDeviceMutation>;
export type RegisterPushNotificationDeviceMutationResult = Apollo.MutationResult<RegisterPushNotificationDeviceMutation>;
export type RegisterPushNotificationDeviceMutationOptions = Apollo.BaseMutationOptions<RegisterPushNotificationDeviceMutation, RegisterPushNotificationDeviceMutationVariables>;
export const DeregisterPushNotificationDeviceDocument = gql`
    mutation deregisterPushNotificationDevice($deviceId: UUID!) {
  deregisterPushNotificationDevice(input: {deviceId: $deviceId}) {
    clientMutationId
  }
}
    `;
export type DeregisterPushNotificationDeviceMutationFn = Apollo.MutationFunction<DeregisterPushNotificationDeviceMutation, DeregisterPushNotificationDeviceMutationVariables>;

/**
 * __useDeregisterPushNotificationDeviceMutation__
 *
 * To run a mutation, you first call `useDeregisterPushNotificationDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeregisterPushNotificationDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deregisterPushNotificationDeviceMutation, { data, loading, error }] = useDeregisterPushNotificationDeviceMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeregisterPushNotificationDeviceMutation(baseOptions?: Apollo.MutationHookOptions<DeregisterPushNotificationDeviceMutation, DeregisterPushNotificationDeviceMutationVariables>) {
        return Apollo.useMutation<DeregisterPushNotificationDeviceMutation, DeregisterPushNotificationDeviceMutationVariables>(DeregisterPushNotificationDeviceDocument, baseOptions);
      }
export type DeregisterPushNotificationDeviceMutationHookResult = ReturnType<typeof useDeregisterPushNotificationDeviceMutation>;
export type DeregisterPushNotificationDeviceMutationResult = Apollo.MutationResult<DeregisterPushNotificationDeviceMutation>;
export type DeregisterPushNotificationDeviceMutationOptions = Apollo.BaseMutationOptions<DeregisterPushNotificationDeviceMutation, DeregisterPushNotificationDeviceMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: UUID!, $name: String!, $avatar: String) {
  updateUser(input: {patch: {name: $name, avatar: $avatar}, id: $id}) {
    user {
      id
      name
      avatar
      avatarUrl
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      avatar: // value for 'avatar'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpgradeUserDocument = gql`
    mutation upgradeUser($id: UUID!, $name: String!, $kind: UserType) {
  updateUser(input: {patch: {name: $name, kind: $kind}, id: $id}) {
    user {
      id
      name
      kind
      avatar
      avatarUrl
    }
  }
}
    `;
export type UpgradeUserMutationFn = Apollo.MutationFunction<UpgradeUserMutation, UpgradeUserMutationVariables>;

/**
 * __useUpgradeUserMutation__
 *
 * To run a mutation, you first call `useUpgradeUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeUserMutation, { data, loading, error }] = useUpgradeUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useUpgradeUserMutation(baseOptions?: Apollo.MutationHookOptions<UpgradeUserMutation, UpgradeUserMutationVariables>) {
        return Apollo.useMutation<UpgradeUserMutation, UpgradeUserMutationVariables>(UpgradeUserDocument, baseOptions);
      }
export type UpgradeUserMutationHookResult = ReturnType<typeof useUpgradeUserMutation>;
export type UpgradeUserMutationResult = Apollo.MutationResult<UpgradeUserMutation>;
export type UpgradeUserMutationOptions = Apollo.BaseMutationOptions<UpgradeUserMutation, UpgradeUserMutationVariables>;
export const UpdateGroupDocument = gql`
    mutation updateGroup($id: UUID!, $name: String!, $avatar: String) {
  updateGroup(input: {patch: {name: $name, avatar: $avatar}, id: $id}) {
    group {
      id
      name
      avatar
      avatarUrl
    }
  }
}
    `;
export type UpdateGroupMutationFn = Apollo.MutationFunction<UpdateGroupMutation, UpdateGroupMutationVariables>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      avatar: // value for 'avatar'
 *   },
 * });
 */
export function useUpdateGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupMutation, UpdateGroupMutationVariables>) {
        return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument, baseOptions);
      }
export type UpdateGroupMutationHookResult = ReturnType<typeof useUpdateGroupMutation>;
export type UpdateGroupMutationResult = Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const AddUserDeviceDocument = gql`
    mutation addUserDevice($userId: UUID!, $platform: DevicePlatform!, $deviceId: UUID!, $osVersion: String!, $appVersion: String!, $appFlavour: String!, $videocallVersion: Int!, $deviceModel: String!, $deviceSerialNo: String, $deviceImei: String, $deviceIccid: String, $chromeVersion: String, $appPackageId: String, $appPackageName: String, $publicKey: String, $contentVersion: String, $appSigning: String, $appSwMode: String, $supportsEncryption: Boolean) {
  addUserDevice(input: {userId: $userId, platform: $platform, deviceId: $deviceId, osVersion: $osVersion, appVersion: $appVersion, appFlavour: $appFlavour, videocallVersion: $videocallVersion, deviceModel: $deviceModel, deviceSerialNo: $deviceSerialNo, deviceImei: $deviceImei, deviceIccid: $deviceIccid, chromeVersion: $chromeVersion, appPackageId: $appPackageId, appPackageName: $appPackageName, publicKey: $publicKey, contentVersion: $contentVersion, appSigning: $appSigning, appSwMode: $appSwMode, supportsEncryption: $supportsEncryption}) {
    clientMutationId
  }
}
    `;
export type AddUserDeviceMutationFn = Apollo.MutationFunction<AddUserDeviceMutation, AddUserDeviceMutationVariables>;

/**
 * __useAddUserDeviceMutation__
 *
 * To run a mutation, you first call `useAddUserDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserDeviceMutation, { data, loading, error }] = useAddUserDeviceMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      platform: // value for 'platform'
 *      deviceId: // value for 'deviceId'
 *      osVersion: // value for 'osVersion'
 *      appVersion: // value for 'appVersion'
 *      appFlavour: // value for 'appFlavour'
 *      videocallVersion: // value for 'videocallVersion'
 *      deviceModel: // value for 'deviceModel'
 *      deviceSerialNo: // value for 'deviceSerialNo'
 *      deviceImei: // value for 'deviceImei'
 *      deviceIccid: // value for 'deviceIccid'
 *      chromeVersion: // value for 'chromeVersion'
 *      appPackageId: // value for 'appPackageId'
 *      appPackageName: // value for 'appPackageName'
 *      publicKey: // value for 'publicKey'
 *      contentVersion: // value for 'contentVersion'
 *      appSigning: // value for 'appSigning'
 *      appSwMode: // value for 'appSwMode'
 *      supportsEncryption: // value for 'supportsEncryption'
 *   },
 * });
 */
export function useAddUserDeviceMutation(baseOptions?: Apollo.MutationHookOptions<AddUserDeviceMutation, AddUserDeviceMutationVariables>) {
        return Apollo.useMutation<AddUserDeviceMutation, AddUserDeviceMutationVariables>(AddUserDeviceDocument, baseOptions);
      }
export type AddUserDeviceMutationHookResult = ReturnType<typeof useAddUserDeviceMutation>;
export type AddUserDeviceMutationResult = Apollo.MutationResult<AddUserDeviceMutation>;
export type AddUserDeviceMutationOptions = Apollo.BaseMutationOptions<AddUserDeviceMutation, AddUserDeviceMutationVariables>;
export const RegisterCatapushDeviceDocument = gql`
    mutation registerCatapushDevice($deviceId: String!, $deviceModel: String!) {
  registerCatapushDevice(deviceId: $deviceId, deviceModel: $deviceModel) {
    password
  }
}
    `;
export type RegisterCatapushDeviceMutationFn = Apollo.MutationFunction<RegisterCatapushDeviceMutation, RegisterCatapushDeviceMutationVariables>;

/**
 * __useRegisterCatapushDeviceMutation__
 *
 * To run a mutation, you first call `useRegisterCatapushDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterCatapushDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerCatapushDeviceMutation, { data, loading, error }] = useRegisterCatapushDeviceMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      deviceModel: // value for 'deviceModel'
 *   },
 * });
 */
export function useRegisterCatapushDeviceMutation(baseOptions?: Apollo.MutationHookOptions<RegisterCatapushDeviceMutation, RegisterCatapushDeviceMutationVariables>) {
        return Apollo.useMutation<RegisterCatapushDeviceMutation, RegisterCatapushDeviceMutationVariables>(RegisterCatapushDeviceDocument, baseOptions);
      }
export type RegisterCatapushDeviceMutationHookResult = ReturnType<typeof useRegisterCatapushDeviceMutation>;
export type RegisterCatapushDeviceMutationResult = Apollo.MutationResult<RegisterCatapushDeviceMutation>;
export type RegisterCatapushDeviceMutationOptions = Apollo.BaseMutationOptions<RegisterCatapushDeviceMutation, RegisterCatapushDeviceMutationVariables>;
export const DeRegisterCatapushDeviceDocument = gql`
    mutation deRegisterCatapushDevice($deviceId: String!) {
  deRegisterCatapushDevice(deviceId: $deviceId)
}
    `;
export type DeRegisterCatapushDeviceMutationFn = Apollo.MutationFunction<DeRegisterCatapushDeviceMutation, DeRegisterCatapushDeviceMutationVariables>;

/**
 * __useDeRegisterCatapushDeviceMutation__
 *
 * To run a mutation, you first call `useDeRegisterCatapushDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeRegisterCatapushDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deRegisterCatapushDeviceMutation, { data, loading, error }] = useDeRegisterCatapushDeviceMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useDeRegisterCatapushDeviceMutation(baseOptions?: Apollo.MutationHookOptions<DeRegisterCatapushDeviceMutation, DeRegisterCatapushDeviceMutationVariables>) {
        return Apollo.useMutation<DeRegisterCatapushDeviceMutation, DeRegisterCatapushDeviceMutationVariables>(DeRegisterCatapushDeviceDocument, baseOptions);
      }
export type DeRegisterCatapushDeviceMutationHookResult = ReturnType<typeof useDeRegisterCatapushDeviceMutation>;
export type DeRegisterCatapushDeviceMutationResult = Apollo.MutationResult<DeRegisterCatapushDeviceMutation>;
export type DeRegisterCatapushDeviceMutationOptions = Apollo.BaseMutationOptions<DeRegisterCatapushDeviceMutation, DeRegisterCatapushDeviceMutationVariables>;
export const SendVideoCallEventDocument = gql`
    mutation sendVideoCallEvent($groupId: UUID!, $senderId: UUID!, $event: VideocallEventType!) {
  sendVideocallEvent(input: {groupId: $groupId, senderId: $senderId, eventType: $event}) {
    clientMutationId
  }
}
    `;
export type SendVideoCallEventMutationFn = Apollo.MutationFunction<SendVideoCallEventMutation, SendVideoCallEventMutationVariables>;

/**
 * __useSendVideoCallEventMutation__
 *
 * To run a mutation, you first call `useSendVideoCallEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVideoCallEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVideoCallEventMutation, { data, loading, error }] = useSendVideoCallEventMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      senderId: // value for 'senderId'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useSendVideoCallEventMutation(baseOptions?: Apollo.MutationHookOptions<SendVideoCallEventMutation, SendVideoCallEventMutationVariables>) {
        return Apollo.useMutation<SendVideoCallEventMutation, SendVideoCallEventMutationVariables>(SendVideoCallEventDocument, baseOptions);
      }
export type SendVideoCallEventMutationHookResult = ReturnType<typeof useSendVideoCallEventMutation>;
export type SendVideoCallEventMutationResult = Apollo.MutationResult<SendVideoCallEventMutation>;
export type SendVideoCallEventMutationOptions = Apollo.BaseMutationOptions<SendVideoCallEventMutation, SendVideoCallEventMutationVariables>;
export const DeleteInvitationDocument = gql`
    mutation deleteInvitation($id: UUID!) {
  deleteInvitation(input: {id: $id}) {
    clientMutationId
  }
}
    `;
export type DeleteInvitationMutationFn = Apollo.MutationFunction<DeleteInvitationMutation, DeleteInvitationMutationVariables>;

/**
 * __useDeleteInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvitationMutation, { data, loading, error }] = useDeleteInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInvitationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvitationMutation, DeleteInvitationMutationVariables>) {
        return Apollo.useMutation<DeleteInvitationMutation, DeleteInvitationMutationVariables>(DeleteInvitationDocument, baseOptions);
      }
export type DeleteInvitationMutationHookResult = ReturnType<typeof useDeleteInvitationMutation>;
export type DeleteInvitationMutationResult = Apollo.MutationResult<DeleteInvitationMutation>;
export type DeleteInvitationMutationOptions = Apollo.BaseMutationOptions<DeleteInvitationMutation, DeleteInvitationMutationVariables>;
export const ResendInvitationDocument = gql`
    mutation resendInvitation($id: UUID!) {
  resendInvitation(input: {id: $id}) {
    clientMutationId
  }
}
    `;
export type ResendInvitationMutationFn = Apollo.MutationFunction<ResendInvitationMutation, ResendInvitationMutationVariables>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ResendInvitationMutation, ResendInvitationMutationVariables>) {
        return Apollo.useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(ResendInvitationDocument, baseOptions);
      }
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = Apollo.MutationResult<ResendInvitationMutation>;
export type ResendInvitationMutationOptions = Apollo.BaseMutationOptions<ResendInvitationMutation, ResendInvitationMutationVariables>;
export const LoginSeniorDocument = gql`
    mutation loginSenior($licence: String!) {
  loginSenior(input: {licence: $licence}) {
    jwtToken
  }
}
    `;
export type LoginSeniorMutationFn = Apollo.MutationFunction<LoginSeniorMutation, LoginSeniorMutationVariables>;

/**
 * __useLoginSeniorMutation__
 *
 * To run a mutation, you first call `useLoginSeniorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginSeniorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginSeniorMutation, { data, loading, error }] = useLoginSeniorMutation({
 *   variables: {
 *      licence: // value for 'licence'
 *   },
 * });
 */
export function useLoginSeniorMutation(baseOptions?: Apollo.MutationHookOptions<LoginSeniorMutation, LoginSeniorMutationVariables>) {
        return Apollo.useMutation<LoginSeniorMutation, LoginSeniorMutationVariables>(LoginSeniorDocument, baseOptions);
      }
export type LoginSeniorMutationHookResult = ReturnType<typeof useLoginSeniorMutation>;
export type LoginSeniorMutationResult = Apollo.MutationResult<LoginSeniorMutation>;
export type LoginSeniorMutationOptions = Apollo.BaseMutationOptions<LoginSeniorMutation, LoginSeniorMutationVariables>;
export const CreateFeedbackDocument = gql`
    mutation createFeedback($userId: UUID!, $deviceId: UUID!, $target: String!, $targetType: String!, $rating: Int, $params: JSON!) {
  __typename
  createFeedback(input: {feedback: {userId: $userId, deviceId: $deviceId, target: $target, targetType: $targetType, rating: $rating, params: $params}}) {
    feedback {
      ...FeedbackItem
    }
  }
}
    ${FeedbackItemFragmentDoc}`;
export type CreateFeedbackMutationFn = Apollo.MutationFunction<CreateFeedbackMutation, CreateFeedbackMutationVariables>;

/**
 * __useCreateFeedbackMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackMutation, { data, loading, error }] = useCreateFeedbackMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      deviceId: // value for 'deviceId'
 *      target: // value for 'target'
 *      targetType: // value for 'targetType'
 *      rating: // value for 'rating'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useCreateFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>) {
        return Apollo.useMutation<CreateFeedbackMutation, CreateFeedbackMutationVariables>(CreateFeedbackDocument, baseOptions);
      }
export type CreateFeedbackMutationHookResult = ReturnType<typeof useCreateFeedbackMutation>;
export type CreateFeedbackMutationResult = Apollo.MutationResult<CreateFeedbackMutation>;
export type CreateFeedbackMutationOptions = Apollo.BaseMutationOptions<CreateFeedbackMutation, CreateFeedbackMutationVariables>;
export const CreateWebsiteDocument = gql`
    mutation createWebsite($userId: UUID!, $url: String!, $title: String, $thumbnail: String) {
  __typename
  createWebsite(input: {website: {userId: $userId, url: $url, title: $title, thumbnail: $thumbnail}}) {
    website {
      id
      url
      title
      thumbnail
    }
  }
}
    `;
export type CreateWebsiteMutationFn = Apollo.MutationFunction<CreateWebsiteMutation, CreateWebsiteMutationVariables>;

/**
 * __useCreateWebsiteMutation__
 *
 * To run a mutation, you first call `useCreateWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebsiteMutation, { data, loading, error }] = useCreateWebsiteMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      url: // value for 'url'
 *      title: // value for 'title'
 *      thumbnail: // value for 'thumbnail'
 *   },
 * });
 */
export function useCreateWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateWebsiteMutation, CreateWebsiteMutationVariables>) {
        return Apollo.useMutation<CreateWebsiteMutation, CreateWebsiteMutationVariables>(CreateWebsiteDocument, baseOptions);
      }
export type CreateWebsiteMutationHookResult = ReturnType<typeof useCreateWebsiteMutation>;
export type CreateWebsiteMutationResult = Apollo.MutationResult<CreateWebsiteMutation>;
export type CreateWebsiteMutationOptions = Apollo.BaseMutationOptions<CreateWebsiteMutation, CreateWebsiteMutationVariables>;
export const UpdateWebsiteDocument = gql`
    mutation updateWebsite($id: Int!, $userId: UUID!, $url: String!, $title: String, $thumbnail: String) {
  __typename
  updateWebsite(input: {patch: {userId: $userId, url: $url, title: $title, thumbnail: $thumbnail}, id: $id}) {
    clientMutationId
  }
}
    `;
export type UpdateWebsiteMutationFn = Apollo.MutationFunction<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>;

/**
 * __useUpdateWebsiteMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteMutation, { data, loading, error }] = useUpdateWebsiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *      url: // value for 'url'
 *      title: // value for 'title'
 *      thumbnail: // value for 'thumbnail'
 *   },
 * });
 */
export function useUpdateWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>) {
        return Apollo.useMutation<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>(UpdateWebsiteDocument, baseOptions);
      }
export type UpdateWebsiteMutationHookResult = ReturnType<typeof useUpdateWebsiteMutation>;
export type UpdateWebsiteMutationResult = Apollo.MutationResult<UpdateWebsiteMutation>;
export type UpdateWebsiteMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>;
export const IsAndroidDeviceSupportedDocument = gql`
    mutation isAndroidDeviceSupported($androidVersion: String!, $chromeVersion: String!, $hwModel: String!, $utilizationMode: String!) {
  __typename
  isAndroidDeviceSupported(input: {androidVersion: $androidVersion, chromeVersion: $chromeVersion, hwModel: $hwModel, utilizationMode: $utilizationMode}) {
    boolean
  }
}
    `;
export type IsAndroidDeviceSupportedMutationFn = Apollo.MutationFunction<IsAndroidDeviceSupportedMutation, IsAndroidDeviceSupportedMutationVariables>;

/**
 * __useIsAndroidDeviceSupportedMutation__
 *
 * To run a mutation, you first call `useIsAndroidDeviceSupportedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIsAndroidDeviceSupportedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [isAndroidDeviceSupportedMutation, { data, loading, error }] = useIsAndroidDeviceSupportedMutation({
 *   variables: {
 *      androidVersion: // value for 'androidVersion'
 *      chromeVersion: // value for 'chromeVersion'
 *      hwModel: // value for 'hwModel'
 *      utilizationMode: // value for 'utilizationMode'
 *   },
 * });
 */
export function useIsAndroidDeviceSupportedMutation(baseOptions?: Apollo.MutationHookOptions<IsAndroidDeviceSupportedMutation, IsAndroidDeviceSupportedMutationVariables>) {
        return Apollo.useMutation<IsAndroidDeviceSupportedMutation, IsAndroidDeviceSupportedMutationVariables>(IsAndroidDeviceSupportedDocument, baseOptions);
      }
export type IsAndroidDeviceSupportedMutationHookResult = ReturnType<typeof useIsAndroidDeviceSupportedMutation>;
export type IsAndroidDeviceSupportedMutationResult = Apollo.MutationResult<IsAndroidDeviceSupportedMutation>;
export type IsAndroidDeviceSupportedMutationOptions = Apollo.BaseMutationOptions<IsAndroidDeviceSupportedMutation, IsAndroidDeviceSupportedMutationVariables>;
export const GenerateToyDatasetDocument = gql`
    mutation generateToyDataset($email: String!, $numberOfMessages: Int, $numberOfImages: Int, $numberOfUnread: Int) {
  generateToyDataset(email: $email, numberOfMessages: $numberOfMessages, numberOfImages: $numberOfImages, numberOfUnread: $numberOfUnread)
}
    `;
export type GenerateToyDatasetMutationFn = Apollo.MutationFunction<GenerateToyDatasetMutation, GenerateToyDatasetMutationVariables>;

/**
 * __useGenerateToyDatasetMutation__
 *
 * To run a mutation, you first call `useGenerateToyDatasetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateToyDatasetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateToyDatasetMutation, { data, loading, error }] = useGenerateToyDatasetMutation({
 *   variables: {
 *      email: // value for 'email'
 *      numberOfMessages: // value for 'numberOfMessages'
 *      numberOfImages: // value for 'numberOfImages'
 *      numberOfUnread: // value for 'numberOfUnread'
 *   },
 * });
 */
export function useGenerateToyDatasetMutation(baseOptions?: Apollo.MutationHookOptions<GenerateToyDatasetMutation, GenerateToyDatasetMutationVariables>) {
        return Apollo.useMutation<GenerateToyDatasetMutation, GenerateToyDatasetMutationVariables>(GenerateToyDatasetDocument, baseOptions);
      }
export type GenerateToyDatasetMutationHookResult = ReturnType<typeof useGenerateToyDatasetMutation>;
export type GenerateToyDatasetMutationResult = Apollo.MutationResult<GenerateToyDatasetMutation>;
export type GenerateToyDatasetMutationOptions = Apollo.BaseMutationOptions<GenerateToyDatasetMutation, GenerateToyDatasetMutationVariables>;
export const DeleteWebsiteDocument = gql`
    mutation deleteWebsite($id: Int!) {
  __typename
  deleteWebsite(input: {id: $id}) {
    clientMutationId
  }
}
    `;
export type DeleteWebsiteMutationFn = Apollo.MutationFunction<DeleteWebsiteMutation, DeleteWebsiteMutationVariables>;

/**
 * __useDeleteWebsiteMutation__
 *
 * To run a mutation, you first call `useDeleteWebsiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebsiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebsiteMutation, { data, loading, error }] = useDeleteWebsiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWebsiteMutation, DeleteWebsiteMutationVariables>) {
        return Apollo.useMutation<DeleteWebsiteMutation, DeleteWebsiteMutationVariables>(DeleteWebsiteDocument, baseOptions);
      }
export type DeleteWebsiteMutationHookResult = ReturnType<typeof useDeleteWebsiteMutation>;
export type DeleteWebsiteMutationResult = Apollo.MutationResult<DeleteWebsiteMutation>;
export type DeleteWebsiteMutationOptions = Apollo.BaseMutationOptions<DeleteWebsiteMutation, DeleteWebsiteMutationVariables>;
export const CreateSystemMessageDocument = gql`
    mutation createSystemMessage($receiverId: UUID!, $payload: JSON!, $kind: SystemMessageType!) {
  createSystemMessage(input: {kind: $kind, payload: $payload, receiverId: $receiverId}) {
    clientMutationId
  }
}
    `;
export type CreateSystemMessageMutationFn = Apollo.MutationFunction<CreateSystemMessageMutation, CreateSystemMessageMutationVariables>;

/**
 * __useCreateSystemMessageMutation__
 *
 * To run a mutation, you first call `useCreateSystemMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSystemMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSystemMessageMutation, { data, loading, error }] = useCreateSystemMessageMutation({
 *   variables: {
 *      receiverId: // value for 'receiverId'
 *      payload: // value for 'payload'
 *      kind: // value for 'kind'
 *   },
 * });
 */
export function useCreateSystemMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateSystemMessageMutation, CreateSystemMessageMutationVariables>) {
        return Apollo.useMutation<CreateSystemMessageMutation, CreateSystemMessageMutationVariables>(CreateSystemMessageDocument, baseOptions);
      }
export type CreateSystemMessageMutationHookResult = ReturnType<typeof useCreateSystemMessageMutation>;
export type CreateSystemMessageMutationResult = Apollo.MutationResult<CreateSystemMessageMutation>;
export type CreateSystemMessageMutationOptions = Apollo.BaseMutationOptions<CreateSystemMessageMutation, CreateSystemMessageMutationVariables>;
export const CreateSenderKeyDocument = gql`
    mutation createSenderKey($input: CreateSenderKeyInput!) {
  createSenderKey(input: $input) {
    senderKey {
      ...SenderKeyItem
    }
  }
}
    ${SenderKeyItemFragmentDoc}`;
export type CreateSenderKeyMutationFn = Apollo.MutationFunction<CreateSenderKeyMutation, CreateSenderKeyMutationVariables>;

/**
 * __useCreateSenderKeyMutation__
 *
 * To run a mutation, you first call `useCreateSenderKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSenderKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSenderKeyMutation, { data, loading, error }] = useCreateSenderKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSenderKeyMutation(baseOptions?: Apollo.MutationHookOptions<CreateSenderKeyMutation, CreateSenderKeyMutationVariables>) {
        return Apollo.useMutation<CreateSenderKeyMutation, CreateSenderKeyMutationVariables>(CreateSenderKeyDocument, baseOptions);
      }
export type CreateSenderKeyMutationHookResult = ReturnType<typeof useCreateSenderKeyMutation>;
export type CreateSenderKeyMutationResult = Apollo.MutationResult<CreateSenderKeyMutation>;
export type CreateSenderKeyMutationOptions = Apollo.BaseMutationOptions<CreateSenderKeyMutation, CreateSenderKeyMutationVariables>;
export const ActivateLicenseDocument = gql`
    mutation activateLicense($input: ActivateLicenseInput!) {
  activateLicense(input: $input) {
    licenseResponse {
      flavour
      jwtToken
      contentGroup
    }
  }
}
    `;
export type ActivateLicenseMutationFn = Apollo.MutationFunction<ActivateLicenseMutation, ActivateLicenseMutationVariables>;

/**
 * __useActivateLicenseMutation__
 *
 * To run a mutation, you first call `useActivateLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateLicenseMutation, { data, loading, error }] = useActivateLicenseMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateLicenseMutation(baseOptions?: Apollo.MutationHookOptions<ActivateLicenseMutation, ActivateLicenseMutationVariables>) {
        return Apollo.useMutation<ActivateLicenseMutation, ActivateLicenseMutationVariables>(ActivateLicenseDocument, baseOptions);
      }
export type ActivateLicenseMutationHookResult = ReturnType<typeof useActivateLicenseMutation>;
export type ActivateLicenseMutationResult = Apollo.MutationResult<ActivateLicenseMutation>;
export type ActivateLicenseMutationOptions = Apollo.BaseMutationOptions<ActivateLicenseMutation, ActivateLicenseMutationVariables>;
export const CreateTestCustomerDocument = gql`
    mutation createTestCustomer($name: String!, $email: String!, $flavour: Flavour!, $contractStatus: ContractStatus!, $contentGroup: String, $swMode: SwMode) {
  __typename
  createTestCustomer(name: $name, email: $email, flavour: $flavour, contractStatus: $contractStatus, contentGroup: $contentGroup, swMode: $swMode)
}
    `;
export type CreateTestCustomerMutationFn = Apollo.MutationFunction<CreateTestCustomerMutation, CreateTestCustomerMutationVariables>;

/**
 * __useCreateTestCustomerMutation__
 *
 * To run a mutation, you first call `useCreateTestCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTestCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTestCustomerMutation, { data, loading, error }] = useCreateTestCustomerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      flavour: // value for 'flavour'
 *      contractStatus: // value for 'contractStatus'
 *      contentGroup: // value for 'contentGroup'
 *      swMode: // value for 'swMode'
 *   },
 * });
 */
export function useCreateTestCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateTestCustomerMutation, CreateTestCustomerMutationVariables>) {
        return Apollo.useMutation<CreateTestCustomerMutation, CreateTestCustomerMutationVariables>(CreateTestCustomerDocument, baseOptions);
      }
export type CreateTestCustomerMutationHookResult = ReturnType<typeof useCreateTestCustomerMutation>;
export type CreateTestCustomerMutationResult = Apollo.MutationResult<CreateTestCustomerMutation>;
export type CreateTestCustomerMutationOptions = Apollo.BaseMutationOptions<CreateTestCustomerMutation, CreateTestCustomerMutationVariables>;
export const SendQrcodeDocument = gql`
    mutation sendQrcode($input: SendQrcodeInput!) {
  sendQrcode(input: $input) {
    clientMutationId
  }
}
    `;
export type SendQrcodeMutationFn = Apollo.MutationFunction<SendQrcodeMutation, SendQrcodeMutationVariables>;

/**
 * __useSendQrcodeMutation__
 *
 * To run a mutation, you first call `useSendQrcodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendQrcodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendQrcodeMutation, { data, loading, error }] = useSendQrcodeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendQrcodeMutation(baseOptions?: Apollo.MutationHookOptions<SendQrcodeMutation, SendQrcodeMutationVariables>) {
        return Apollo.useMutation<SendQrcodeMutation, SendQrcodeMutationVariables>(SendQrcodeDocument, baseOptions);
      }
export type SendQrcodeMutationHookResult = ReturnType<typeof useSendQrcodeMutation>;
export type SendQrcodeMutationResult = Apollo.MutationResult<SendQrcodeMutation>;
export type SendQrcodeMutationOptions = Apollo.BaseMutationOptions<SendQrcodeMutation, SendQrcodeMutationVariables>;
export const ChatListDocument = gql`
    query ChatList {
  me {
    id
    groupsList {
      ...ChatListItem
    }
  }
}
    ${ChatListItemFragmentDoc}`;

/**
 * __useChatListQuery__
 *
 * To run a query within a React component, call `useChatListQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatListQuery({
 *   variables: {
 *   },
 * });
 */
export function useChatListQuery(baseOptions?: Apollo.QueryHookOptions<ChatListQuery, ChatListQueryVariables>) {
        return Apollo.useQuery<ChatListQuery, ChatListQueryVariables>(ChatListDocument, baseOptions);
      }
export function useChatListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatListQuery, ChatListQueryVariables>) {
          return Apollo.useLazyQuery<ChatListQuery, ChatListQueryVariables>(ChatListDocument, baseOptions);
        }
export type ChatListQueryHookResult = ReturnType<typeof useChatListQuery>;
export type ChatListLazyQueryHookResult = ReturnType<typeof useChatListLazyQuery>;
export type ChatListQueryResult = Apollo.QueryResult<ChatListQuery, ChatListQueryVariables>;
export const GetChatDocument = gql`
    query GetChat($chatId: UUID!, $first: Int!, $cursor: Cursor) {
  group(id: $chatId) {
    ...Chat
    id
    messages(first: $first, after: $cursor, orderBy: CREATED_AT_DESC) {
      nodes {
        ...FullMessage
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        endCursor
      }
    }
  }
}
    ${ChatFragmentDoc}
${FullMessageFragmentDoc}`;

/**
 * __useGetChatQuery__
 *
 * To run a query within a React component, call `useGetChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useGetChatQuery(baseOptions: Apollo.QueryHookOptions<GetChatQuery, GetChatQueryVariables>) {
        return Apollo.useQuery<GetChatQuery, GetChatQueryVariables>(GetChatDocument, baseOptions);
      }
export function useGetChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatQuery, GetChatQueryVariables>) {
          return Apollo.useLazyQuery<GetChatQuery, GetChatQueryVariables>(GetChatDocument, baseOptions);
        }
export type GetChatQueryHookResult = ReturnType<typeof useGetChatQuery>;
export type GetChatLazyQueryHookResult = ReturnType<typeof useGetChatLazyQuery>;
export type GetChatQueryResult = Apollo.QueryResult<GetChatQuery, GetChatQueryVariables>;
export const GetAttachmentsListDocument = gql`
    query GetAttachmentsList {
  getAttachmentsList {
    url
    height
    id
    createdAt
    width
    kind
    message {
      content
      senderKeyId
      author {
        name
      }
    }
  }
}
    `;

/**
 * __useGetAttachmentsListQuery__
 *
 * To run a query within a React component, call `useGetAttachmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAttachmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAttachmentsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAttachmentsListQuery(baseOptions?: Apollo.QueryHookOptions<GetAttachmentsListQuery, GetAttachmentsListQueryVariables>) {
        return Apollo.useQuery<GetAttachmentsListQuery, GetAttachmentsListQueryVariables>(GetAttachmentsListDocument, baseOptions);
      }
export function useGetAttachmentsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAttachmentsListQuery, GetAttachmentsListQueryVariables>) {
          return Apollo.useLazyQuery<GetAttachmentsListQuery, GetAttachmentsListQueryVariables>(GetAttachmentsListDocument, baseOptions);
        }
export type GetAttachmentsListQueryHookResult = ReturnType<typeof useGetAttachmentsListQuery>;
export type GetAttachmentsListLazyQueryHookResult = ReturnType<typeof useGetAttachmentsListLazyQuery>;
export type GetAttachmentsListQueryResult = Apollo.QueryResult<GetAttachmentsListQuery, GetAttachmentsListQueryVariables>;
export const GetSeniorProfilesAttachmentsListDocument = gql`
    query GetSeniorProfilesAttachmentsList($seniorProfileId: UUID!) {
  getSeniorProfileAttachmentsList(seniorProfileId: $seniorProfileId) {
    url
    height
    id
    createdAt
    width
    kind
    message {
      content
      senderKeyId
      author {
        name
      }
    }
  }
}
    `;

/**
 * __useGetSeniorProfilesAttachmentsListQuery__
 *
 * To run a query within a React component, call `useGetSeniorProfilesAttachmentsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeniorProfilesAttachmentsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeniorProfilesAttachmentsListQuery({
 *   variables: {
 *      seniorProfileId: // value for 'seniorProfileId'
 *   },
 * });
 */
export function useGetSeniorProfilesAttachmentsListQuery(baseOptions: Apollo.QueryHookOptions<GetSeniorProfilesAttachmentsListQuery, GetSeniorProfilesAttachmentsListQueryVariables>) {
        return Apollo.useQuery<GetSeniorProfilesAttachmentsListQuery, GetSeniorProfilesAttachmentsListQueryVariables>(GetSeniorProfilesAttachmentsListDocument, baseOptions);
      }
export function useGetSeniorProfilesAttachmentsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeniorProfilesAttachmentsListQuery, GetSeniorProfilesAttachmentsListQueryVariables>) {
          return Apollo.useLazyQuery<GetSeniorProfilesAttachmentsListQuery, GetSeniorProfilesAttachmentsListQueryVariables>(GetSeniorProfilesAttachmentsListDocument, baseOptions);
        }
export type GetSeniorProfilesAttachmentsListQueryHookResult = ReturnType<typeof useGetSeniorProfilesAttachmentsListQuery>;
export type GetSeniorProfilesAttachmentsListLazyQueryHookResult = ReturnType<typeof useGetSeniorProfilesAttachmentsListLazyQuery>;
export type GetSeniorProfilesAttachmentsListQueryResult = Apollo.QueryResult<GetSeniorProfilesAttachmentsListQuery, GetSeniorProfilesAttachmentsListQueryVariables>;
export const GetUnreadMessagesDocument = gql`
    query GetUnreadMessages {
  unreadMessagesList {
    ...FullMessage
  }
}
    ${FullMessageFragmentDoc}`;

/**
 * __useGetUnreadMessagesQuery__
 *
 * To run a query within a React component, call `useGetUnreadMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnreadMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnreadMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUnreadMessagesQuery(baseOptions?: Apollo.QueryHookOptions<GetUnreadMessagesQuery, GetUnreadMessagesQueryVariables>) {
        return Apollo.useQuery<GetUnreadMessagesQuery, GetUnreadMessagesQueryVariables>(GetUnreadMessagesDocument, baseOptions);
      }
export function useGetUnreadMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnreadMessagesQuery, GetUnreadMessagesQueryVariables>) {
          return Apollo.useLazyQuery<GetUnreadMessagesQuery, GetUnreadMessagesQueryVariables>(GetUnreadMessagesDocument, baseOptions);
        }
export type GetUnreadMessagesQueryHookResult = ReturnType<typeof useGetUnreadMessagesQuery>;
export type GetUnreadMessagesLazyQueryHookResult = ReturnType<typeof useGetUnreadMessagesLazyQuery>;
export type GetUnreadMessagesQueryResult = Apollo.QueryResult<GetUnreadMessagesQuery, GetUnreadMessagesQueryVariables>;
export const GetUploadLinksDocument = gql`
    query GetUploadLinks($keys: [String]!) {
  getUploadLinksWithKeys(keys: $keys) {
    key
    url
  }
}
    `;

/**
 * __useGetUploadLinksQuery__
 *
 * To run a query within a React component, call `useGetUploadLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUploadLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUploadLinksQuery({
 *   variables: {
 *      keys: // value for 'keys'
 *   },
 * });
 */
export function useGetUploadLinksQuery(baseOptions: Apollo.QueryHookOptions<GetUploadLinksQuery, GetUploadLinksQueryVariables>) {
        return Apollo.useQuery<GetUploadLinksQuery, GetUploadLinksQueryVariables>(GetUploadLinksDocument, baseOptions);
      }
export function useGetUploadLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUploadLinksQuery, GetUploadLinksQueryVariables>) {
          return Apollo.useLazyQuery<GetUploadLinksQuery, GetUploadLinksQueryVariables>(GetUploadLinksDocument, baseOptions);
        }
export type GetUploadLinksQueryHookResult = ReturnType<typeof useGetUploadLinksQuery>;
export type GetUploadLinksLazyQueryHookResult = ReturnType<typeof useGetUploadLinksLazyQuery>;
export type GetUploadLinksQueryResult = Apollo.QueryResult<GetUploadLinksQuery, GetUploadLinksQueryVariables>;
export const VerifyTokenDocument = gql`
    query VerifyToken($token: String!) {
  verifyToken(token: $token) {
    sub
    valid
    used
    expired
    email
    userId
  }
}
    `;

/**
 * __useVerifyTokenQuery__
 *
 * To run a query within a React component, call `useVerifyTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyTokenQuery(baseOptions: Apollo.QueryHookOptions<VerifyTokenQuery, VerifyTokenQueryVariables>) {
        return Apollo.useQuery<VerifyTokenQuery, VerifyTokenQueryVariables>(VerifyTokenDocument, baseOptions);
      }
export function useVerifyTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyTokenQuery, VerifyTokenQueryVariables>) {
          return Apollo.useLazyQuery<VerifyTokenQuery, VerifyTokenQueryVariables>(VerifyTokenDocument, baseOptions);
        }
export type VerifyTokenQueryHookResult = ReturnType<typeof useVerifyTokenQuery>;
export type VerifyTokenLazyQueryHookResult = ReturnType<typeof useVerifyTokenLazyQuery>;
export type VerifyTokenQueryResult = Apollo.QueryResult<VerifyTokenQuery, VerifyTokenQueryVariables>;
export const GetUpdateLinkDocument = gql`
    query getUpdateLink($version: String!, $flavour: String!, $swMode: SwMode) {
  updateLink(version: $version, flavour: $flavour, swMode: $swMode)
}
    `;

/**
 * __useGetUpdateLinkQuery__
 *
 * To run a query within a React component, call `useGetUpdateLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUpdateLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUpdateLinkQuery({
 *   variables: {
 *      version: // value for 'version'
 *      flavour: // value for 'flavour'
 *      swMode: // value for 'swMode'
 *   },
 * });
 */
export function useGetUpdateLinkQuery(baseOptions: Apollo.QueryHookOptions<GetUpdateLinkQuery, GetUpdateLinkQueryVariables>) {
        return Apollo.useQuery<GetUpdateLinkQuery, GetUpdateLinkQueryVariables>(GetUpdateLinkDocument, baseOptions);
      }
export function useGetUpdateLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUpdateLinkQuery, GetUpdateLinkQueryVariables>) {
          return Apollo.useLazyQuery<GetUpdateLinkQuery, GetUpdateLinkQueryVariables>(GetUpdateLinkDocument, baseOptions);
        }
export type GetUpdateLinkQueryHookResult = ReturnType<typeof useGetUpdateLinkQuery>;
export type GetUpdateLinkLazyQueryHookResult = ReturnType<typeof useGetUpdateLinkLazyQuery>;
export type GetUpdateLinkQueryResult = Apollo.QueryResult<GetUpdateLinkQuery, GetUpdateLinkQueryVariables>;
export const GetDownloadLinkDocument = gql`
    query getDownloadLink($key: String!) {
  getDownloadLink(key: $key) {
    key
    url
  }
}
    `;

/**
 * __useGetDownloadLinkQuery__
 *
 * To run a query within a React component, call `useGetDownloadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDownloadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDownloadLinkQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetDownloadLinkQuery(baseOptions: Apollo.QueryHookOptions<GetDownloadLinkQuery, GetDownloadLinkQueryVariables>) {
        return Apollo.useQuery<GetDownloadLinkQuery, GetDownloadLinkQueryVariables>(GetDownloadLinkDocument, baseOptions);
      }
export function useGetDownloadLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDownloadLinkQuery, GetDownloadLinkQueryVariables>) {
          return Apollo.useLazyQuery<GetDownloadLinkQuery, GetDownloadLinkQueryVariables>(GetDownloadLinkDocument, baseOptions);
        }
export type GetDownloadLinkQueryHookResult = ReturnType<typeof useGetDownloadLinkQuery>;
export type GetDownloadLinkLazyQueryHookResult = ReturnType<typeof useGetDownloadLinkLazyQuery>;
export type GetDownloadLinkQueryResult = Apollo.QueryResult<GetDownloadLinkQuery, GetDownloadLinkQueryVariables>;
export const VideocallTokenDocument = gql`
    query videocallToken($chatId: String!) {
  videocallToken(chatId: $chatId)
}
    `;

/**
 * __useVideocallTokenQuery__
 *
 * To run a query within a React component, call `useVideocallTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideocallTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideocallTokenQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useVideocallTokenQuery(baseOptions: Apollo.QueryHookOptions<VideocallTokenQuery, VideocallTokenQueryVariables>) {
        return Apollo.useQuery<VideocallTokenQuery, VideocallTokenQueryVariables>(VideocallTokenDocument, baseOptions);
      }
export function useVideocallTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideocallTokenQuery, VideocallTokenQueryVariables>) {
          return Apollo.useLazyQuery<VideocallTokenQuery, VideocallTokenQueryVariables>(VideocallTokenDocument, baseOptions);
        }
export type VideocallTokenQueryHookResult = ReturnType<typeof useVideocallTokenQuery>;
export type VideocallTokenLazyQueryHookResult = ReturnType<typeof useVideocallTokenLazyQuery>;
export type VideocallTokenQueryResult = Apollo.QueryResult<VideocallTokenQuery, VideocallTokenQueryVariables>;
export const VideocallInviteDocument = gql`
    query videocallInvite($chatId: String!) {
  videocallInvite(chatId: $chatId)
}
    `;

/**
 * __useVideocallInviteQuery__
 *
 * To run a query within a React component, call `useVideocallInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideocallInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideocallInviteQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useVideocallInviteQuery(baseOptions: Apollo.QueryHookOptions<VideocallInviteQuery, VideocallInviteQueryVariables>) {
        return Apollo.useQuery<VideocallInviteQuery, VideocallInviteQueryVariables>(VideocallInviteDocument, baseOptions);
      }
export function useVideocallInviteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideocallInviteQuery, VideocallInviteQueryVariables>) {
          return Apollo.useLazyQuery<VideocallInviteQuery, VideocallInviteQueryVariables>(VideocallInviteDocument, baseOptions);
        }
export type VideocallInviteQueryHookResult = ReturnType<typeof useVideocallInviteQuery>;
export type VideocallInviteLazyQueryHookResult = ReturnType<typeof useVideocallInviteLazyQuery>;
export type VideocallInviteQueryResult = Apollo.QueryResult<VideocallInviteQuery, VideocallInviteQueryVariables>;
export const VideocallHangupDocument = gql`
    query videocallHangup($chatId: String!) {
  videocallHangup(chatId: $chatId)
}
    `;

/**
 * __useVideocallHangupQuery__
 *
 * To run a query within a React component, call `useVideocallHangupQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideocallHangupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideocallHangupQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useVideocallHangupQuery(baseOptions: Apollo.QueryHookOptions<VideocallHangupQuery, VideocallHangupQueryVariables>) {
        return Apollo.useQuery<VideocallHangupQuery, VideocallHangupQueryVariables>(VideocallHangupDocument, baseOptions);
      }
export function useVideocallHangupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VideocallHangupQuery, VideocallHangupQueryVariables>) {
          return Apollo.useLazyQuery<VideocallHangupQuery, VideocallHangupQueryVariables>(VideocallHangupDocument, baseOptions);
        }
export type VideocallHangupQueryHookResult = ReturnType<typeof useVideocallHangupQuery>;
export type VideocallHangupLazyQueryHookResult = ReturnType<typeof useVideocallHangupLazyQuery>;
export type VideocallHangupQueryResult = Apollo.QueryResult<VideocallHangupQuery, VideocallHangupQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...Contact
    email
    licence
  }
}
    ${ContactFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, baseOptions);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const CheckHealthDocument = gql`
    query checkHealth {
  checkHealth
}
    `;

/**
 * __useCheckHealthQuery__
 *
 * To run a query within a React component, call `useCheckHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckHealthQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckHealthQuery(baseOptions?: Apollo.QueryHookOptions<CheckHealthQuery, CheckHealthQueryVariables>) {
        return Apollo.useQuery<CheckHealthQuery, CheckHealthQueryVariables>(CheckHealthDocument, baseOptions);
      }
export function useCheckHealthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckHealthQuery, CheckHealthQueryVariables>) {
          return Apollo.useLazyQuery<CheckHealthQuery, CheckHealthQueryVariables>(CheckHealthDocument, baseOptions);
        }
export type CheckHealthQueryHookResult = ReturnType<typeof useCheckHealthQuery>;
export type CheckHealthLazyQueryHookResult = ReturnType<typeof useCheckHealthLazyQuery>;
export type CheckHealthQueryResult = Apollo.QueryResult<CheckHealthQuery, CheckHealthQueryVariables>;
export const GetMostRecentVideocallEventDocument = gql`
    query getMostRecentVideocallEvent($groupId: UUID!, $senderId: UUID!) {
  mostRecentVideocallEvent(groupId: $groupId, senderId: $senderId)
}
    `;

/**
 * __useGetMostRecentVideocallEventQuery__
 *
 * To run a query within a React component, call `useGetMostRecentVideocallEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMostRecentVideocallEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMostRecentVideocallEventQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      senderId: // value for 'senderId'
 *   },
 * });
 */
export function useGetMostRecentVideocallEventQuery(baseOptions: Apollo.QueryHookOptions<GetMostRecentVideocallEventQuery, GetMostRecentVideocallEventQueryVariables>) {
        return Apollo.useQuery<GetMostRecentVideocallEventQuery, GetMostRecentVideocallEventQueryVariables>(GetMostRecentVideocallEventDocument, baseOptions);
      }
export function useGetMostRecentVideocallEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMostRecentVideocallEventQuery, GetMostRecentVideocallEventQueryVariables>) {
          return Apollo.useLazyQuery<GetMostRecentVideocallEventQuery, GetMostRecentVideocallEventQueryVariables>(GetMostRecentVideocallEventDocument, baseOptions);
        }
export type GetMostRecentVideocallEventQueryHookResult = ReturnType<typeof useGetMostRecentVideocallEventQuery>;
export type GetMostRecentVideocallEventLazyQueryHookResult = ReturnType<typeof useGetMostRecentVideocallEventLazyQuery>;
export type GetMostRecentVideocallEventQueryResult = Apollo.QueryResult<GetMostRecentVideocallEventQuery, GetMostRecentVideocallEventQueryVariables>;
export const GetSupportedVideocallVersionDocument = gql`
    query getSupportedVideocallVersion($groupId: UUID!) {
  supportedVideocallVersion(groupId: $groupId)
}
    `;

/**
 * __useGetSupportedVideocallVersionQuery__
 *
 * To run a query within a React component, call `useGetSupportedVideocallVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportedVideocallVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportedVideocallVersionQuery({
 *   variables: {
 *      groupId: // value for 'groupId'
 *   },
 * });
 */
export function useGetSupportedVideocallVersionQuery(baseOptions: Apollo.QueryHookOptions<GetSupportedVideocallVersionQuery, GetSupportedVideocallVersionQueryVariables>) {
        return Apollo.useQuery<GetSupportedVideocallVersionQuery, GetSupportedVideocallVersionQueryVariables>(GetSupportedVideocallVersionDocument, baseOptions);
      }
export function useGetSupportedVideocallVersionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupportedVideocallVersionQuery, GetSupportedVideocallVersionQueryVariables>) {
          return Apollo.useLazyQuery<GetSupportedVideocallVersionQuery, GetSupportedVideocallVersionQueryVariables>(GetSupportedVideocallVersionDocument, baseOptions);
        }
export type GetSupportedVideocallVersionQueryHookResult = ReturnType<typeof useGetSupportedVideocallVersionQuery>;
export type GetSupportedVideocallVersionLazyQueryHookResult = ReturnType<typeof useGetSupportedVideocallVersionLazyQuery>;
export type GetSupportedVideocallVersionQueryResult = Apollo.QueryResult<GetSupportedVideocallVersionQuery, GetSupportedVideocallVersionQueryVariables>;
export const GetWebsitesDocument = gql`
    query getWebsites {
  websitesList {
    id
    url
    title
    thumbnail
  }
}
    `;

/**
 * __useGetWebsitesQuery__
 *
 * To run a query within a React component, call `useGetWebsitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebsitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebsitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWebsitesQuery(baseOptions?: Apollo.QueryHookOptions<GetWebsitesQuery, GetWebsitesQueryVariables>) {
        return Apollo.useQuery<GetWebsitesQuery, GetWebsitesQueryVariables>(GetWebsitesDocument, baseOptions);
      }
export function useGetWebsitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebsitesQuery, GetWebsitesQueryVariables>) {
          return Apollo.useLazyQuery<GetWebsitesQuery, GetWebsitesQueryVariables>(GetWebsitesDocument, baseOptions);
        }
export type GetWebsitesQueryHookResult = ReturnType<typeof useGetWebsitesQuery>;
export type GetWebsitesLazyQueryHookResult = ReturnType<typeof useGetWebsitesLazyQuery>;
export type GetWebsitesQueryResult = Apollo.QueryResult<GetWebsitesQuery, GetWebsitesQueryVariables>;
export const GetWebSiteMetadataDocument = gql`
    query getWebSiteMetadata($url: String!) {
  getWebSiteMetadata(url: $url) {
    valid
    url
    title
    image
  }
}
    `;

/**
 * __useGetWebSiteMetadataQuery__
 *
 * To run a query within a React component, call `useGetWebSiteMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebSiteMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebSiteMetadataQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGetWebSiteMetadataQuery(baseOptions: Apollo.QueryHookOptions<GetWebSiteMetadataQuery, GetWebSiteMetadataQueryVariables>) {
        return Apollo.useQuery<GetWebSiteMetadataQuery, GetWebSiteMetadataQueryVariables>(GetWebSiteMetadataDocument, baseOptions);
      }
export function useGetWebSiteMetadataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebSiteMetadataQuery, GetWebSiteMetadataQueryVariables>) {
          return Apollo.useLazyQuery<GetWebSiteMetadataQuery, GetWebSiteMetadataQueryVariables>(GetWebSiteMetadataDocument, baseOptions);
        }
export type GetWebSiteMetadataQueryHookResult = ReturnType<typeof useGetWebSiteMetadataQuery>;
export type GetWebSiteMetadataLazyQueryHookResult = ReturnType<typeof useGetWebSiteMetadataLazyQuery>;
export type GetWebSiteMetadataQueryResult = Apollo.QueryResult<GetWebSiteMetadataQuery, GetWebSiteMetadataQueryVariables>;
export const GetFeedbackListDocument = gql`
    query getFeedbackList($userId: UUID!, $targetType: String!) {
  feedbacksList(condition: {userId: $userId, targetType: $targetType}) {
    ...FeedbackItem
  }
}
    ${FeedbackItemFragmentDoc}`;

/**
 * __useGetFeedbackListQuery__
 *
 * To run a query within a React component, call `useGetFeedbackListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      targetType: // value for 'targetType'
 *   },
 * });
 */
export function useGetFeedbackListQuery(baseOptions: Apollo.QueryHookOptions<GetFeedbackListQuery, GetFeedbackListQueryVariables>) {
        return Apollo.useQuery<GetFeedbackListQuery, GetFeedbackListQueryVariables>(GetFeedbackListDocument, baseOptions);
      }
export function useGetFeedbackListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbackListQuery, GetFeedbackListQueryVariables>) {
          return Apollo.useLazyQuery<GetFeedbackListQuery, GetFeedbackListQueryVariables>(GetFeedbackListDocument, baseOptions);
        }
export type GetFeedbackListQueryHookResult = ReturnType<typeof useGetFeedbackListQuery>;
export type GetFeedbackListLazyQueryHookResult = ReturnType<typeof useGetFeedbackListLazyQuery>;
export type GetFeedbackListQueryResult = Apollo.QueryResult<GetFeedbackListQuery, GetFeedbackListQueryVariables>;
export const SeniorRecoveryKeysListDocument = gql`
    query seniorRecoveryKeysList {
  seniorRecoveryKeysList {
    seniorId
    licence
    seniorName
    adminName
    adminId
    familyId
  }
}
    `;

/**
 * __useSeniorRecoveryKeysListQuery__
 *
 * To run a query within a React component, call `useSeniorRecoveryKeysListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeniorRecoveryKeysListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeniorRecoveryKeysListQuery({
 *   variables: {
 *   },
 * });
 */
export function useSeniorRecoveryKeysListQuery(baseOptions?: Apollo.QueryHookOptions<SeniorRecoveryKeysListQuery, SeniorRecoveryKeysListQueryVariables>) {
        return Apollo.useQuery<SeniorRecoveryKeysListQuery, SeniorRecoveryKeysListQueryVariables>(SeniorRecoveryKeysListDocument, baseOptions);
      }
export function useSeniorRecoveryKeysListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeniorRecoveryKeysListQuery, SeniorRecoveryKeysListQueryVariables>) {
          return Apollo.useLazyQuery<SeniorRecoveryKeysListQuery, SeniorRecoveryKeysListQueryVariables>(SeniorRecoveryKeysListDocument, baseOptions);
        }
export type SeniorRecoveryKeysListQueryHookResult = ReturnType<typeof useSeniorRecoveryKeysListQuery>;
export type SeniorRecoveryKeysListLazyQueryHookResult = ReturnType<typeof useSeniorRecoveryKeysListLazyQuery>;
export type SeniorRecoveryKeysListQueryResult = Apollo.QueryResult<SeniorRecoveryKeysListQuery, SeniorRecoveryKeysListQueryVariables>;
export const GetSystemMessagesDocument = gql`
    query getSystemMessages($lowerBound: Datetime, $upperBound: Datetime) {
  getSystemMessagesList(lowerBound: $lowerBound, upperBound: $upperBound) {
    ...SystemMessageItem
  }
}
    ${SystemMessageItemFragmentDoc}`;

/**
 * __useGetSystemMessagesQuery__
 *
 * To run a query within a React component, call `useGetSystemMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemMessagesQuery({
 *   variables: {
 *      lowerBound: // value for 'lowerBound'
 *      upperBound: // value for 'upperBound'
 *   },
 * });
 */
export function useGetSystemMessagesQuery(baseOptions?: Apollo.QueryHookOptions<GetSystemMessagesQuery, GetSystemMessagesQueryVariables>) {
        return Apollo.useQuery<GetSystemMessagesQuery, GetSystemMessagesQueryVariables>(GetSystemMessagesDocument, baseOptions);
      }
export function useGetSystemMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSystemMessagesQuery, GetSystemMessagesQueryVariables>) {
          return Apollo.useLazyQuery<GetSystemMessagesQuery, GetSystemMessagesQueryVariables>(GetSystemMessagesDocument, baseOptions);
        }
export type GetSystemMessagesQueryHookResult = ReturnType<typeof useGetSystemMessagesQuery>;
export type GetSystemMessagesLazyQueryHookResult = ReturnType<typeof useGetSystemMessagesLazyQuery>;
export type GetSystemMessagesQueryResult = Apollo.QueryResult<GetSystemMessagesQuery, GetSystemMessagesQueryVariables>;
export const GroupInvitationsListDocument = gql`
    query GroupInvitationsList {
  groupInvitationsList {
    ...InvitationInfo
  }
}
    ${InvitationInfoFragmentDoc}`;

/**
 * __useGroupInvitationsListQuery__
 *
 * To run a query within a React component, call `useGroupInvitationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupInvitationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupInvitationsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupInvitationsListQuery(baseOptions?: Apollo.QueryHookOptions<GroupInvitationsListQuery, GroupInvitationsListQueryVariables>) {
        return Apollo.useQuery<GroupInvitationsListQuery, GroupInvitationsListQueryVariables>(GroupInvitationsListDocument, baseOptions);
      }
export function useGroupInvitationsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupInvitationsListQuery, GroupInvitationsListQueryVariables>) {
          return Apollo.useLazyQuery<GroupInvitationsListQuery, GroupInvitationsListQueryVariables>(GroupInvitationsListDocument, baseOptions);
        }
export type GroupInvitationsListQueryHookResult = ReturnType<typeof useGroupInvitationsListQuery>;
export type GroupInvitationsListLazyQueryHookResult = ReturnType<typeof useGroupInvitationsListLazyQuery>;
export type GroupInvitationsListQueryResult = Apollo.QueryResult<GroupInvitationsListQuery, GroupInvitationsListQueryVariables>;
export const GetLicensesDocument = gql`
    query getLicenses($customerId: String) {
  licenses(customerId: $customerId) {
    ...LicenseItem
  }
}
    ${LicenseItemFragmentDoc}`;

/**
 * __useGetLicensesQuery__
 *
 * To run a query within a React component, call `useGetLicensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLicensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLicensesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetLicensesQuery(baseOptions?: Apollo.QueryHookOptions<GetLicensesQuery, GetLicensesQueryVariables>) {
        return Apollo.useQuery<GetLicensesQuery, GetLicensesQueryVariables>(GetLicensesDocument, baseOptions);
      }
export function useGetLicensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLicensesQuery, GetLicensesQueryVariables>) {
          return Apollo.useLazyQuery<GetLicensesQuery, GetLicensesQueryVariables>(GetLicensesDocument, baseOptions);
        }
export type GetLicensesQueryHookResult = ReturnType<typeof useGetLicensesQuery>;
export type GetLicensesLazyQueryHookResult = ReturnType<typeof useGetLicensesLazyQuery>;
export type GetLicensesQueryResult = Apollo.QueryResult<GetLicensesQuery, GetLicensesQueryVariables>;
export const GetAccountsDocument = gql`
    query getAccounts {
  getAccounts {
    id
    name
  }
}
    `;

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
        return Apollo.useQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, baseOptions);
      }
export function useGetAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountsQuery, GetAccountsQueryVariables>) {
          return Apollo.useLazyQuery<GetAccountsQuery, GetAccountsQueryVariables>(GetAccountsDocument, baseOptions);
        }
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<typeof useGetAccountsLazyQuery>;
export type GetAccountsQueryResult = Apollo.QueryResult<GetAccountsQuery, GetAccountsQueryVariables>;
export const GetCustomersDocument = gql`
    query getCustomers($accountId: String) {
  getCustomers(accountId: $accountId) {
    id
    name
    email
  }
}
    `;

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetCustomersQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
        return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, baseOptions);
      }
export function useGetCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>) {
          return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, baseOptions);
        }
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>;
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>;
export type GetCustomersQueryResult = Apollo.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>;
export const GetLicenseDocument = gql`
    query getLicense {
  getLicense {
    flavour
  }
}
    `;

/**
 * __useGetLicenseQuery__
 *
 * To run a query within a React component, call `useGetLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLicenseQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLicenseQuery(baseOptions?: Apollo.QueryHookOptions<GetLicenseQuery, GetLicenseQueryVariables>) {
        return Apollo.useQuery<GetLicenseQuery, GetLicenseQueryVariables>(GetLicenseDocument, baseOptions);
      }
export function useGetLicenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLicenseQuery, GetLicenseQueryVariables>) {
          return Apollo.useLazyQuery<GetLicenseQuery, GetLicenseQueryVariables>(GetLicenseDocument, baseOptions);
        }
export type GetLicenseQueryHookResult = ReturnType<typeof useGetLicenseQuery>;
export type GetLicenseLazyQueryHookResult = ReturnType<typeof useGetLicenseLazyQuery>;
export type GetLicenseQueryResult = Apollo.QueryResult<GetLicenseQuery, GetLicenseQueryVariables>;
export const GetUserFlavourDocument = gql`
    query getUserFlavour {
  getUserFlavour
}
    `;

/**
 * __useGetUserFlavourQuery__
 *
 * To run a query within a React component, call `useGetUserFlavourQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserFlavourQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserFlavourQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserFlavourQuery(baseOptions?: Apollo.QueryHookOptions<GetUserFlavourQuery, GetUserFlavourQueryVariables>) {
        return Apollo.useQuery<GetUserFlavourQuery, GetUserFlavourQueryVariables>(GetUserFlavourDocument, baseOptions);
      }
export function useGetUserFlavourLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserFlavourQuery, GetUserFlavourQueryVariables>) {
          return Apollo.useLazyQuery<GetUserFlavourQuery, GetUserFlavourQueryVariables>(GetUserFlavourDocument, baseOptions);
        }
export type GetUserFlavourQueryHookResult = ReturnType<typeof useGetUserFlavourQuery>;
export type GetUserFlavourLazyQueryHookResult = ReturnType<typeof useGetUserFlavourLazyQuery>;
export type GetUserFlavourQueryResult = Apollo.QueryResult<GetUserFlavourQuery, GetUserFlavourQueryVariables>;
export const GetSwOnlyAppDownloadLinkDocument = gql`
    query getSwOnlyAppDownloadLink($shortId: String!) {
  getSwOnlyAppDownloadLink(shortId: $shortId)
}
    `;

/**
 * __useGetSwOnlyAppDownloadLinkQuery__
 *
 * To run a query within a React component, call `useGetSwOnlyAppDownloadLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSwOnlyAppDownloadLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSwOnlyAppDownloadLinkQuery({
 *   variables: {
 *      shortId: // value for 'shortId'
 *   },
 * });
 */
export function useGetSwOnlyAppDownloadLinkQuery(baseOptions: Apollo.QueryHookOptions<GetSwOnlyAppDownloadLinkQuery, GetSwOnlyAppDownloadLinkQueryVariables>) {
        return Apollo.useQuery<GetSwOnlyAppDownloadLinkQuery, GetSwOnlyAppDownloadLinkQueryVariables>(GetSwOnlyAppDownloadLinkDocument, baseOptions);
      }
export function useGetSwOnlyAppDownloadLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSwOnlyAppDownloadLinkQuery, GetSwOnlyAppDownloadLinkQueryVariables>) {
          return Apollo.useLazyQuery<GetSwOnlyAppDownloadLinkQuery, GetSwOnlyAppDownloadLinkQueryVariables>(GetSwOnlyAppDownloadLinkDocument, baseOptions);
        }
export type GetSwOnlyAppDownloadLinkQueryHookResult = ReturnType<typeof useGetSwOnlyAppDownloadLinkQuery>;
export type GetSwOnlyAppDownloadLinkLazyQueryHookResult = ReturnType<typeof useGetSwOnlyAppDownloadLinkLazyQuery>;
export type GetSwOnlyAppDownloadLinkQueryResult = Apollo.QueryResult<GetSwOnlyAppDownloadLinkQuery, GetSwOnlyAppDownloadLinkQueryVariables>;
export const OnGroupJoinedDocument = gql`
    subscription onGroupJoined($userId: UUID!) {
  groupJoined(userId: $userId) {
    group {
      ...ChatListItem
    }
  }
}
    ${ChatListItemFragmentDoc}`;

/**
 * __useOnGroupJoinedSubscription__
 *
 * To run a query within a React component, call `useOnGroupJoinedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnGroupJoinedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnGroupJoinedSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnGroupJoinedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnGroupJoinedSubscription, OnGroupJoinedSubscriptionVariables>) {
        return Apollo.useSubscription<OnGroupJoinedSubscription, OnGroupJoinedSubscriptionVariables>(OnGroupJoinedDocument, baseOptions);
      }
export type OnGroupJoinedSubscriptionHookResult = ReturnType<typeof useOnGroupJoinedSubscription>;
export type OnGroupJoinedSubscriptionResult = Apollo.SubscriptionResult<OnGroupJoinedSubscription>;
export const OnGroupLastReadByAllUpdatedDocument = gql`
    subscription onGroupLastReadByAllUpdated($userId: UUID!) {
  groupLastReadByAllUpdated(userId: $userId) {
    chatId
    lastReadByAll
    lastReceivedByAll
  }
}
    `;

/**
 * __useOnGroupLastReadByAllUpdatedSubscription__
 *
 * To run a query within a React component, call `useOnGroupLastReadByAllUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnGroupLastReadByAllUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnGroupLastReadByAllUpdatedSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnGroupLastReadByAllUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnGroupLastReadByAllUpdatedSubscription, OnGroupLastReadByAllUpdatedSubscriptionVariables>) {
        return Apollo.useSubscription<OnGroupLastReadByAllUpdatedSubscription, OnGroupLastReadByAllUpdatedSubscriptionVariables>(OnGroupLastReadByAllUpdatedDocument, baseOptions);
      }
export type OnGroupLastReadByAllUpdatedSubscriptionHookResult = ReturnType<typeof useOnGroupLastReadByAllUpdatedSubscription>;
export type OnGroupLastReadByAllUpdatedSubscriptionResult = Apollo.SubscriptionResult<OnGroupLastReadByAllUpdatedSubscription>;
export const OnMessageAddedDocument = gql`
    subscription onMessageAdded($userId: UUID!) {
  messageAdded(userId: $userId) {
    message {
      ...FullMessage
    }
  }
}
    ${FullMessageFragmentDoc}`;

/**
 * __useOnMessageAddedSubscription__
 *
 * To run a query within a React component, call `useOnMessageAddedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnMessageAddedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnMessageAddedSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnMessageAddedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnMessageAddedSubscription, OnMessageAddedSubscriptionVariables>) {
        return Apollo.useSubscription<OnMessageAddedSubscription, OnMessageAddedSubscriptionVariables>(OnMessageAddedDocument, baseOptions);
      }
export type OnMessageAddedSubscriptionHookResult = ReturnType<typeof useOnMessageAddedSubscription>;
export type OnMessageAddedSubscriptionResult = Apollo.SubscriptionResult<OnMessageAddedSubscription>;
export const OnMessageDeletedDocument = gql`
    subscription onMessageDeleted($userId: UUID!) {
  messageDeleted(userId: $userId) {
    chatId
    messageId
  }
}
    `;

/**
 * __useOnMessageDeletedSubscription__
 *
 * To run a query within a React component, call `useOnMessageDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnMessageDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnMessageDeletedSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useOnMessageDeletedSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnMessageDeletedSubscription, OnMessageDeletedSubscriptionVariables>) {
        return Apollo.useSubscription<OnMessageDeletedSubscription, OnMessageDeletedSubscriptionVariables>(OnMessageDeletedDocument, baseOptions);
      }
export type OnMessageDeletedSubscriptionHookResult = ReturnType<typeof useOnMessageDeletedSubscription>;
export type OnMessageDeletedSubscriptionResult = Apollo.SubscriptionResult<OnMessageDeletedSubscription>;
export const OnInvitationDocument = gql`
    subscription onInvitation {
  invitationAddedOrUpdated {
    invitation {
      ...InvitationInfo
    }
  }
}
    ${InvitationInfoFragmentDoc}`;

/**
 * __useOnInvitationSubscription__
 *
 * To run a query within a React component, call `useOnInvitationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnInvitationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnInvitationSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnInvitationSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnInvitationSubscription, OnInvitationSubscriptionVariables>) {
        return Apollo.useSubscription<OnInvitationSubscription, OnInvitationSubscriptionVariables>(OnInvitationDocument, baseOptions);
      }
export type OnInvitationSubscriptionHookResult = ReturnType<typeof useOnInvitationSubscription>;
export type OnInvitationSubscriptionResult = Apollo.SubscriptionResult<OnInvitationSubscription>;
export const OnVideoCallEventDocument = gql`
    subscription onVideoCallEvent {
  newVideocallEvent {
    videocallEvent {
      event
      groupId
      senderId
    }
  }
}
    `;

/**
 * __useOnVideoCallEventSubscription__
 *
 * To run a query within a React component, call `useOnVideoCallEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnVideoCallEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnVideoCallEventSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnVideoCallEventSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnVideoCallEventSubscription, OnVideoCallEventSubscriptionVariables>) {
        return Apollo.useSubscription<OnVideoCallEventSubscription, OnVideoCallEventSubscriptionVariables>(OnVideoCallEventDocument, baseOptions);
      }
export type OnVideoCallEventSubscriptionHookResult = ReturnType<typeof useOnVideoCallEventSubscription>;
export type OnVideoCallEventSubscriptionResult = Apollo.SubscriptionResult<OnVideoCallEventSubscription>;
export const OnSystemMessageDocument = gql`
    subscription onSystemMessage {
  systemMessageAdded {
    systemMessage {
      senderId
      receiverId
      payload
      kind
      id
      hidden
      createdAt
    }
  }
}
    `;

/**
 * __useOnSystemMessageSubscription__
 *
 * To run a query within a React component, call `useOnSystemMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnSystemMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnSystemMessageSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnSystemMessageSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnSystemMessageSubscription, OnSystemMessageSubscriptionVariables>) {
        return Apollo.useSubscription<OnSystemMessageSubscription, OnSystemMessageSubscriptionVariables>(OnSystemMessageDocument, baseOptions);
      }
export type OnSystemMessageSubscriptionHookResult = ReturnType<typeof useOnSystemMessageSubscription>;
export type OnSystemMessageSubscriptionResult = Apollo.SubscriptionResult<OnSystemMessageSubscription>;